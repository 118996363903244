import React from 'react';
// import { Grid } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export const TileGroup = ({ children, gap = 'md', type, ...rest }) => {
  const spacing = gap === 'xs' ? 1.25 : gap === 'sm' ? 2.5 : 4;
  return (
    <Grid container spacing={spacing} columns={{ xs: 2, md: 4, lg: 6 }} {...rest} justifyContent={'center'}>
      {React.Children.map(children, (child) => {
        return child !== null && child.props.type === undefined
          ? React.cloneElement(child, { type }, null)
          : child;
      })}
    </Grid>
  );
};
