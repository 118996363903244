import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Button } from '../../Buttons';

export const MeasuresContent = ({
  title,
  description,
  subtitle,
  to,
  toLabel,
  buttonTo,
  buttonLabel = 'Learn More',
  promote,
  showPsiCloudQi
}) => {
  return (
    <CardContent>
      <div className='promote'>{promote ? 'Beta' : ''}</div>
      <Typography variant='h3' className='title'>
        <a href={to}>{title}</a>
      </Typography>
      {subtitle && (
        <Typography variant='h5' className='subtitle' mt={1.5}>
          {subtitle}
        </Typography>
      )}
      <Typography variant='body2' component='div' className='description'>
        {description}
      </Typography>
      <Typography variant='body2' component='div' className='link'>
        <a href={to}>{toLabel}</a>
      </Typography>
      {showPsiCloudQi && (<Typography variant='body2' component='div' className='link'>
        Interested in using CloudQI? <a className='bolder' href='/software/cloudqi' onClick={(e) => e.stopPropagation()}>Click here to download CloudQI.</a>
      </Typography>)}
      {buttonTo && (
        <Button
          ariaLabel={buttonLabel}
          size='small'
          href={buttonTo}
          arrow
          onClick={(e) => e.stopPropagation()}>
          {buttonLabel}
        </Button>
      )}
    </CardContent>
  );
};

const CardContent = styled(Box)(({ theme }) => ({
  '.title': {
    fontSize: '2rem',
    fontWeight: 900,

    '&:after': {
      content: `""`,
      position: 'absolute',
      bottom: 0,
      left: 0,

      display: 'block',
      backgroundColor: theme.palette.grey[300],
      height: 2,
      width: 50,
    },
  },
  '.description': {
    lineHeight: '18px',
    marginTop: theme.spacing(1),
    zIndex: 1,
  },
  '.promote': {
    color: theme.palette.secondary.light,
    fontFamily: '"Public Sans", sans-serif',
    fontSize: '.875rem',
    fontWeight: 'bold',
    lineHeight: 1,
    height: 12,
    textTransform: 'uppercase',
  },
  '.link': {
    marginTop: '.5rem',
  },
  '.MuiButton-root': {
    marginTop: '1rem',
  },
}));
