export const modulesData = {
  pqi: {
    topic: 'PQI - Prevention Quality Indicators in Inpatient Settings',
    tag: 'PQI-Indicators',
    indicators: [
      {
        indicator: 'PQI 01 Diabetes Short-term Complications Admission Rate',
        tag: 'PQI-01',
        type: 'Area Level',
        desc: 'Hospitalizations for a principal diagnosis of diabetes with short-term complications (ketoacidosis, hyperosmolarity, or coma) per 100,000 population, ages 18 years and older. Excludes obstetric hospitalizations and transfers from other institutions.',
        versions: {          
          icd10_v2024: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '195 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '130 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '328 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '335 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '334 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '138 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '621 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '587 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '410 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '380 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '140 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 01 Diabetes Short-term Complications Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '81 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_01_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          v45: {
            name: 'PQI 01 Diabetes Short-term Complications Admission Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '220 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 01 Diabetes Short-term Complications Admission Rate.pdf',
          },
          v44: {
            name: 'PQI 01 Diabetes Short-term Complications Admissions Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '175 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 01 Diabetes Short-term Complications Admissions Rate.pdf',
          },
          v43a: {
            name: 'PQI 01 Diabetes Short-term Complications Admissions Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '169 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 01 Diabetes Short-term Complications Admissions Rate.pdf',
          },
          v43: {
            name: 'PQI 01 Diabetes Short-term Complications Admissions Rate',
            aria: 'View PQI 01 Diabetes Short-term Complications Admission Rate PDF',
            size: '24 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 01 Diabetes Short-term Complications Admissions Rate.pdf',
          },
          v42: {
            name: 'PQI 01 Diabetes Short-term Complications',
            aria: 'View PQI 01 Diabetes Short-term Complications PDF',
            size: '41 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 01 Diabetes Short-term Complications.pdf',
          },
          v41: {
            name: 'PQI 01 Diabetes Short-term Complications',
            aria: 'View PQI 01 Diabetes Short-term Complications PDF',
            size: '52 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 01 Diabetes Short-term Complications.pdf',
          },
        },
      },
      {
        indicator: 'PQI 03 Diabetes Long-term Complications Admission Rate',
        tag: 'PQI-03',
        type: 'Area Level',
        desc: 'Hospitalizations for a principal diagnosis of diabetes with long-term complications (renal, eye, neurological, circulatory, other specified, or unspecified) per 100,000 population, ages 18 years and older. Excludes obstetric hospitalizations and transfers from other institutions.',
        versions: {
          icd10_v2024: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '265 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '206 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '376 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '381 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '381 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '188 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '728 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '695 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '426 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '384 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '144 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 03 Diabetes Long-term Complications Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '76 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_03_Diabetes_Long-term_Complications_Admission_Rate.pdf',
          },
          v45: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '228 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 03 Diabetes Long-term Complications Admission Rate.pdf',
          },
          v44: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '206 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 03 Diabetes Long-term Complications Admission Rate.pdf',
          },
          v43a: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '180 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 03 Diabetes Long-term Complications Admission Rate.pdf',
          },
          v43: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '28 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 03 Diabetes Long-term Complications Admission Rate.pdf',
          },
          v42: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '45 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 03 Diabetes Long-term Complications Admission Rate.pdf',
          },
          v41: {
            name: 'PQI 03 Diabetes Long-term Complications Admission Rate',
            aria: 'View PQI 03 Diabetes Long-term Complications Admission Rate PDF',
            size: '52 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 03 Diabetes Long-term Complications Admission Rate.pdf',
          },
        },
      },
      {
        indicator:
          'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
        tag: 'PQI-05',
        type: 'Area Level',
        desc: 'Hospitalizations with a principal diagnosis of chronic obstructive pulmonary disease (COPD) or asthma per 100,000 population, ages 40 years and older. Excludes hospitalizations with cystic fibrosis and anomalies of the respiratory system, obstetric hospitalizations, and transfers from other institutions.',
        versions: {
            icd10_v2024: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '212 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '189 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '429 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '443 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '441 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '204 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '674 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '641 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '432 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '395 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_05_Chronic_Obstructive_Pulmonary_Disease_(COPD)_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '125 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 05 COPD or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '92 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_05_COPD_or_Asthma_in_Older_Adults_Admission_Rate.pdf',
          },
          v45: {
            name: 'PQI 05 COPD or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '254 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 05 COPD or Asthma in Older Adults Admission Rate.pdf',
          },
          v44: {
            name: 'PQI 05 COPD or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '202 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 05 COPD or Asthma in Older Adults Admission Rate.pdf',
          },
          v43a: {
            name: 'PQI 05 COPD or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '175 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 05 COPD or Asthma in Older Adults Admission Rate.pdf',
          },
          v43: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease or Asthma in Older Adults Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '93 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 05 Chronic Obstructive Pulmonary Disease or Asthma in Older Adults Admission Rate.pdf',
          },
          v42: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '45 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 05 Chronic Obstructive Pulmonary Disease (COPD) Admission Rate.pdf',
          },
          v41: {
            name: 'PQI 05 Chronic Obstructive Pulmonary Disease (COPD) Admission Rate',
            aria: 'View PQI 05 Chronic Obstructive Pulmonary Disease (COPD) or Asthma in Older Adults Admission Rate PDF',
            size: '53 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 05 Chronic Obstructive Pulmonary Disease (COPD) Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PQI 07 Hypertension Admission Rate',
        tag: 'PQI-07',
        type: 'Area Level',
        desc: 'Hospitalizations with a principal diagnosis of hypertension per 100,000 population, ages 18 years and older. Excludes hospitalizations with stage 1- 4 or unspecified chronic kidney disease combined with a dialysis access procedure, hospitalizations for cardiac procedure, obstetric hospitalizations, and transfers from other institutions.',
        versions: {
          icd10_v2024: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '246 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '174 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '368 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '376 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '376 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '177 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '698 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '325 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '578 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '391 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '126 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 07 Hypertension Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '81 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_07_Hypertension_Admission_Rate.pdf',
          },
          v45: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '234 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 07 Hypertension Admission Rate.pdf',
          },
          v44: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '181 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 07 Hypertension Admission Rate.pdf',
          },
          v43a: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '191 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 07 Hypertension Admission Rate.pdf',
          },
          v43: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '30 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 07 Hypertension Admission Rate.pdf',
          },
          v42: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '73 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 07 Hypertension Admission Rate.pdf',
          },
          v41: {
            name: 'PQI 07 Hypertension Admission Rate',
            aria: 'View PQI 07 Hypertension Admission Rate PDF',
            size: '148 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 07 Hypertension Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PQI 08 Heart Failure Admission Rate',
        isMostPopular: true,
        tag: 'PQI-08',
        type: 'Area Level',
        desc: 'Hospitalizations with a principal diagnosis of heart failure per 100,000 population, ages 18 years and older. Excludes hospitalizations with cardiac procedure, obstetric hospitalizations, and transfers from other institutions.',
        versions: {
          icd10_v2024: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '224 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '136 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '331 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '339 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '339 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '144 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '633 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '717 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '412 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '387 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '157 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 08 Heart Failure Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '73 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_08_Heart_Failure_Admission_Rate.pdf',
          },
          v45: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '230 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 08 Heart Failure Admission Rate.pdf',
          },
          v44: {
            name: 'PQI 08 Heart Failure Admission Rate',
            aria: 'View PQI 08 Heart Failure Admission Rate PDF',
            size: '214 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 08 Heart Failure Admission Rate.pdf',
          },
          v43a: {
            name: 'PQI 08 Congestive Heart Failure (CHF) Admission Rate',
            aria: 'View PQI 08 Congestive Heart Failure (CHF) Admission Rate PDF',
            size: '196 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 08 Congestive Heart Failure (CHF) Admission Rate.pdf',
          },
          v43: {
            name: 'PQI 08 Congestive Heart Failure (CHF) Admission Rate',
            aria: 'View PQI 08 Congestive Heart Failure (CHF) Admission Rate PDF',
            size: '30 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 08 Congestive Heart Failure (CHF) Admission Rate.pdf',
          },
          v42: {
            name: 'PQI 08 CHF Admission Rate',
            aria: 'View PQI 08 CHF Admission Rate PDF',
            size: '71 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 08 CHF Admission Rate.pdf',
          },
          v41: {
            name: 'PQI 08 CHF Admission Rate',
            aria: 'View PQI 08 CHF Admission Rate PDF',
            size: '133 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 08 CHF Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PQI 11 Community Acquired  Pneumonia Admission Rate',
        tag: 'PQI-11',
        type: 'Area Level',
        desc: 'Hospitalizations with a principal diagnosis of community-acquired bacterial pneumonia per 100,000 population, ages 18 years or older. Excludes hospitalizations with sickle cell or hemoglobin-S disease, other indications of immunocompromised state, obstetric hospitalizations, and transfers from other institutions.',
        versions: {
          icd10_v2024: {
            name: 'PQI 11 Community Acquired Pneumonia Admission Rate',
            aria: 'View PQI 11 Community Acquired Pneumonia Admission Rate PDF',
            size: '232 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_11_Community_Acquired _Pneumonia_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 11 Community Acquired Pneumonia Admission Rate',
            aria: 'View PQI 11 Community Acquired Pneumonia Admission Rate PDF',
            size: '206 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_11_Community_Acquired _Pneumonia_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 11 Community Acquired Pneumonia Admission Rate',
            aria: 'View PQI 11 Community Acquired Pneumonia Admission Rate PDF',
            size: '503 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_11_Community_Acquired _Pneumonia_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 11 Community Acquired Pneumonia Admission Rate',
            aria: 'View PQI 11 Community Acquired Pneumonia Admission Rate PDF',
            size: '515 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_11_Community_Acquired _Pneumonia_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 11 Community Acquired  Pneumonia Admission Rate',
            aria: 'View PQI 11 Community Acquired Pneumonia Admission Rate PDF',
            size: '512 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_11_Community_Acquired _Pneumonia_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 11 Community Acquired  Pneumonia Admission Rate',
            aria: 'View PQI 11 Community Acquired Pneumonia Admission Rate PDF',
            size: '232 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_11_Community_Acquired _Pneumonia_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 11 Community Acquired  Pneumonia Admission Rate',
            aria: 'View PQI 11 Community Acquired Pneumonia Admission Rate PDF',
            size: '644 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_11_Community_Acquired _Pneumonia_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 11 Community Acquired  Pneumonia Admission Rate',
            aria: 'View PQI 11 Community Acquired Pneumonia Admission Rate PDF',
            size: '477 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_11_Community_Acquired _Pneumonia_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '418 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_11_Bacterial_Pneumonia_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '388 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_11_Bacterial_Pneumonia_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '121 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 11 Bacterial Pneumonia Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '76 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_11_Bacterial_Pneumonia_Admission_Rate.pdf',
          },
          v45: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '229 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 11 Bacterial Pneumonia Admission Rate.pdf',
          },
          v44: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '189 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 11 Bacterial Pneumonia Admission Rate.pdf',
          },
          v43a: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '187 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 11 Bacterial Pneumonia Admission Rate.pdf',
          },
          v43: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '26 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 11 Bacterial Pneumonia Admission Rate.pdf',
          },
          v42: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '70 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 11 Bacterial Pneumonia Admission Rate.pdf',
          },
          v41: {
            name: 'PQI 11 Bacterial Pneumonia Admission Rate',
            aria: 'View PQI 11 Bacterial Pneumonia Admission Rate PDF',
            size: '133 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 11 Bacterial Pneumonia Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PQI 12 Urinary Tract Infection Admission Rate',
        tag: 'PQI-12',
        type: 'Area Level',
        desc: 'Hospitalizations with a principal diagnosis of urinary tract infection per 100,000 population, ages 18 years and older. Excludes hospitalizations with a kidney or urinary tract disorder, hospitalizations with other indications of immunocompromised state, obstetric hospitalizations, and transfers from other institutions.',
        versions: {
          icd10_v2024: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '238 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '193 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '516 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '527 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '527 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '250 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '684 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '493 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '434 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '393 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '188 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 12 Urinary Tract Infection Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '79 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_12_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          v45: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '254 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 12 Urinary Tract Infection Admission Rate.pdf',
          },
          v44: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '194 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 12 Urinary Tract Infection Admission Rate.pdf',
          },
          v43a: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '196 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 12 Urinary Tract Infection Admission Rate.pdf',
          },
          v43: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '31 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 12 Urinary Tract Infection Admission Rate.pdf',
          },
          v42: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '72 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 12 Urinary Tract Infection Admission Rate.pdf',
          },
          v41: {
            name: 'PQI 12 Urinary Tract Infection Admission Rate',
            aria: 'View PQI 12 Urinary Tract Infection Admission Rate PDF',
            size: '140 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 12 Urinary Tract Infection Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PQI 14 Uncontrolled Diabetes Admission Rate',
        tag: 'PQI-14',
        type: 'Area Level',
        desc: 'Hospitalizations for a principal diagnosis of uncontrolled diabetes without mention of short-term (ketoacidosis, hyperosmolarity, or coma) or long-term (renal, eye, neurological, circulatory, other specified, or unspecified) complications per 100,000 population, ages 18 years and older. Excludes obstetric hospitalizations and transfers from other institutions.',
        versions: {
          icd10_v2024: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '216 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '196 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '415 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '424 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '424 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '184 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '621 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '588 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '399 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '379 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '139 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 14 Uncontrolled Diabetes Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '75 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_14_Uncontrolled_Diabetes_Admission_Rate.pdf',
          },
          v45: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '212 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 14 Uncontrolled Diabetes Admission Rate.pdf',
          },
          v44: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '155 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 14 Uncontrolled Diabetes Admission Rate.pdf',
          },
          v43a: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '162 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 14 Uncontrolled Diabetes Admission Rate.pdf',
          },
          v43: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '21 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 14 Uncontrolled Diabetes Admission Rate.pdf',
          },
          v42: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '40 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 14 Uncontrolled Diabetes Admission Rate.pdf',
          },
          v41: {
            name: 'PQI 14 Uncontrolled Diabetes Admission Rate',
            aria: 'View PQI 14 Uncontrolled Diabetes Admission Rate PDF',
            size: '51 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 14 Uncontrolled Diabetes Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PQI 15 Asthma in Younger Adults Admission Rate',
        tag: 'PQI-15',
        type: 'Area Level',
        desc: 'Hospitalizations for a principal diagnosis of asthma per 100,000 population, ages 18 to 39 years. Excludes hospitalizations with cystic fibrosis or anomalies of the respiratory system, obstetric hospitalizations, and transfers from other institutions.',
        versions: {
          icd10_v2024: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '230 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '205 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '507 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '520 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '520 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '238 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '662 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '630 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '427 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '391 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '149 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 15 Asthma in Younger Adults Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '79 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_15_Asthma_in_Younger_Adults_Admission_Rate.pdf',
          },
          v45: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '252 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 15 Asthma in Younger Adults Admission Rate.pdf',
          },
          v44: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '206 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 15 Asthma in Younger Adults Admission Rate.pdf',
          },
          v43a: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '189 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 15 Asthma in Younger Adults Admission Rate.pdf',
          },
          v43: {
            name: 'PQI 15 Asthma in Younger Adults Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '31 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 15 Asthma in Younger Adults Admission Rate.pdf',
          },
          v42: {
            name: 'PQI 15 Adult Asthma Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '47 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 15 Adult Asthma Admission Rate.pdf',
          },
          v41: {
            name: 'PQI 15 Adult Asthma Admission Rate',
            aria: 'View PQI 15 Asthma in Younger Adults Admission Rate PDF',
            size: '56 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 15 Adult Asthma Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
        tag: 'PQI-16',
        type: 'Area Level',
        desc: 'Hospitalizations for diabetes and a procedure of lower-extremity amputation (except toe amputations) per 100,000 population, ages 18 years and older. Excludes traumatic lower-extremity amputation hospitalizations, obstetric hospitalizations, and transfers from other institutions.',
        versions: {
          icd10_v2024: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '299 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '291 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '518 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '529 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '530 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '296 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '876 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_16_Lower_Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd10_v70: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '843 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_16_Lower-Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd10_v60: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '480 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_16_Lower-Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd9_v60: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '400 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_16_Lower-Extremity_Amputation_among_Patients_with_Diabetes_Rate.pdf',
          },
          icd10_v50: {
            name: 'PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '176 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate.pdf',
          },
          icd9_v50: {
            name: 'PQI 16 Lower-Extremity Amputation Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '83 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_16_Lower-Extremity_Amputation_Diabetes_Rate.pdf',
          },
          v45: {
            name: 'PQI 16 Lower-Extremity Amputation Diabetes Rate',
            aria: 'View PQI 16 Lower-Extremity Amputation among Patients with Diabetes Rate PDF',
            size: '242 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 16 Lower-Extremity Amputation Diabetes Rate.pdf',
          },
          v44: {
            name: 'PQI 16 Rate of Lower-Extremity Amputation Diabetes',
            aria: 'View PQI 16 Rate of Lower-Extremity Amputation Diabetes PDF',
            size: '203 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 16 Rate of Lower-Extremity Amputation Diabetes.pdf',
          },
          v43a: {
            name: 'PQI 16 Rate of Lower-Extremity Amputation Among Patients With Diabetes',
            aria: 'View PQI 16 Rate of Lower-Extremity Amputation Diabetes PDF',
            size: '209 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 16 Rate of Lower-Extremity Amputation Among Patients With Diabetes.pdf',
          },
          v43: {
            name: 'PQI 16 Rate of Lower-Extremity Amputation Among Patients With Diabetes',
            aria: 'View PQI 16 Rate of Lower-Extremity Amputation Diabetes PDF',
            size: '34 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 16 Rate of Lower-Extremity Amputation Among Patients With Diabetes.pdf',
          },
          v42: {
            name: 'PQI 16 Rate of Lower-extremity Amputation',
            aria: 'View PQI 16 Rate of Lower-Extremity Amputation PDF',
            size: '54 KB',
            url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 16 Rate of Lower-extremity Amputation.pdf',
          },
          v41: {
            name: 'PQI 16 Rate of Lower-extremity Amputation',
            aria: 'View PQI 16 Rate of Lower-Extremity Amputation PDF',
            size: '68 KB',
            url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 16 Rate of Lower-extremity Amputation.pdf',
          },
        },
      },
      {
        indicator: 'PQI 90 Prevention Quality Overall Composite',
        isMostPopular: true,
        tag: 'PQI-90',
        type: 'Area Level',
        desc: 'Prevention Quality Indicators (PQI) overall composite per 100,000 population, ages 18 years and older. Includes hospitalizations for one of the following conditions: diabetes with short-term complications, diabetes with long-term complications, uncontrolled diabetes without complications, diabetes with lower-extremity amputation, chronic obstructive pulmonary disease, asthma, hypertension, heart failure, bacterial pneumonia, or urinary tract infection.',
        versions: {
          icd10_v2024: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '184 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd10_v2023: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '129 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd10_v2022: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '403 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd10_v2021: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '356 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd10_v2020: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '427 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd10_v2019: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '114 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd10_v2018: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '661 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd10_v70: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '460 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd10_v60: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '297 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd9_v60: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '455 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite.pdf',
          },
          icd10_v50: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '49 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 90 Prevention Quality Overall Composite.pdf',
          },
          icd9_v50: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '71 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_90_Prevention_Quality_Overall_Composite_.pdf',
          },
          v45: {
            name: 'PQI 90 Prevention Quality Overall Composite',
            aria: 'View PQI 90 Prevention Quality Overall Composite PDF',
            size: '173 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 90 Prevention Quality Overall Composite.pdf',
          },
        },
      },
      {
        indicator: 'PQI 91 Prevention Quality Acute Composite',
        tag: 'PQI-91',
        type: 'Area Level',
        desc: 'Prevention Quality Indicators (PQI) composite of acute conditions per 100,000 population, ages 18 years and older. Includes hospitalizations with a principal diagnosis of one of the following conditions: bacterial pneumonia or urinary tract infection.',
        versions: {
          icd10_v2024: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '178 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd10_v2023: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '120 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd10_v2022: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '326 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd10_v2021: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '333 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd10_v2020: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '333 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd10_v2019: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '107 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd10_v2018: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '649 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd10_v70: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '451 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd10_v60: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '290 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd9_v60: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '447 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          icd10_v50: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '47 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 91 Prevention Quality Acute Composite.pdf',
          },
          icd9_v50: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '65 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_91_Prevention_Quality_Acute_Composite.pdf',
          },
          v45: {
            name: 'PQI 91 Prevention Quality Acute Composite',
            aria: 'View PQI 91 Prevention Quality Acute Composite PDF',
            size: '163 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 91 Prevention Quality Acute Composite.pdf',
          },
        },
      },
      {
        indicator: 'PQI 92 Prevention Quality Chronic Composite',
        tag: 'PQI-92',
        type: 'Area Level',
        desc: 'Prevention Quality Indicators (PQI) composite of chronic conditions per 100,000 population, ages 18 years and older. Includes hospitalizations for one of the following conditions: diabetes with short-term complications, diabetes with long-term complications, uncontrolled diabetes without complications, diabetes with lower-extremity amputation, chronic obstructive pulmonary disease, asthma, hypertension, or heart failure.',
        versions: {
          icd10_v2024: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '184 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd10_v2023: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '130 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd10_v2022: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '338 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd10_v2021: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '346 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd10_v2020: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '427 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd10_v2019: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '113 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd10_v2018: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '656 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd10_v70: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '457 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd10_v60: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '395 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd9_v60: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '455 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          icd10_v50: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '47 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 92 Prevention Quality Chronic Composite.pdf',
          },
          icd9_v50: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '70 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_92_Prevention_Quality_Chronic_Composite.pdf',
          },
          v45: {
            name: 'PQI 92 Prevention Quality Chronic Composite',
            aria: 'View PQI 92 Prevention Quality Chronic Composite PDF',
            size: '171 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 92 Prevention Quality Chronic Composite.pdf',
          },
        },
      },
      {
        indicator: 'PQI 93 Prevention Quality Diabetes Composite',
        tag: 'PQI-93',
        type: 'Area Level',
        desc: 'Prevention Quality Indicators (PQI) composite of diabetes admissions per 100,000 population, ages 18 years and older. Includes hospitalizations for one of the following conditions: diabetes with short-term complications, diabetes with long-term complications, uncontrolled diabetes without complications, or diabetes with lower-extremity amputation.',
        versions: {
          icd10_v2024: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '181 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
          icd10_v2023: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '126 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
          icd10_v2022: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '336 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
          icd10_v2021: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '344 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
          icd10_v2020: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '423 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
          icd10_v2019: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '109 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
          icd10_v2018: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '654 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
          icd10_v70: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '453 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
          icd10_v60: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '392 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
          icd9_v60: {
            name: 'PQI 93 Prevention Quality Diabetes Composite',
            aria: 'View PQI 93 Prevention Quality Diabetes Composite PDF',
            size: '453 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_93_Prevention_Quality_Diabetes_Composite.pdf',
          },
        },
      },
    ],
    appendices: {
      indicator: 'Appendices',
      tag: 'PQI-Appendices',
      versions: {
        icd10_v2024: [
          {
            name: 'PQI Appendix A - Admission Codes for Incoming Transfers',
            aria: 'View Appendix A - Admission Codes for Incoming Transfers PDF',
            size: '142 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '515 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '214 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix E - COVID-19 Diagnosis Codes',
            aria: 'View Appendix E - COVID-19 Diagnosis Codes PDF',
            size: '130 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_Appendix_E.pdf',
          },
          {
            name: 'PQI Appendix F - MDC 14 and MDC 15 Principal Diagnosis Codes',
            aria: 'View Appendix F - MDC 14 and MDC 15 Principal Diagnosis Codes PDF',
            size: '588 KB',
            url: '/Downloads/Modules/PQI/V2024/TechSpecs/PQI_Appendix_F.pdf',
          },
        ],
        icd10_v2023: [
          {
            name: 'PQI Appendix A - Admission Codes for Incoming Transfers',
            aria: 'View Appendix A - Admission Codes for Incoming Transfers PDF',
            size: '57 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '607 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '153 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix E - COVID-19 Diagnosis Codes',
            aria: 'View Appendix E - COVID-19 Diagnosis Codes PDF',
            size: '57 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_Appendix_E.pdf',
          },
          {
            name: 'PQI Appendix F - MDC 14 and MDC 15 Principal Diagnosis Codes',
            aria: 'View Appendix F - MDC 14 and MDC 15 Principal Diagnosis Codes PDF',
            size: '617 KB',
            url: '/Downloads/Modules/PQI/V2023/TechSpecs/PQI_Appendix_F.pdf',
          },
        ],
        icd10_v2022: [
          {
            name: 'PQI Appendix A - Admission Codes for Incoming Transfers',
            aria: 'View Appendix A - Admission Codes for Incoming Transfers PDF',
            size: '209 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '712 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '406 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix E - COVID-19 Diagnosis Codes',
            aria: 'View Appendix E - COVID-19 Diagnosis Codes PDF',
            size: '322 KB',
            url: '/Downloads/Modules/PQI/V2022/TechSpecs/PQI_Appendix_E.pdf',
          },
        ],
        icd10_v2021: [
          {
            name: 'PQI Appendix A - Admission Codes for Transfers',
            aria: 'View Appendix A - Admission Codes for Transfers PDF',
            size: '214 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '697 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '414 KB',
            url: '/Downloads/Modules/PQI/V2021/TechSpecs/PQI_Appendix_C.pdf',
          },
        ],
        icd10_v2020: [
          {
            name: 'PQI Appendix A - Admission Codes for Transfers',
            aria: 'View Appendix A - Admission Codes for Transfers PDF',
            size: '214 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '696 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '381 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '326 KB',
            url: '/Downloads/Modules/PQI/V2020/TechSpecs/PQI_Appendix_D.pdf',
          },
        ],
        icd10_v2019: [
          {
            name: 'PQI Appendix A - Admission Codes for Transfers',
            aria: 'View Appendix A - Admission Codes for Transfers PDF',
            size: '61 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '591 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '157 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '125 KB',
            url: '/Downloads/Modules/PQI/V2019/TechSpecs/PQI_Appendix_D.pdf',
          },
        ],
        icd10_v2018: [
          {
            name: 'PQI Appendix A - Admission Codes for Transfers',
            aria: 'View Appendix A - Admission Codes for Transfers PDF',
            size: '480 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '1.6 MB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '685 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '505 KB',
            url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_Appendix_D.pdf',
          },
        ],
        icd10_v70: [
          {
            name: 'PQI Appendix A - Admission Codes for Transfers',
            aria: 'View Appendix A - Admission Codes for Transfers PDF',
            size: '448 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '1.5 MB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '648 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '473 KB',
            url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_Appendix_D.pdf',
          },
        ],
        icd10_v60: [
          {
            name: 'PQI Appendix A - Admission Codes for Transfers',
            aria: 'View Appendix A - Admission Codes for Transfers PDF',
            size: '310 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '444 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '194 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '227 KB',
            url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_Appendix_D.pdf',
          },
        ],
        icd9_v60: [
          {
            name: 'PQI Appendix A - Admission Codes for Transfers',
            aria: 'View Appendix A - Admission Codes for Transfers PDF',
            size: '310 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '444 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '194 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '227 KB',
            url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_Appendix_D.pdf',
          },
        ],
        icd10_v50: [
          {
            name: 'PQI Appendix A - Admission Codes for Transfers',
            aria: 'View Appendix A - Admission Codes for Transfers PDF',
            size: '58 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI_Appendix_A.pdf',
          },
          {
            name: 'PQI Appendix B - Cardiac Procedure Codes',
            aria: 'View Appendix B - Cardiac Procedure Codes PDF',
            size: '280 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI_Appendix_B.pdf',
          },
          {
            name: 'PQI Appendix C - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View Appendix C - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '116 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI_Appendix_C.pdf',
          },
          {
            name: 'PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PQI Appendix D - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '93 KB',
            url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI_Appendix_D.pdf',
          },
        ],
        icd9_v50: [
          {
            name: 'PQI Appendices',
            aria: 'View PQI Appendices PDF',
            size: '355 KB',
            url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_Appendices.pdf',
          },
        ],
        v45: [
          {
            name: 'PQI Appendices',
            aria: 'View PQI Appendices PDF',
            size: '430 KB',
            url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI Appendices.pdf',
          },
        ],
        v44: [
          {
            name: 'PQI Appendices',
            aria: 'View PQI Appendices PDF',
            size: '306 KB',
            url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI Appendices.pdf',
          },
        ],
        v43a: [
          {
            name: 'PQI Appendices',
            aria: 'View PQI Appendices PDF',
            size: '317 KB',
            url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI Appendices.pdf',
          },
        ],
        v43: [
          {
            name: 'PQI Appendices',
            aria: 'View PQI Appendices PDF',
            size: '99 KB',
            url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI Appendices.pdf',
          },
        ],
      },
    },
    retired: {
      indicator: 'Retired PQIs',
      tag: 'PQI-Retired',
      versions: {
        'PQI 02': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_02_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '337 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_02_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '578 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_02_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '406 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_02_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '375 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 02 Perforated Appendix Admission Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '106 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_02_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '75 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 02 Perforated Appendix Admission Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '208 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 02 Perforated Appendix Admission Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '175 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 02 Perforated Appendix Admission Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '165 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 02 Perforated Appendix Admission Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '78 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 02 Perforated Appendix Admission Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '42 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 02 Perforated Appendix Admission Rate.pdf',
              aria: 'View PQI 02 Perforated Appendix Admission Rate PDF',
              size: '51 KB',
              name: 'PQI 02 Perforated Appendix Admission Rate',
            },
          ],
        },
        'PQI 09': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_09_Low_Birth_Weight_Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '632 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_09_Low_Birth_Weight_Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '303 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_09_Low_Birth_Weight_Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '483 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_09_Low_Birth_Weight_Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '396 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 09 Low Birth Weight Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '112 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_09_Low_Birth_Weight_Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '86 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 09 Low Birth Weight Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '239 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 09 Low Birth Weight Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '205 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 09 Low Birth Weight Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '210 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 09 Low Birth Weight Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '100 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 09 Low Birth Weight Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '51 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 09 Low Birth Weight Rate.pdf',
              aria: 'View PQI 09 Low Birth Weight Rate PDF',
              size: '59 KB',
              name: 'PQI 09 Low Birth Weight Rate',
            },
          ],
        },
        'PQI 10': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PQI/V2018/TechSpecs/PQI_10_Dehydration_Admission_Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '443 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PQI/V70/TechSpecs/PQI_10_Dehydration_Admission_Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '612 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PQI/V60-ICD10/TechSpecs/PQI_10_Dehydration_Admission_Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '424 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PQI/V60-ICD09/TechSpecs/PQI_10_Dehydration_Admission_Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '397 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 10 Dehydration Admission Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '115 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_10_Dehydration_Admission_Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '85 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 10 Dehydration Admission Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '234 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 10 Dehydration Admission Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '212 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 10 Dehydration Admission Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '205 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 10 Dehydration Admission Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '35 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 10 Dehydration Admission Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '40 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 10 Dehydration Admission Rate.pdf',
              aria: 'View PQI 10 Dehydration Admission Rate PDF',
              size: '51 KB',
              name: 'PQI 10 Dehydration Admission Rate',
            },
          ],
        },
        'PQI 13': {
          previous: [
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PQI/V50-ICD10/TechSpecs/PQI 13 Angina Without Procedure Admission Rate.pdf',
              aria: 'View PQI 13 Angina Without Procedure Admission Rate PDF',
              size: '117 KB',
              name: 'PQI 13 Angina Without Procedure Admission Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PQI/V50/TechSpecs/PQI_13_Angina_Without_Procedure_Admission_Rate.pdf',
              aria: 'View PQI 13 Angina Without Procedure Admission Rate PDF',
              size: '73 KB',
              name: 'PQI 13 Angina Without Procedure Admission Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PQI/V45/TechSpecs/PQI 13 Angina without Procedure Admission Rate.pdf',
              aria: 'View PQI 13 Angina Without Procedure Admission Rate PDF',
              size: '202 KB',
              name: 'PQI 13 Angina without Procedure Admission Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PQI/V44/TechSpecs/PQI 13 Angina without Procedure Admission Rate.pdf',
              aria: 'View PQI 13 Angina Without Procedure Admission Rate PDF',
              size: '176 KB',
              name: 'PQI 13 Angina without Procedure Admission Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PQI/V43a/TechSpecs/PQI 13 Angina without Procedure Admission Rate.pdf',
              aria: 'View PQI 13 Angina Without Procedure Admission Rate PDF',
              size: '174 KB',
              name: 'PQI 13 Angina without Procedure Admission Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PQI/V43/TechSpecs/PQI 13 Angina without Procedure Admission Rate.pdf',
              aria: 'View PQI 13 Angina Without Procedure Admission Rate PDF',
              size: '24 KB',
              name: 'PQI 13 Angina without Procedure Admission Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PQI/V42/TechSpecs/PQI 13 Angina without Procedure Admission Rate.pdf',
              aria: 'View PQI 13 Angina Without Procedure Admission Rate PDF',
              size: '66 KB',
              name: 'PQI 13 Angina without Procedure Admission Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PQI/V41/TechSpecs/PQI 13 Angina without Procedure Admission Rate.pdf',
              aria: 'View PQI 13 Angina Without Procedure Admission Rate PDF',
              size: '125 KB',
              name: 'PQI 13 Angina without Procedure Admission Rate',
            },
          ],
        },
      },
    },
  },
  iqi: {
    topic: 'IQI - Inpatient Quality Indicators',
    tag: 'IQI-Indicators',
    indicators: [
      {
        indicator: 'IQI 08 Esophageal Resection Mortality Rate',
        tag: 'IQI-08',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 discharges with a procedure for esophageal resection or total gastrectomy and a diagnosis of esophageal cancer; or discharges with a procedure for esophageal resection and a diagnosis of gastrointestinal cancer, ages 18 years and older. Excludes obstetric discharges and transfers to another hospital.',
        versions: {
          icd10_v2024: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '232 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '223 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '388 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '396 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '395 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '221 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '795 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '764 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '414 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '307 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '79 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 08 Esophageal Resection Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '542 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_08_Esophageal_Resection_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '232 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 08 Esophageal Resection Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '199 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 08 Esophageal Resection Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '141 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 08 Esophageal Resection Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 08 Esophageal Resection Mortality Rate',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '111 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 08 Esophageal Resection Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 08-Esophogeal Resection Mortality',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '66 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 08-Esophogeal Resection Mortality.pdf',
          },
          v41: {
            name: 'IQI 08-Esophogeal Resection Mortality',
            aria: 'View IQI 08 Esophageal Resection Mortality Rate PDF',
            size: '115 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 08-Esophogeal Resection Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 09 Pancreatic Resection Mortality Rate',
        isMostPopular: true,
        tag: 'IQI-09',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 discharges with pancreatic resection, ages 18 years and older. Includes metrics to stratify discharges grouped by presence or absence of a diagnosis of pancreatic cancer. Excludes discharges with a diagnosis of acute pancreatitis, transfers to another hospital, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '198 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '229 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '344 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '350 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '349 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '150 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '640 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '607 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '343 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '309 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_09_Pancreatic_Resection_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '114 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 09 Pancreatic Resection Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '87 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_09_Pancreatic_Resection_Mortality Rate.pdf',
          },
          v45: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '233 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 09 Pancreatic Resection Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '162 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 09 Pancreatic Resection Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '132 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 09 Pancreatic Resection Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 09 Pancreatic Resection Mortality Rate',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '101 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 09 Pancreatic Resection Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 09 Pancreatic Resection Mortality',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '59 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 09 Pancreatic Resection Mortality.pdf',
          },
          v41: {
            name: 'IQI 09 Pancreatic Resection Mortality',
            aria: 'View IQI 09 Pancreatic Resection Mortality Rate PDF',
            size: '89 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 09 Pancreatic Resection Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
        tag: 'IQI-11',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 discharges with abdominal aortic aneurysm (AAA) repair, ages 18 years and older. Includes metrics for discharges grouped based on AAA rupture status and repair type. Excludes transfers to another hospital, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '243 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '241 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '400 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '408 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '407 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '214 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '404 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '296 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '342 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '311 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '146 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '86 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_11_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '226 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '163 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '130 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '99 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 11 AAA Repair Mortality',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '58 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 11 AAA Repair Mortality.pdf',
          },
          v41: {
            name: 'IQI 11 AAA Repair Mortality',
            aria: 'View IQI 11 Abdominal Aortic Aneurysm (AAA) Repair Mortality Rate PDF',
            size: '81 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 11 AAA Repair Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
        tag: 'IQI 12',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 discharges with coronary artery bypass graft (CABG), ages 40 years and older. Excludes obstetric discharges and transfers to another hospital.',
        versions: {
          icd10_v2024: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '216 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '198 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '364 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '371 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '370 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '174 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '369 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '652 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '366 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '229 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '92 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '70 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_12_Coronary_Artery_Bypass_Graft_(CABG)_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '194 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '158 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '129 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '100 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 12 CABG Mortality',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '60 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 12 CABG Mortality.pdf',
          },
          v41: {
            name: 'IQI 12 CABG Mortality',
            aria: 'View IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate PDF',
            size: '83 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 12 CABG Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
        tag: 'IQI-15',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 hospital discharges with a principal diagnosis of acute myocardial infarction (AMI) for patients ages 18 years and older. Excludes transfers to another hospital, discharges admitted from a hospice facility, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '189 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '158 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '324 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '331 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '331 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 15 Acute Myocardial Infarction Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '131 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 15 Acute Myocardial Infarction Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '600 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 15 Acute Myocardial Infarction Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '565 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 15 Acute Myocardial Infarction Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '327 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_15_Acute_Myocardial_Infarction_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '232 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_15_Acute_Myocardial_Infarction_(AMI)_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 15 Acute Myocardial Infarction Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '78 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 15 Acute Myocardial Infarction Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '68 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_15_Acute_Myocardial_Infarction_(AMI)_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '214 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '174 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '141 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '39 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 15 AMI Mortality',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '59 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 15 AMI Mortality.pdf',
          },
          v41: {
            name: 'IQI 15 AMI Mortality',
            aria: 'View IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate PDF',
            size: '83 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 15 AMI Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 16 Heart Failure Mortality Rate',
        tag: 'IQI-16',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 hospital discharges with a principal diagnosis of heart failure for patients ages 18 years and older. Excludes discharges with a procedure for heart transplant, discharges admitted from a hospice facility, transfers to another hospital, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '199 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '169 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '336 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '343 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '343 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '143 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '626 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '586 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '328 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '233 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '80 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 16 Heart Failure Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '73 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_16_Heart_Failure_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '207 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 16 Heart Failure Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 16 Heart Failure Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '163 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 16 Heart Failure Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 16 Congestive Heart Failure (CHF) Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '134 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 16 Congestive Heart Failure (CHF) Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 16 Congestive Heart Failure (CHF) Mortality Rate',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '104 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 16 Congestive Heart Failure (CHF) Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 16 Congestive Heart Failure (CHF) Mortality',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '62 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 16 Congestive Heart Failure (CHF) Mortality.pdf',
          },
          v41: {
            name: 'IQI 16 Congestive Heart Failure (CHF) Mortality',
            aria: 'View IQI 16 Heart Failure Mortality Rate PDF',
            size: '98 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 16 Congestive Heart Failure (CHF) Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 17 Acute Stroke Mortality Rate',
        tag: 'IQI-17',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 hospital discharges with a principal diagnosis of acute stroke for patients ages 18 years and older. Includes metrics for discharges grouped by type of stroke. Excludes transfers to another hospital, discharges admitted from a hospice facility, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '218 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '205 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '364 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '371 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '373 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '177 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '703 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '670 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '366 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '313 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '89 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 17 Acute Stroke Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '87 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_17_Acute_Stroke_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '221 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 17 Acute Stroke Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '168 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 17 Acute Stroke Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '137 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 17 Acute Stroke Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 17 Acute Stroke Mortality Rate',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '101 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 17 Acute Stroke Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 17 Acute Stroke Mortality',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '60 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 17 Acute Stroke Mortality.pdf',
          },
          v41: {
            name: 'IQI 17 Acute Stroke Mortality',
            aria: 'View IQI 17 Acute Stroke Mortality Rate PDF',
            size: '82 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 17 Acute Stroke Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
        tag: 'IQI-18',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 discharges with a principal diagnosis of gastrointestinal hemorrhage; or a secondary diagnosis of esophageal varices with bleeding along with a qualifying underlying principal diagnosis, for patients ages 18 years and older. Excludes discharges with a procedure for liver transplant, discharges admitted from a hospice facility, transfers to another hospital, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '225 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '208 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '372 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '381 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '380 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '182 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '376 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '676 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '348 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '243 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '117 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 18 Gastrointestinal Hemorrhage Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '70 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_18_Gastrointestinal_Hemorrhage_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '244 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 18 Gastrointestinal Hemorrhage Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '190 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 18 Gastrointestinal Hemorrhage Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '148 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 18 Gastrointestinal Hemorrhage Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '138 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 18 Gastrointestinal Hemorrhage Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '69 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 18 Gastrointestinal Hemorrhage Mortality.pdf',
          },
          v41: {
            name: 'IQI 18 Gastrointestinal Hemorrhage Mortality',
            aria: 'View IQI 18 Gastrointestinal Hemorrhage Mortality Rate',
            size: '116 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 18 Gastrointestinal Hemorrhage Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 19 Hip Fracture Mortality Rate',
        tag: 'IQI-19',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 hospital discharges with hip fracture as a principal diagnosis for patients ages 65 years and older. Excludes periprosthetic fracture discharges, discharges admitted from a hospice facility, transfers to another hospital, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '227 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '222 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '384 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '391 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '392 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '193 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '730 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '699 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '606 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '232 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '134 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 19 Hip Fracture Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '74 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_19_Hip_Fracture_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '198 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 19 Hip Fracture Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '145 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 19 Hip Fracture Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '116 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 19 Hip Fracture Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 19 Hip Fracture Mortality Rate',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '25 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 19 Hip Fracture Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 19 Hip Fracture Mortality',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '60 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 19 Hip Fracture Mortality.pdf',
          },
          v41: {
            name: 'IQI 19 Hip Fracture Mortality',
            aria: 'View IQI 19 Hip Fracture Mortality Rate',
            size: '53 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 19 Hip Fracture Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 20 Pneumonia Mortality Rate',
        tag: 'IQI-20',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 hospital discharges with a principal diagnosis of pneumonia or a principal diagnosis of sepsis with a secondary diagnosis of pneumonia present on admission, for patients ages 18 years and older. Excludes discharges with severe sepsis present on admission, transfers to another hospital, discharges admitted from a hospice facility, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '203 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '178 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '344 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '351 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '345 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '147 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '628 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '595 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '343 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '243 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '115 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 20 Pneumonia Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '70 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_20_Pneumonia_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '274 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 20 Pneumonia Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '210 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 20 Pneumonia Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '147 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 20 Pneumonia Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 20 Pneumonia Mortality Rate',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '139 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 20 Pneumonia Mortality Rate.pdf',
          },
          v42: {
            name: 'IQI 20 Pneumonia Mortality',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '67 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 20 Pneumonia Mortality.pdf',
          },
          v41: {
            name: 'IQI 20 Pneumonia Mortality',
            aria: 'View IQI 20 Pneumonia Mortality Rate',
            size: '116 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 20 Pneumonia Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 21 Cesarean Delivery Rate, Uncomplicated',
        tag: 'IQI-21',
        type: 'Hospital Level',
        desc: 'Cesarean deliveries without a hysterotomy procedure per 1,000 deliveries. Excludes deliveries with complications (abnormal presentation, preterm delivery, fetal death, multiple gestation, or breech presentation).',
        versions: {
          icd10_v2024: {
            name: 'IQI 21 Cesarean Delivery Rate, Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '194 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2023: {
            name: 'IQI 21 Cesarean Delivery Rate, Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '208 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2022: {
            name: 'IQI 21 Cesarean Delivery Rate, Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '412 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2021: {
            name: 'IQI 21 Cesarean Delivery Rate, Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '420 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2020: {
            name: 'IQI 21 Cesarean Delivery Rate, Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '420 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2019: {
            name: 'IQI 21 Cesarean Delivery Rate Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '185 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2018: {
            name: 'IQI 21 Cesarean Delivery Rate Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '636 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v70: {
            name: 'IQI 21 Cesarean Delivery Rate Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '391 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v60: {
            name: 'IQI 21 Cesarean Delivery Rate Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '414 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd9_v60: {
            name: 'IQI 21 Cesarean Delivery Rate Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '251 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_21_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v50: {
            name: 'IQI 21 Cesarean Delivery Rate, Uncomplicated',
            aria: 'View IQI 21 Cesarean Delivery Rate, Uncomplicated PDF',
            size: '599 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 21 Cesarean Delivery Rate, Uncomplicated.pdf',
          },
          icd9_v50: {
            name: 'IQI 21 Uncomplicated Cesarean Delivery Rate',
            aria: 'View IQI 21 Cesarean Delivery Rate PDF',
            size: '80 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_21_Uncomplicated_Cesarean_Delivery_Rate.pdf',
          },
          v45: {
            name: 'IQI 21 Uncomplicated Cesarean Delivery Rate',
            aria: 'View IQI 21 Cesarean Delivery Rate PDF',
            size: '220 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 21 Uncomplicated Cesarean Delivery Rate.pdf',
          },
          v44: {
            name: 'IQI 21 Cesarean Delivery Rate',
            aria: 'View IQI 21 Cesarean Delivery Rate PDF',
            size: '183 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 21 Cesarean Delivery Rate.pdf',
          },
          v43a: {
            name: 'IQI 21 Cesarean Delivery Rate',
            aria: 'View IQI 21 Cesarean Delivery Rate PDF',
            size: '132 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 21 Cesarean Delivery Rate.pdf',
          },
          v43: {
            name: 'IQI 21 Cesarean Delivery Rate',
            aria: 'View IQI 21 Cesarean Delivery Rate PDF',
            size: '46 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 21 Cesarean Delivery Rate.pdf',
          },
          v42: {
            name: 'IQI 21 Cesarean Delivery Rate',
            aria: 'View IQI 21 Cesarean Delivery Rate PDF',
            size: '74 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 21 Cesarean Delivery Rate.pdf',
          },
          v41: {
            name: 'IQI 21 Cesarean Delivery Rate',
            aria: 'View IQI 21 Cesarean Delivery Rate PDF',
            size: '60 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 21 Cesarean Delivery Rate.pdf',
          },
        },
      },
      {
        indicator: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated',
        tag: 'IQI-22',
        type: 'Hospital Level',
        desc: 'Vaginal births per 1,000 deliveries by patients with previous Cesarean deliveries. Excludes deliveries with complications (abnormal presentation, preterm delivery, fetal death, multiple gestation, or breech presentation).',
        versions: {
          icd10_v2024: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '203 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2023: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '213 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2022: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '416 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2021: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '425 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2020: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '425 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2019: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '185 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2018: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '639 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v70: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '606 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v60: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '417 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd9_v60: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '250 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v50: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC)Delivery Rate, Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '595 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 22 Vaginal Birth After Cesarean (VBAC)Delivery Rate, Uncomplicated.pdf',
          },
          icd9_v50: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Rate Uncomplicate',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '72 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_22_Vaginal_Birth_After_Cesarean_(VBAC)_Rate_Uncomplicate.pdf',
          },
          v45: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '229 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 22 Vaginal Birth After Cesarean (VBAC) Rate Uncomplicated.pdf',
          },
          v44: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '203 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 22 Vaginal Birth After Cesarean (VBAC) Rate Uncomplicated.pdf',
          },
          v43a: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '149 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 22 Vaginal Birth After Cesarean (VBAC) Rate Uncomplicated.pdf',
          },
          v43: {
            name: 'IQI 22 Vaginal Birth After Cesarean (VBAC) Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '125 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 22 Vaginal Birth After Cesarean (VBAC) Rate Uncomplicated.pdf',
          },
          v42: {
            name: 'IQI 22 VBAC Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '76 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 22 VBAC Rate Uncomplicated.pdf',
          },
          v41: {
            name: 'IQI 22 VBAC Rate Uncomplicated',
            aria: 'View 22 Vaginal Birth After Cesarean (VBAC) Delivery Rate, Uncomplicated PDF',
            size: '65 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 22 VBAC Rate Uncomplicated.pdf',
          },
        },
      },
      {
        indicator: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
        tag: 'IQI-30',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 discharges with a procedure for percutaneous coronary intervention (PCI), for patients ages 40 years and older. Excludes transfers to another hospital, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '221 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '198 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '196 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '365 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '365 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '171 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '683 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '374 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '338 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '227 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '81 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '74 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_30_Percutaneous_Coronary_Intervention_(PCI)_Mortality.pdf',
          },
          v45: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '212 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 30 Percutaneous Coronary Intervention (PCI) Mortality.pdf',
          },
          v44: {
            name: 'IQI 30 Percutaneous Coronary Intervention (PCI) Mortality',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '116 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 30 Percutaneous Coronary Intervention (PCI) Mortality.pdf',
          },
          v43a: {
            name: 'IQI 30 Percutaneous Transluminal Coronary Angioplasty (PTCA) Mortality',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '115 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 30 Percutaneous Transluminal Coronary Angioplasty (PTCA) Mortality.pdf',
          },
          v43: {
            name: 'IQI 30 Percutaneous Transluminal Coronary Angioplasty (PTCA) Mortality',
            aria: 'View 30 Percutaneous Coronary Intervention (PCI) Mortality Rate PDF',
            size: '36 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 30 Percutaneous Transluminal Coronary Angioplasty (PTCA) Mortality.pdf',
          },
        },
      },
      {
        indicator: 'IQI 31 Carotid Endarterectomy Mortality Rate',
        tag: 'IQI-31',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 discharges with a procedure for carotid endarterectomy (CEA), for patients ages 18 years and older. Excludes transfers to another hospital, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '189 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd10_v2023: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '158 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd10_v2022: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '324 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd10_v2021: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '330 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd10_v2020: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '329 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd10_v2019: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '130 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd10_v2018: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '595 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd10_v70: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '567 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd10_v60: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '327 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd9_v60: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '226 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          icd10_v50: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '74 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 31 Carotid Endarterectomy Mortality Rate.pdf',
          },
          icd9_v50: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '63 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_31_Carotid_Endarterectomy_Mortality_Rate.pdf',
          },
          v45: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '194 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 31 Carotid Endarterectomy Mortality Rate.pdf',
          },
          v44: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '139 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 31 Carotid Endarterectomy Mortality Rate.pdf',
          },
          v43a: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '124 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 31 Carotid Endarterectomy Mortality Rate.pdf',
          },
          v43: {
            name: 'IQI 31 Carotid Endarterectomy Mortality Rate',
            aria: 'View 31 Carotid Endarterectomy Mortality Rate PDF',
            size: '34 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 31 Carotid Endarterectomy Mortality Rate.pdf',
          },
        },
      },
      {
        indicator: 'IQI 33 Primary Cesarean Delivery Rate, Uncomplicated',
        tag: 'IQI-33',
        type: 'Hospital Level',
        desc: 'First-time Cesarean deliveries without a hysterotomy procedure per 1,000 deliveries. Excludes deliveries with complications (abnormal presentation, preterm delivery, fetal death, multiple gestation, or breech presentation).',
        versions: {
          icd10_v2024: {
            name: 'IQI 33 Primary Cesarean Delivery Rate, Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '199 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2023: {
            name: 'IQI 33 Primary Cesarean Delivery Rate, Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '215 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2022: {
            name: 'IQI 33 Primary Cesarean Delivery Rate, Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '416 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2021: {
            name: 'IQI 33 Primary Cesarean Delivery Rate, Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '427 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2020: {
            name: 'IQI 33 Primary Cesarean Delivery Rate, Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '426 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2019: {
            name: 'IQI 33 Primary Cesarean Delivery Rate Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '189 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v2018: {
            name: 'IQI 33 Primary Cesarean Delivery Rate Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '644 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v70: {
            name: 'IQI 33 Primary Cesarean Delivery Rate Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '395 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v60: {
            name: 'IQI 33 Primary Cesarean Delivery Rate Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '417 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd9_v60: {
            name: 'IQI 33 Primary Cesarean Delivery Rate Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '253 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          icd10_v50: {
            name: 'IQI 33 Primary Cesarean Delivery Rate, Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '146 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 33 Primary Cesarean Delivery Rate, Uncomplicated.pdf',
          },
          icd9_v50: {
            name: 'IQI 33 Primary Cesarean Delivery Rate Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '76 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_33_Primary_Cesarean_Delivery_Rate_Uncomplicated.pdf',
          },
          v45: {
            name: 'IQI 33 Primary Cesarean Delivery Rate Uncomplicated',
            aria: 'View 33 Primary Cesarean Delivery Rate, Uncomplicated PDF',
            size: '208 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 33 Primary Cesarean Delivery Rate Uncomplicated.pdf',
          },
          v44: {
            name: 'IQI 33 Primary Cesarean Delivery Rate',
            aria: 'View 33 Primary Cesarean Delivery Rate PDF',
            size: '185 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 33 Primary Cesarean Delivery Rate.pdf',
          },
          v43a: {
            name: 'IQI 33 Primary Cesarean Delivery Rate',
            aria: 'View 33 Primary Cesarean Delivery Rate PDF',
            size: '132 KB',
            url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 33 Primary Cesarean Delivery Rate.pdf',
          },
          v43: {
            name: 'IQI 33 Primary Cesarean Delivery Rate',
            aria: 'View 33 Primary Cesarean Delivery Rate PDF',
            size: '47 KB',
            url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 33 Primary Cesarean Delivery Rate.pdf',
          },
          v42: {
            name: 'IQI 33 Primary Cesarean Delivery Rate',
            aria: 'View 33 Primary Cesarean Delivery Rate PDF',
            size: '76 KB',
            url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 33 Primary Cesarean Delivery Rate.pdf',
          },
          v41: {
            name: 'IQI 33 Primary Cesarean Delivery Rate',
            aria: 'View 33 Primary Cesarean Delivery Rate PDF',
            size: '66 KB',
            url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 33 Primary Cesarean Delivery Rate.pdf',
          },
        },
      },
      {
        indicator: 'IQI 90 Mortality for Selected Inpatient Procedures ',
        tag: 'IQI-90',
        type: 'Hospital Level',
        desc: 'The weighted average of the observed-to-expected ratios for the following component indicators: IQI 08 Esophageal Resection Mortality Rate, IQI 09 Pancreatic Resection Morality Rate, IQI 11 Abdominal Aortic Aneurism (AAA) Repair Mortality Rate, IQI 12 Coronary Artery Bypass Graft (CABG) Mortality Rate, IQI 30 Percutaneous Coronary Intervention (PCI) Mortality Rate, and IQI 31 Carotid Endarterectomy Mortality Rate.',
        versions: {
          icd10_v2024: {
            name: 'IQI 90 Mortality for Selected Inpatient Procedures',
            aria: 'View 90 Mortality for Selected Inpatient Procedures PDF',
            size: '175 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI 90 Mortality for Selected Inpatient Procedures.pdf',
          },
          icd10_v2023: {
            name: 'IQI 90 Mortality for Selected Inpatient Procedures',
            aria: 'View 90 Mortality for Selected Inpatient Procedures PDF',
            size: '130 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI 90 Mortality for Selected Inpatient Procedures.pdf',
          },
          icd10_v2022: {
            name: 'IQI 90 Mortality for Selected Inpatient Procedures',
            aria: 'View 90 Mortality for Selected Inpatient Procedures PDF',
            size: '328 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI 90 Mortality for Selected Inpatient Procedures.pdf',
          },
          icd10_v2021: {
            name: 'IQI 90 Mortality for Selected Inpatient Procedures',
            aria: 'View 90 Mortality for Selected Inpatient Procedures PDF',
            size: '534 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI 90 Mortality for Selected Inpatient Procedures.pdf',
          },
          icd10_v2020: {
            name: 'IQI 90 Mortality for Selected Inpatient Procedures',
            aria: 'View 90 Mortality for Selected Inpatient Procedures PDF',
            size: '168 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI 90 Mortality for Selected Inpatient Procedures.pdf',
          },
          icd10_v2019: {
            name: 'IQI 90 Mortality for Selected Procedures',
            aria: 'View 90 Mortality for Selected Procedures PDF',
            size: '156 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI 90 Mortality for Selected Procedures.pdf',
          },
          icd9_v60: {
            name: 'IQI 90 Mortality for Selected Procedures',
            aria: 'View 90 Mortality for Selected Procedures PDF',
            size: '352 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_90_Mortality_for_Selected_Procedures.pdf',
          },
          icd10_v50: {
            name: 'IQI 90 Mortality for Selected Procedures',
            aria: 'View 90 Mortality for Selected Procedures PDF',
            size: '47 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 90 Mortality for Selected Procedures.pdf',
          },
          icd9_v50: {
            name: 'IQI 90 Mortality for Selected Procedures',
            aria: 'View 90 Mortality for Selected Procedures PDF',
            size: '62 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_90_Mortality_for_Selected_Procedures.pdf',
          },
          v45: {
            name: 'IQI 90 Mortality for Selected Inpatient Procedures',
            aria: 'View 90 Mortality for Selected Procedures PDF',
            size: '158 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 90 Mortality for Selected Procedures.pdf',
          },
        },
      },
      {
        indicator: 'IQI 91 Mortality for Selected Inpatient Conditions ',
        tag: 'IQI-91',
        type: 'Hospital Level',
        desc: 'The weighted average of the observed-to-expected ratios for the following component indicators:  IQI 15 Acute Myocardial Infarction (AMI) Mortality Rate, IQI 16 Heart Failure Mortality Rate, IQI 17 Acute Stroke Mortality Rate, IQI 18 Gastrointestinal Hemorrhage Mortality Rate, IQI 19 Hip Fracture Mortality Rate, and IQI 20 Pneumonia Mortality Rate.',
        versions: {
          icd10_v2024: {
            name: 'IQI 91 Mortality for Selected Inpatient Conditions',
            aria: 'View IQI 91 Mortality for Selected Inpatient Conditions PDF',
            size: '193 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI 91 Mortality for Selected Inpatient Conditions.pdf',
          },
          icd10_v2023: {
            name: 'IQI 91 Mortality for Selected Inpatient Conditions',
            aria: 'View IQI 91 Mortality for Selected Inpatient Conditions PDF',
            size: '136 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI 91 Mortality for Selected Inpatient Conditions.pdf',
          },
          icd10_v2022: {
            name: 'IQI 91 Mortality for Selected Inpatient Conditions',
            aria: 'View IQI 91 Mortality for Selected Inpatient Conditions PDF',
            size: '488 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI 91 Mortality for Selected Inpatient Conditions.pdf',
          },
          icd10_v2021: {
            name: 'IQI 91 Mortality for Selected Inpatient Conditions',
            aria: 'View IQI 91 Mortality for Selected Inpatient Conditions PDF',
            size: '496 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI 91 Mortality for Selected Inpatient Conditions.pdf',
          },
          icd10_v2020: {
            name: 'IQI 91 Mortality for Selected Inpatient Conditions',
            aria: 'View IQI 91 Mortality for Selected Inpatient Conditions PDF',
            size: '497 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI 91 Mortality for Selected Inpatient Conditions.pdf',
          },
          icd10_v2019: {
            name: 'IQI 91 Mortality for Selected Conditions',
            aria: 'View IQI 91 Mortality for Selected Conditions PDF',
            size: '158 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI 91 Mortality for Selected Conditions.pdf',
          },
          icd9_v60: {
            name: 'IQI 91 Mortality for Selected Conditions',
            aria: 'View IQI 91 Mortality for Selected Conditions PDF',
            size: '352 KB',
            url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_91_Mortality_for_Selected_Conditions.pdf',
          },
          icd10_v50: {
            name: 'IQI 91 Mortality for Selected Conditions',
            aria: 'View IQI 91 Mortality for Selected Conditions PDF',
            size: '47 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 91 Mortality for Selected Conditions.pdf',
          },
          icd9_v50: {
            name: 'IQI 91 Mortality for Selected Conditions',
            aria: 'View IQI 91 Mortality for Selected Conditions PDF',
            size: '62 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_91_Mortality_for_Selected_Conditions.pdf',
          },
          v45: {
            name: 'IQI 91 Mortality for Selected Conditions',
            aria: 'View IQI 91 Mortality for Selected Conditions PDF',
            size: '166 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 91 Mortality for Selected Conditions.pdf',
          },
        },
      },
    ],
    appendices: {
      indicator: 'Appendices',
      tag: 'IQI-Appendices',
      versions: {
        icd10_v2024: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '198 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_APPENDIX_A.pdf',
          },
          {
            name: 'IQI Appendix B - MDC 14 and MDC 15 Principal Diagnosis Codes',
            aria: 'View IQI Appendix B - MDC 14 and MDC 15 Principal Diagnosis Codes PDF',
            size: '621 KB',
            url: '/Downloads/Modules/IQI/V2024/TechSpecs/IQI_APPENDIX_B.pdf',
          },
        ],
        icd10_v2023: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '144 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_Appendix_A.pdf',
          },
          {
            name: 'IQI Appendix B - MDC 14 and MDC 15 Principal Diagnosis Codes',
            aria: 'View IQI Appendix B - MDC 14 and MDC 15 Principal Diagnosis Codes PDF',
            size: '800 KB',
            url: '/Downloads/Modules/IQI/V2023/TechSpecs/IQI_Appendix_B.pdf',
          },
        ],
        icd10_v2022: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '293 KB',
            url: '/Downloads/Modules/IQI/V2022/TechSpecs/IQI_Appendix_A.pdf',
          },
        ],
        icd10_v2021: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '301 KB',
            url: '/Downloads/Modules/IQI/V2021/TechSpecs/IQI_Appendix_A.pdf',
          },
        ],
        icd10_v2020: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '304 KB',
            url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_Appendix_A.pdf',
          },
        ],
        icd10_v2019: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '142 KB',
            url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_Appendix_A.pdf',
          },
        ],
        icd10_v2018: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '657 KB',
            url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_Appendix_A.pdf',
          },
        ],
        icd10_v70: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '625 KB',
            url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_Appendix_A.pdf',
          },
        ],
        icd10_v60: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '195 KB',
            url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_Appendix_A.pdf',
          },
        ],
        icd10_v50: [
          {
            name: 'IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes',
            aria: 'View IQI Appendix A - Abnormal Presentation, Preterm, Fetal Death and Multiple Gestation Diagnosis Codes PDF',
            size: '99 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI_Appendix_A.pdf',
          },
          {
            name: 'IQI Appendix B - Breech Procedure Codes',
            aria: 'View IQI Appendix B - Breech Procedure Codes PDF',
            size: '57 KB',
            url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI_Appendix_B.pdf',
          },
        ],
        icd9_v50: [
          {
            name: 'IQI Appendices',
            aria: 'View IQI Appendices PDF',
            size: '114 KB',
            url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_Appendices.pdf',
          },
        ],
        v45: [
          {
            name: 'IQI Appendices',
            aria: 'View IQI Appendices PDF',
            size: '233 KB',
            url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI Appendices.pdf',
          },
        ],
        v44: [
          {
            name: 'IQI Appendices',
            aria: 'View IQI Appendices PDF',
            size: '235 KB',
            url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI Appendices.pdf',
          },
        ],
      },
    },
    retired: {
      indicator: 'Retired IQIs',
      tag: 'IQI-Retired',
      versions: {
        'IQI 01': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_01_Esophageal_Resection_Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '704 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_01_Esophageal_Resection_Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '672 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_01_Esophageal_Resection_Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '412 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_01_Esophageal_Resection_Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '301 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 01 Esophageal Resection Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '99 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_01_Esophageal_Resection_Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '64 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 01 Esophageal Resection Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '204 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 01 Esophageal Resection Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '191 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 01 Esophogeal Resection Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '137 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 01 Esophogeal Resection Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '107 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 01 Esophogeal Resection Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '64 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 01 Esophogeal Resection Volume.pdf',
              aria: 'View IQI 01 Esophageal Resection Volume PDF',
              size: '100 KB',
              name: 'IQI 01 Esophageal Resection Volume',
            },
          ],
        },
        'IQI 02': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_02_Pancreatic_Resection_Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '551 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_02_Pancreatic_Resection_Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '522 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_02_Pancreatic_Resection_Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '398 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_02_Pancreatic_Resection_Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '307 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 02 Pancreatic Resection Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '81 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_02_Pancreatic_Resection_Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '80 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 02 Pancreatic Resection Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '219 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 02 Pancreatic Resection Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '144 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 02 Pancreatic Resection Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '124 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 02 Pancreatic Resection Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '98 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 02 Pancreatic Resection Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '53 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 02 Pancreatic Resection Volume.pdf',
              aria: 'View IQI 02 Pancreatic Resection Volume PDF',
              size: '76 KB',
              name: 'IQI 02 Pancreatic Resection Volume',
            },
          ],
        },
        'IQI 04': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_04_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '676 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_04_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '652 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_04_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '255 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_04_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '305 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '85 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_04_Abdominal_Aortic_Aneurysm_(AAA)_Repair_Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '77 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '226 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '165 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '130 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume.pdf',
              aria: 'View IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume PDF',
              size: '99 KB',
              name: 'IQI 04 Abdominal Aortic Aneurysm (AAA) Repair Volume',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 04 AAA Repair Volume.pdf',
              aria: 'View IQI 04 AAA Repair Volume PDF',
              size: '57 KB',
              name: 'IQI 04 AAA Repair Volume',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 04 AAA Repair Volume.pdf',
              aria: 'View IQI 04 AAA Repair Volume PDF',
              size: '82 KB',
              name: 'IQI 04 AAA Repair Volume',
            },
          ],
        },
        'IQI 05': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_05_Coronary_Artery_Bypass_Graft_(CABG).pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '603 KB',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG)',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_05_Coronary_Artery_Bypass_Graft_(CABG).pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '571 KB',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG)',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_05_Coronary_Artery_Bypass_Graft_(CABG).pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '363 KB',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG)',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_05_Coronary_Artery_Bypass_Graft_(CABG)_Volume.pdf',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG) Volume',
              size: '298 KB',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 05 Coronary Artery Bypass Graft (CABG).pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '89 KB',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG)',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_05_Coronary_Artery_Bypass_Graft_(CABG)_Volume.pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '61 KB',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG) Volume',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 05 Coronary Artery Bypass Graft (CABG) Volume.pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '165 KB',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG) Volume',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 05 Coronary Artery Bypass Graft (CABG) Volume.pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '158 KB',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG) Volume',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 05 Coronary Artery Bypass Graft (CABG) Volume.pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '118 KB',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG) Volume',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 05 Coronary Artery Bypass Graft (CABG) Volume.pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '99 KB',
              name: 'IQI 05 Coronary Artery Bypass Graft (CABG) Volume',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 05 CABG Volume.pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '59 KB',
              name: 'IQI 05 CABG Volume',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 05 CABG Volume.pdf',
              aria: 'View IQI 05 Coronary Artery Bypass Graft (CABG) PDF',
              size: '85 KB',
              name: 'IQI 05 CABG Volume',
            },
          ],
        },
        'IQI 06': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_06_Percutaneous_Coronary_Intervention_(PCI)_Volume.pdf',
              aria: 'View IQI 06 Percutaneous Coronary Intervention (PCI) Volume PDF',
              size: '600 KB',
              name: 'IQI 06 Percutaneous Coronary Intervention (PCI) Volume',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_06_Percutaneous_Coronary_Intervention_(PCI)_Volume.pdf',
              aria: 'View IQI 06 Percutaneous Coronary Intervention (PCI) Volume PDF',
              size: '568 KB',
              name: 'IQI 06 Percutaneous Coronary Intervention (PCI) Volume',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_06_Percutaneous_Coronary_Intervention_(PCI)_Volume.pdf',
              aria: 'View IQI 06 Percutaneous Coronary Intervention (PCI) Volume PDF',
              size: '337 KB',
              name: 'IQI 06 Percutaneous Coronary Intervention (PCI) Volume',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_06_Percutaneous_Coronary_Intervention_(PCI)_Volume.pdf',
              aria: 'View IQI 06 Percutaneous Coronary Intervention (PCI) Volume PDF',
              size: '292 KB',
              name: 'IQI 06 Percutaneous Coronary Intervention (PCI) Volume',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 06 Percutaneous Coronary Intervention (PCI) Volume.pdf',
              aria: 'View IQI 06 Percutaneous Coronary Intervention (PCI) Volume PDF',
              size: '81 KB',
              name: 'IQI 06 Percutaneous Coronary Intervention (PCI) Volume',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_06_Percutaneous_Coronary_Intervention_(PCI)_Volume_.pdf',
              name: 'IQI 06 Percutaneous Coronary Intervention (PCI) Volume ',
              size: '66 KB',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 06 Percutaneous Coronary Intervention (PCI) Volume.pdf',
              aria: 'View IQI 06 Percutaneous Coronary Intervention (PCI) Volume PDF',
              size: '185 KB',
              name: 'IQI 06 Percutaneous Coronary Intervention (PCI) Volume',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 06 Percutaneous Coronary Intervention (PCI) Volume.pdf',
              aria: 'View IQI 06 Percutaneous Coronary Intervention (PCI) Volume PDF',
              size: '153 KB',
              name: 'IQI 06 Percutaneous Coronary Intervention (PCI) Volume',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 06 Percutaneous Transluminal Coronary Angioplasty (PTCA) Volume.pdf',
              aria: 'View IQI 06 Percutaneous Transluminal Coronary Angioplasty (PTCA) Volume PDF',
              size: '115 KB',
              name: 'IQI 06 Percutaneous Transluminal Coronary Angioplasty (PTCA) Volume',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 06 Percutaneous Transluminal Coronary Angioplasty (PTCA) Volume.pdf',
              aria: 'View IQI 06 Percutaneous Transluminal Coronary Angioplasty (PTCA) Volume PDF',
              size: '98 KB',
              name: 'IQI 06 Percutaneous Transluminal Coronary Angioplasty (PTCA) Volume',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 06 and IQI 30 Percutaneous Transluminal Coronary Angioplasty.pdf',
              aria: 'View IQI 06 Percutaneous Transluminal Coronary Angioplasty (PTCA) Volume PDF',
              size: '62 KB',
              name: 'IQI 06 and IQI 30 Percutaneous Transluminal Coronary Angioplasty',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 06 and IQI 30 Percutaneous Transluminal Coronary Angioplasty.pdf',
              aria: 'View IQI 06 Percutaneous Transluminal Coronary Angioplasty (PTCA) Volume PDF',
              size: '92 KB',
              name: 'IQI 06 and IQI 30 Percutaneous Transluminal Coronary Angioplasty',
            },
          ],
        },
        'IQI 07': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_07_Carotid_Endarterectomy_Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '507 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_07_Carotid_Endarterectomy_Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '289 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_07_Carotid_Endarterectomy_Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '322 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_07_Carotid_Endarterectomy_Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '289 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 07 Carotid Endarterectomy Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '75 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_07_Carotid_Endarterectomy_Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '59 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 07 Carotid Endarterectomy Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '155 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 07 Carotid Endarterectomy Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '147 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 07 Carotid Endarterectomy Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '123 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 07 Carotid Endarterectomy Volume.pdf',
              aria: 'View IQI 07 Carotid Endarterectomy Volume PDF',
              size: '97 KB',
              name: 'IQI 07 Carotid Endarterectomy Volume',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 07 and IQI 31 Carotid Endarterectomy.pdf',
              aria: 'View IQI 07 and IQI 31 Carotid Endarterectomy PDF',
              size: '60 KB',
              name: 'IQI 07 and IQI 31 Carotid Endarterectomy',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 07 and IQI 31 Carotid Endarterectomy.pdf',
              aria: 'View IQI 07 and IQI 31 Carotid Endarterectomy PDF',
              size: '88 KB',
              name: 'IQI 07 and IQI 31 Carotid Endarterectomy',
            },
          ],
        },
        'IQI 13': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_13_Craniotomy_Mortality_Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '1 MB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_13_Craniotomy_Mortality_Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '973 KB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_13_Craniotomy_Mortality_Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '491 KB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_13_Craniotomy_Mortality_Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '354 KB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 13 Craniotomy Mortality Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '625 KB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_13_Craniotomy_Mortality_Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '254 KB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 13 Craniotomy Mortality Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '752 KB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 13 Craniotomy Mortality Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '419 KB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 13 Craniotomy Mortality Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '244 KB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 13 Craniotomy Mortality Rate.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '334 KB',
              name: 'IQI 13 Craniotomy Mortality Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 13 Craniotomy Mortality.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '169 KB',
              name: 'IQI 13 Craniotomy Mortality',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 13 Craniotomy Mortality.pdf',
              aria: 'View IQI 13 Craniotomy Mortality Rate PDF',
              size: '283 KB',
              name: 'IQI 13 Craniotomy Mortality',
            },
          ],
        },
        'IQI 14': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_14_Hip_Replacement_Mortality_Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '816 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_14_Hip_Replacement_Mortality_Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '780 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_14_Hip_Replacement_Mortality_Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '683 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_14_Hip_Replacement_Mortality_Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '239 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 14 Hip Replacement Mortality Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '110 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_14_Hip_Replacement_Mortality_Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '75 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 14 Hip Replacement Mortality Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '223 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 14 Hip Replacement Mortality Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '169 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 14 Hip Replacement Mortality Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '121 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 14 Hip Replacement Mortality Rate.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '35 KB',
              name: 'IQI 14 Hip Replacement Mortality Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 14 Hip Replacement Mortality.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '67 KB',
              name: 'IQI 14 Hip Replacement Mortality',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 14 Hip Replacement Mortality.pdf',
              aria: 'View IQI 14 Hip Replacement Mortality Rate PDF',
              size: '67 KB',
              name: 'IQI 14 Hip Replacement Mortality',
            },
          ],
        },
        'IQI 23': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_23_Laparoscopic_Cholecystectomy_Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '332 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_23_Laparoscopic_Cholecystectomy_Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '231 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 23 Laparoscopic Cholecystectomy Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '112 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_23_Laparoscopic_Cholecystectomy_Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '65 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 23 Laparoscopic Cholecystectomy Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '222 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 23 Laparoscopic Cholecystectomy Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '123 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 23 Laparoscopic Cholecystectomy Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '115 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 23 Laparoscopic Cholecystectomy Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '24 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 23 Laparoscopic Cholecystectomy Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '58 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 23 Laparoscopic Cholecystectomy Rate.pdf',
              aria: 'View IQI 23 Laparoscopic Cholecystectomy Rate PDF',
              size: '55 KB',
              name: 'IQI 23 Laparoscopic Cholecystectomy Rate',
            },
          ],
        },
        'IQI 24': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_24_Incidental_Appendectomy_in_the_Elderly_Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '3.5 MB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_24_Incidental_Appendectomy_in_the_Elderly_Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '329 KB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 24 Incidental Appendectomy in the Elderly Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '539 KB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_24_Incidental_Appendectomy_in_the_Elderly_Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '254 KB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 24 Incidental Appendectomy in the Elderly Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '601 KB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 24 Incidental Appendectomy in the Elderly Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '212 KB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 24 Incidental Appendectomy in the Elderly Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '214 KB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 24 Incidental Appendectomy in the Elderly Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '215 KB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 24 Incidental Appendectomy in the Elderly Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '140 KB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 24 Incidental Appendectomy in the Elderly Rate.pdf',
              aria: 'View IQI 24 Incidental Appendectomy in the Elderly Rate PDF',
              size: '368 KB',
              name: 'IQI 24 Incidental Appendectomy in the Elderly Rate',
            },
          ],
        },
        'IQI 25': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_25_Bilateral_Cardiac_Catheterization_Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '404 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_25_Bilateral_Cardiac_Catheterization_Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '280 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 25 Bilateral Cardiac Catheterization Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '105 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_25_Bilateral_Cardiac_Catheterization_Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '112 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 25 Bilateral Cardiac Catheterization Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '411 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 25 Bilateral Cardiac Catheterization Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '167 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 25 Bilateral Cardiac Catheterization Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '147 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 25 Bilateral Cardiac Catheterization Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '78 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 25 Bilateral Cardiac Catheterization Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '93 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 25 Bilateral Cardiac Catheterization Rate.pdf',
              aria: 'View IQI 25 Bilateral Cardiac Catheterization Rate PDF',
              size: '96 KB',
              name: 'IQI 25 Bilateral Cardiac Catheterization Rate',
            },
          ],
        },
        'IQI 26': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_26_Coronary_Artery_Bypass_Graft_(CABG)_Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '367 KB',
              name: 'IQI 26 Coronary Artery Bypass Graft (CABG) Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_26_Coronary_Artery_Bypass_Graft_(CABG)_Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '230 KB',
              name: 'IQI 26 Coronary Artery Bypass Graft (CABG) Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 26 Coronary Artery Bypass Graft (CABG) Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '89 KB',
              name: 'IQI 26 Coronary Artery Bypass Graft (CABG) Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_26_Coronary_Artery_Bypass_Graft_(CABG)_Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '68 KB',
              name: 'IQI 26 Coronary Artery Bypass Graft (CABG) Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 26 Coronary Artery Bypass Graft (CABG) Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '211 KB',
              name: 'IQI 26 Coronary Artery Bypass Graft (CABG) Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 26 Coronary Artery Bypass Graft (CABG) Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '147 KB',
              name: 'IQI 26 Coronary Artery Bypass Graft (CABG) Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 26 Coronary Artery Bypass Graft (CABG) Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '133 KB',
              name: 'IQI 26 Coronary Artery Bypass Graft (CABG) Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 26 Coronary Artery Bypass Graft (CABG) Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '156 KB',
              name: 'IQI 26 Coronary Artery Bypass Graft (CABG) Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 26 CABG Area Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '62 KB',
              name: 'IQI 26 CABG Area Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 26 CABG Area Rate.pdf',
              aria: 'View IQI 26 Coronary Artery Bypass Graft (CABG) Rate PDF',
              size: '84 KB',
              name: 'IQI 26 CABG Area Rate',
            },
          ],
        },
        'IQI 27': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_27_Percutaneous_Coronary_Intervention_(PCI)_Rate.pdf',
              aria: 'View IQI 27 Percutaneous Coronary Intervention (PCI) Rate PDF',
              size: '341 KB',
              name: 'IQI 27 Percutaneous Coronary Intervention (PCI) Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_27_Percutaneous_Coronary_Intervention_(PCI)_Rate.pdf',
              aria: 'View IQI 27 Percutaneous Coronary Intervention (PCI) Rate PDF',
              size: '229 KB',
              name: 'IQI 27 Percutaneous Coronary Intervention (PCI) Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 27 Percutaneous Coronary Intervention (PCI) Rate.pdf',
              aria: 'View IQI 27 Percutaneous Coronary Intervention (PCI) Rate PDF',
              size: '81 KB',
              name: 'IQI 27 Percutaneous Coronary Intervention (PCI) Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_27_Percutaneous_Coronary_Intervention_(PCI)_Rate.pdf',
              aria: 'View IQI 27 Percutaneous Coronary Intervention (PCI) Rate PDF',
              size: '66 KB',
              name: 'IQI 27 Percutaneous Coronary Intervention (PCI) Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 27 Percutaneous Coronary Intervention (PCI) Rate.pdf',
              aria: 'View IQI 27 Percutaneous Coronary Intervention (PCI) Rate PDF',
              size: '228 KB',
              name: 'IQI 27 Percutaneous Coronary Intervention (PCI) Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 27 Percutaneous Coronary Intervention (PCI) Rate.pdf',
              aria: 'View IQI 27 Percutaneous Coronary Intervention (PCI) Rate PDF',
              size: '158 KB',
              name: 'IQI 27 Percutaneous Coronary Intervention (PCI) Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 27 Percutaneous Transluminal Coronary Angioplasty (PTCA) Rate.pdf',
              aria: 'View IQI 27 Percutaneous Transluminal Coronary Angioplasty (PTCA) Rate PDF',
              size: '131 KB',
              name: 'IQI 27 Percutaneous Transluminal Coronary Angioplasty (PTCA) Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 27 Percutaneous Transluminal Coronary Angioplasty (PTCA) Rate.pdf',
              aria: 'View IQI 27 Percutaneous Transluminal Coronary Angioplasty (PTCA) Rate PDF',
              size: '153 KB',
              name: 'IQI 27 Percutaneous Transluminal Coronary Angioplasty (PTCA) Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 27 PCTA Area Rate.pdf',
              aria: 'View IQI 27 PCTA Area Rate PDF',
              size: '61 KB',
              name: 'IQI 27 PCTA Area Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 27 PCTA Area Rate.pdf',
              aria: 'View IQI 27 PCTA Area Rate PDF',
              size: '81 KB',
              name: 'IQI 27 PCTA Area Rate',
            },
          ],
        },
        'IQI 28': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_28_Hysterectomy_Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '410 KB',
              name: 'IQI 28 Hysterectomy Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_28_Hysterectomy_Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '248 KB',
              name: 'IQI 28 Hysterectomy Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 28 Hysterectomy Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '104 KB',
              name: 'IQI 28 Hysterectomy Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_28_Hysterectomy_Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '78 KB',
              name: 'IQI 28 Hysterectomy Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 28 Hysterectomy Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '286 KB',
              name: 'IQI 28 Hysterectomy Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 28 Hysterectomy Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '155 KB',
              name: 'IQI 28 Hysterectomy Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 28 Hysterectomy Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '147 KB',
              name: 'IQI 28 Hysterectomy Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 28 Hysterectomy Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '175 KB',
              name: 'IQI 28 Hysterectomy Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 28 Hysterectomy Area Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '74 KB',
              name: 'IQI 28 Hysterectomy Area Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 28 Hysterectomy Area Rate.pdf',
              aria: 'View IQI 28 Hysterectomy Rate PDF',
              size: '109 KB',
              name: 'IQI 28 Hysterectomy Area Rate',
            },
          ],
        },
        'IQI 29': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_29_Laminectomy_or_Spinal_Fusion_Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '504 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_29_Laminectomy_or_Spinal_Fusion_Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '235 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 29 Laminectomy or Spinal Fusion Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '150 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_29_Laminectomy_or_Spinal_Fusion_Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '70 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 29 Laminectomy or Spinal Fusion Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '250 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 29 Laminectomy or Spinal Fusion Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '173 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 29 Laminectomy or Spinal Fusion Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '137 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 29 Laminectomy or Spinal Fusion Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '166 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 29 Laminectomy or Spinal Fusion Area Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '64 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Area Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 29 Laminectomy or Spinal Fusion Area Rate.pdf',
              aria: 'View IQI 29 Laminectomy or Spinal Fusion Rate PDF',
              size: '97 KB',
              name: 'IQI 29 Laminectomy or Spinal Fusion Area Rate',
            },
          ],
        },
        'IQI 32': {
          previous: [
            {
              version: 'icd10_v2020',
              url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_32_Acute_Myocardial_Infarction_(AMI)_Mortality_Rate_Without_Transfer_Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '331 KB',
              name: 'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases',
            },
            {
              version: 'icd10_v2019',
              url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_32_Acute_Myocardial_Infarction_(AMI)_Mortality_Rate_Without_Transfer_Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '131 KB',
              name: 'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases',
            },
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_32_Acute_Myocardial_Infarction_(AMI)_Mortality_Rate_Without_Transfer_Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '603 KB',
              name: 'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_32_Acute_Myocardial_Infarction_(AMI)_Mortality_Rate_Without_Transfer_Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '569 KB',
              name: 'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_32_Acute_Myocardial_Infarction_(AMI)_Mortality_Rate_Without_Transfer_Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '330 KB',
              name: 'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_32_Acute_Myocardial_Infarction_(AMI)_Mortality_Rate_Without_Transfer_Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '232 KB',
              name: 'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '78 KB',
              name: 'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_32_AMI_Mortality_Rate_without_Transfer_Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '68 KB',
              name: 'IQI 32 AMI Mortality Rate without Transfer Cases',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 32 AMI Mortality Rate without Transfer Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '205 KB',
              name: 'IQI 32 AMI Mortality Rate without Transfer Cases',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 32 AMI Mortality Rate without Transfer Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '153 KB',
              name: 'IQI 32 AMI Mortality Rate without Transfer Cases',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate without Transfer Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '143 KB',
              name: 'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate without Transfer Cases',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate without Transfer Cases.pdf',
              aria: 'View IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate, Without Transfer Cases PDF',
              size: '39 KB',
              name: 'IQI 32 Acute Myocardial Infarction (AMI) Mortality Rate without Transfer Cases',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 32 AMI Mortality WO Transfer.pdf',
              aria: 'View IQI 32 AMI Mortality WO Transfer PDF',
              size: '60 KB',
              name: 'IQI 32 AMI Mortality WO Transfer',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 32 AMI Mortality WO Transfer.pdf',
              aria: 'View IQI 32 AMI Mortality WO Transfer PDF',
              size: '86 KB',
              name: 'IQI 32 AMI Mortality WO Transfer',
            },
          ],
        },
        'IQI 34': {
          previous: [
            {
              version: 'icd10_v2020',
              url: '/Downloads/Modules/IQI/V2020/TechSpecs/IQI_34_Vaginal_Birth_After_Cesarean_(VBAC)_Rate.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '337 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate, All',
            },
            {
              version: 'icd10_v2019',
              url: '/Downloads/Modules/IQI/V2019/TechSpecs/IQI_34_Vaginal_Birth_After_Cesarean_(VBAC)_Rate.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '138 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate',
            },
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/IQI/V2018/TechSpecs/IQI_34_Vaginal_Birth_After_Cesarean_(VBAC)_Rate.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '623 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/IQI/V70/TechSpecs/IQI_34_Vaginal_Birth_After_Cesarean_(VBAC)_Rate.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '590 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/IQI/V60-ICD10/TechSpecs/IQI_34_Vaginal_Birth_After_Cesarean_(VBAC)_Rate.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '335 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/IQI/V60/TechSpecs/IQI_34_Vaginal_Birth_After_Cesarean_(VBAC)_Rate_All.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '231 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate All',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/IQI/V50-ICD10/TechSpecs/IQI 34 Vaginal Birth After Cesarean (VBAC) Rate, All.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '108 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate, All',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/IQI/V50/TechSpecs/IQI_34_Vaginal_Birth_After_Cesarean_(VBAC)_Rate_All.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '73 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate, All',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/IQI/V45/TechSpecs/IQI 34 Vaginal Birth After Cesarean (VBAC) Rate All.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '208 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate, All',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/IQI/V44/TechSpecs/IQI 34 Vaginal Birth After Cesarean (VBAC) Rate All.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '148 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate, All',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/IQI/V43a/TechSpecs/IQI 34 Vaginal Birth After Cesarean (VBAC) Rate All.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '116 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate, All',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/IQI/V43/TechSpecs/IQI 34 Vaginal Birth After Cesarean (VBAC) Rate All.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '27 KB',
              name: 'IQI 34 Vaginal Birth After Cesarean (VBAC) Rate, All',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/IQI/V42/TechSpecs/IQI 34 VBAC Rate All.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '59 KB',
              name: 'IQI 34 VBAC Rate All',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/IQI/V41/TechSpecs/IQI 34 VBAC Rate All.pdf',
              aria: 'View IQI 34 Vaginal Birth After Cesarean (VBAC) Rate PDF',
              size: '48 KB',
              name: 'IQI 34 VBAC Rate All',
            },
          ],
        },
      },
    },
  },
  psi: {
    topic: 'PSI - Patient Safety Indicators',
    tag: 'PSI-Indicators',
    indicators: [
      {
        indicator: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
        tag: 'PSI-02',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 discharges for hospitalizations with low expected mortality (less than 0.5%) among patients ages 18 years and older. Excludes hospital discharges with trauma; cancer; an immunocompromised state; and transfers to an acute care facility.',
        versions: {
          icd10_v2024: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '183 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd10_v2023: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '187 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd10_v2022: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '177 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd10_v2021: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '361 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd10_v2020: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '357 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd10_v2019: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '358 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd10_v2018: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '671 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd10_v70: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '318 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd10_v60: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '357 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd9_v60: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '345 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_02_Death_Rate_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          icd10_v50: {
            name: 'PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '362 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs).pdf',
          },
          icd9_v50: {
            name: 'PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '112 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_02_Death_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs).pdf',
          },
          v45a: {
            name: 'PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs) V45a',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '99 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_02_Death_in_Low-Mortality_Diagnosis_Related_Groups_(DRGs)_V45a.pdf',
          },
          v45: {
            name: 'PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '387 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs).pdf',
          },
          v44: {
            name: 'PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '209 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs).pdf',
          },
          v43a: {
            name: 'PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '160 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs).pdf',
          },
          v43: {
            name: 'PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs)',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '44 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 02 Death in Low-Mortality Diagnosis Related Groups (DRGs).pdf',
          },
          v42: {
            name: 'PSI 02 Death in Low-mortality DRGs',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '65 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 02 Death in Low-mortality DRGs.pdf',
          },
          v41: {
            name: 'PSI 02 Death in Low-mortality DRGs',
            aria: 'View PSI 02 Death Rate in Low-Mortality Diagnosis Related Groups (DRGs) PDF',
            size: '88 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 02 Death in Low-mortality DRGs.pdf',
          },
        },
      },
      {
        indicator: 'PSI 03 Pressure Ulcer Rate',
        isMostPopular: true,
        tag: 'PSI-03',
        type: 'Hospital Level',
        desc: 'Stage 3 or 4 (or unstageable) pressure ulcers (secondary diagnosis not present on admission) per 1,000 hospital discharges of surgical or medical patients ages 18 years and older. Excludes discharges1 with length-of-stay less than 3 days; with a principal diagnosis of stage 3 or 4 (or unstageable) pressure ulcer or deep tissue injury at the same anatomic site; with severe burns; or with exfoliative skin disorders; and obstetric discharges. Excludes numerator events with a secondary diagnosis code for deep tissue injury or unstageable pressure ulcer present on admission at the same anatomic site.',
        versions: {
          icd10_v2024: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '315 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '299 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '441 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '416 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '807 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '383 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '744 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '350 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '570 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '337 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '667 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 03 Pressure Ulcer Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '97 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_03_Pressure_Ulcer_Rate.pdf',
          },
          v45a: {
            name: 'PSI 03 Pressure Ulcer Rate V45a',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '158 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_03_Pressure_Ulcer_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '332 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 03 Pressure Ulcer Rate.pdf',
          },
          v44: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '235 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 03 Pressure Ulcer Rate.pdf',
          },
          v43a: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '161 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 03 Pressure Ulcer Rate.pdf',
          },
          v43: {
            name: 'PSI 03 Pressure Ulcer Rate',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '95 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 03 Pressure Ulcer Rate.pdf',
          },
          v42: {
            name: 'PSI 03 Pressure Ulcer',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '59 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 03 Pressure Ulcer.pdf',
          },
          v41: {
            name: 'PSI 03 Pressure Ulcer',
            aria: 'View PSI 03 Pressure Ulcer Rate PDF',
            size: '80 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 03 Pressure Ulcer.pdf',
          },
        },
      },
      {
        indicator:
          'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
        isMostPopular: true,
        tag: 'PSI-04',
        type: 'Hospital Level',
        desc: 'In-hospital deaths per 1,000 surgical discharges among patients ages 18 through 89 years or obstetric patients of any age with serious treatable complications (shock/cardiac arrest, sepsis, pneumonia, gastrointestinal hemorrhage/acute ulcer, or deep vein thrombosis/pulmonary embolism). Measure can be stratified to report deaths by hospitalizations with each type of complication. Excludes transfers to an acute care facility and discharges admitted from a hospice facility.',
        versions: {
          icd10_v2024: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '326 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
          },
          icd10_v2023: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '331 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
          },
          icd10_v2022: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '620 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
          },
          icd10_v2021: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '633 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
          },
          icd10_v2020: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '320 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
          },
          icd10_v2019: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '607 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
          },
          icd10_v2018: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '259 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
          },
          icd10_v70: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '653 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
          },
          icd10_v60: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '246 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Conditions.pdf',
          },
          icd9_v60: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '519 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_04_Death_Rate_among_Surgical_Inpatients_with_Serious_Treatable_Complications.pdf',
          },
          icd10_v50: {
            name: 'PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications',
            aria: 'View PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Complications PDF',
            size: '865 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 04 Death Rate among Surgical Inpatients with Serious Treatable Conditions.pdf',
          },
          icd9_v50: {
            name: 'PSI 04 Death among Surgical Inpatients',
            aria: 'View PSI 04 Death among Surgical Inpatients PDF',
            size: '344 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_04_Death_among_Surgical_Inpatients.pdf',
          },
          v45a: {
            name: 'PSI 04 Death among Surgical Inpatients V45a',
            aria: 'View PSI 04 Death among Surgical Inpatients PDF',
            size: '282 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_04_Death_among_Surgical_Inpatients_V45a.pdf',
          },
          v45: {
            name: 'PSI 04 Death among Surgical Inpatients',
            aria: 'View PSI 04 Death among Surgical Inpatients PDF',
            size: '638 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 04 Death among Surgical Inpatients.pdf',
          },
          v44: {
            name: 'PSI 04 Death among Surgical Inpatients',
            aria: 'View PSI 04 Death among Surgical Inpatients PDF',
            size: '373 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 04 Death among Surgical Inpatients.pdf',
          },
          v43a: {
            name: 'PSI 04 Death among Surgical Inpatients',
            aria: 'View PSI 04 Death among Surgical Inpatients PDF',
            size: '238 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 04 Death among Surgical Inpatients.pdf',
          },
          v43: {
            name: 'PSI 04 Death among Surgical Inpatients',
            aria: 'View PSI 04 Death among Surgical Inpatients PDF',
            size: '105 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 04 Death among Surgical Inpatients.pdf',
          },
          v42: {
            name: 'PSI 04 Death among Surgical Inpatients',
            aria: 'View PSI 04 Death among Surgical Inpatients PDF',
            size: '120 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 04 Death among Surgical Inpatients.pdf',
          },
          v41: {
            name: 'PSI 04 Death among Surgical Inpatients',
            aria: 'View PSI 04 Death among Surgical Inpatients PDF',
            size: '239 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 04 Death among Surgical Inpatients.pdf',
          },
        },
      },
      {
        indicator: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
        tag: 'PSI-05',
        type: 'Hospital Level',
        desc: 'The number of hospital discharges with a retained surgical item or unretrieved device fragment (secondary diagnosis) among surgical and medical hospitalizations for patients ages 18 years and older or obstetric hospitalizations for patients of any age. Excludes discharges with principal diagnosis of retained surgical item or unretrieved device fragment; and discharges with a secondary diagnosis of retained surgical item or unretrieved device fragment present on admission.',
        versions: {
          icd10_v2024: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '178 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd10_v2023: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '177 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd10_v2022: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '336 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd10_v2021: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '347 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd10_v2020: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '343 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd10_v2019: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '344 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd10_v2018: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '118 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd10_v70: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '535 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd10_v60: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '346 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd9_v60: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '294 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          icd10_v50: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '386 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count.pdf',
          },
          icd9_v50: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '66 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
          },
          v45a: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count V45a',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count V45a PDF',
            size: '30 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_05_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count_V45a.pdf',
          },
          v45: {
            name: 'PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count',
            aria: 'View PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
            size: '189 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 05 Retained Surgical Item or Unretrieved Device Fragment Count.pdf',
          },
          v44: {
            name: 'PSI 05 Foreign Body Left During Procedure',
            aria: 'View PSI 05 Foreign Body Left During Procedure PDF',
            size: '165 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 05 Foreign Body Left During Procedure.pdf',
          },
          v43a: {
            name: 'PSI 05 Foreign Body Left During Procedure',
            aria: 'View PSI 05 Foreign Body Left During Procedure PDF',
            size: '136 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 05 Foreign Body Left During Procedure.pdf',
          },
          v43: {
            name: 'PSI 05 Foreign Body Left During Procedure',
            aria: 'View PSI 05 Foreign Body Left During Procedure PDF',
            size: '68 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 05 Foreign Body Left During Procedure.pdf',
          },
          v42: {
            name: 'PSI 05 Foreign Body Left During Procedure',
            aria: 'View PSI 05 Foreign Body Left During Procedure PDF',
            size: '40 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 05 Foreign Body Left During Procedure.pdf',
          },
          v41: {
            name: 'PSI 05 Foreign Body Left During Procedure',
            aria: 'View PSI 05 Foreign Body Left During Procedure PDF',
            size: '57 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 05 Foreign Body Left During Procedure.pdf',
          },
        },
      },
      {
        indicator: 'PSI 06 Iatrogenic Pneumothorax Rate',
        tag: 'PSI-06',
        type: 'Hospital Level',
        desc: 'Hospital discharges with iatrogenic pneumothorax (secondary diagnosis) per 1,000 surgical and medical discharges for patients ages 18 years and older. Excludes discharges with a principal diagnosis of non-traumatic pneumothorax; discharges with a secondary diagnosis of non-traumatic pneumothorax present on admission; with chest trauma (rib fractures, traumatic pneumothorax and related chest wall inquires); with pleural effusion; with thoracic surgery, including lung or pleural biopsy, or diaphragmatic repair, with a potentially trans-pleural cardiac procedure; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '1.32 MB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '1.37 MB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '1.88 MB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '1.9 MB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '1.7 MB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '1.7 MB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '4.2 MB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '1.6 MB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '1.6 MB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '395 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '1.9 MB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 06 Iatrogenic Pneumothorax Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '265 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          v45a: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate V45a',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '344 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_06_Iatrogenic_Pneumothorax_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '464 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 06 Iatrogenic Pneumothorax Rate.pdf',
          },
          v44: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '336 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 06 Iatrogenic Pneumothorax Rate.pdf',
          },
          v43a: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '219 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 06 Iatrogenic Pneumothorax Rate.pdf',
          },
          v43: {
            name: 'PSI 06 Iatrogenic Pneumothorax Rate',
            aria: 'View PSI 06 Iatrogenic Pneumothorax Rate PDF',
            size: '179 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 06 Iatrogenic Pneumothorax Rate.pdf',
          },
          v42: {
            name: 'PSI 06 Iatrogenic Pneumothorax',
            aria: 'View PSI 06 Iatrogenic Pneumothorax PDF',
            size: '118 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 06 Iatrogenic Pneumothorax.pdf',
          },
          v41: {
            name: 'PSI 06 Iatrogenic Pneumothorax',
            aria: 'View PSI 06 Iatrogenic Pneumothorax PDF',
            size: '264 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 06 Iatrogenic Pneumothorax.pdf',
          },
        },
      },
      {
        indicator: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
        tag: 'PSI-07',
        type: 'Hospital Level',
        desc: 'Hospital discharges with central venous catheter-related bloodstream infections (secondary diagnosis) per 1,000 medical and surgical discharges among hospital discharges of patients ages 18 years and older or obstetric discharges for patients of any age. Excludes discharges with a principal diagnosis of a central venous catheter-related bloodstream infection; discharges with a secondary diagnosis of a central venous catheter-related bloodstream infection present on admission; discharges with length of stay less than two (2) days; discharges with a cancer diagnosis; or discharges with an immunocompromised state.',
        versions: {
          icd10_v2024: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '167 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '172 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '323 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '331 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '330 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '127 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '622 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '587 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '328 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '303 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '461 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '100 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection.pdf',
          },
          v45a: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection V45a',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '76 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_07_Central_Venous_Catheter-Related_Blood_Stream_Infection_V45a.pdf',
          },
          v45: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '219 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 07 Central Venous Catheter-Related Blood Stream Infection.pdf',
          },
          v44: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infection',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '198 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 07 Central Venous Catheter-Related Blood Stream Infection.pdf',
          },
          v43a: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infections',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '137 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 07 Central Venous Catheter-Related Blood Stream Infections.pdf',
          },
          v43: {
            name: 'PSI 07 Central Venous Catheter-Related Blood Stream Infections',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '68 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 07 Central Venous Catheter-Related Blood Stream Infections.pdf',
          },
          v42: {
            name: 'PSI 07 Central Venous Catheter-related Bloodstream Infections',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '39 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 07 Central Venous Catheter-related Bloodstream Infections.pdf',
          },
          v41: {
            name: 'PSI 07 Central Venous Catheter-related Bloodstream Infections',
            aria: 'View PSI 07 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '41 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 07 Central Venous Catheter-related Bloodstream Infections.pdf',
          },
        },
      },
      {
        indicator: 'PSI 08 In-Hospital Fall-Associated Fracture Rate',
        tag: 'PSI-08',
        type: 'Hospital Level',
        desc: 'In-hospital fall-associated fractures (secondary diagnosis) per 1,000 discharges for patients ages 18 years and older. Includes metrics for discharges grouped by fracture type. Excludes discharges with a principal diagnosis of fracture, a secondary diagnosis of fracture present on admission, or a diagnosis of joint prosthesis-associated fracture; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PSI 08 In-Hospital Fall-Associated Fracture Rate',
            aria: 'View PSI 08 In-Hospital Fall-Associated Fracture Rate PDF',
            size: '753 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_08_In-Hospital_Fall-Associated_Fracture_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 08 In-Hospital Fall-Associated Fracture Rate',
            aria: 'View PSI 08 In-Hospital Fall-Associated Fracture Rate PDF',
            size: '762 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_08_In-Hospital_Fall-Associated_Fracture_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 08 In Hospital Fall with Hip Fracture Rate',
            aria: 'View PSI 08 In Hospital Fall with Hip Fracture Rate PDF',
            size: '385 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 08 In Hospital Fall with Hip Fracture Rate',
            aria: 'View PSI 08 In Hospital Fall with Hip Fracture Rate PDF',
            size: '393 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 08 In Hospital Fall with Hip Fracture Rate',
            aria: 'View PSI 08 In Hospital Fall with Hip Fracture Rate PDF',
            size: '776 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 08 In Hospital Fall with Hip Fracture Rate',
            aria: 'View PSI 08 In Hospital Fall with Hip Fracture Rate PDF',
            size: '767 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 08 In Hospital Fall with Hip Fracture Rate',
            aria: 'View PSI 08 In Hospital Fall with Hip Fracture Rate PDF',
            size: '430 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 08 In Hospital Fall with Hip Fracture Rate',
            aria: 'View PSI 08 In Hospital Fall with Hip Fracture Rate PDF',
            size: '1.7 MB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 08 In Hospital Fall with Hip Fracture Rate',
            aria: 'View PSI 08 In Hospital Fall with Hip Fracture Rate PDF',
            size: '1.5 MB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 08 In Hospital Fall with Hip Fracture Rate',
            aria: 'View PSI 08 In Hospital Fall with Hip Fracture Rate PDF',
            size: '498 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_08_In_Hospital_Fall_with_Hip_Fracture_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 08 Postoperative Hip Fracture Rate',
            aria: 'View PSI 08 Postoperative Hip Fracture Rate PDF',
            size: '1.2 MB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 08 Postoperative Hip Fracture Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 08 Postoperative Hip Fracture Rate',
            aria: 'View PSI 08 Postoperative Hip Fracture Rate PDF',
            size: '319 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_08_Postoperative_Hip_Fracture_Rate.pdf',
          },
          v45a: {
            name: 'PSI 08 Postoperative Hip Fracture Rate V45a',
            aria: 'View PSI 08 Postoperative Hip Fracture Rate PDF',
            size: '982 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_08_Postoperative_Hip_Fracture_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 08 Postoperative Hip Fracture Rate',
            aria: 'View PSI 08 Postoperative Hip Fracture Rate PDF',
            size: '929 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 08 Postoperative Hip Fracture Rate.pdf',
          },
          v44: {
            name: 'PSI 08 Postoperative Hip Fracture Rate',
            aria: 'View PSI 08 Postoperative Hip Fracture Rate PDF',
            size: '483 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 08 Postoperative Hip Fracture Rate.pdf',
          },
          v43a: {
            name: 'PSI 08 Postoperative Hip Fracture Rate',
            aria: 'View PSI 08 Postoperative Hip Fracture Rate PDF',
            size: '269 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 08 Postoperative Hip Fracture Rate.pdf',
          },
          v43: {
            name: 'PSI 08 Postoperative Hip Fracture Rate',
            aria: 'View PSI 08 Postoperative Hip Fracture Rate PDF',
            size: '333 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 08 Postoperative Hip Fracture Rate.pdf',
          },
          v42: {
            name: 'PSI 08 Postoperative Hip Fracture',
            aria: 'View PSI 08 Postoperative Hip Fracture Rate PDF',
            size: '196 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 08 Postoperative Hip Fracture.pdf',
          },
          v41: {
            name: 'PSI 08 Postoperative Hip Fracture',
            aria: 'View PSI 08 Postoperative Hip Fracture Rate PDF',
            size: '166 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 08 Postoperative Hip Fracture.pdf',
          },
        },
      },
      {
        indicator: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
        tag: 'PSI-09',
        type: 'Hospital Level',
        desc: 'Discharges with postoperative hemorrhage or hematoma (secondary diagnosis) associated with a procedure to treat the hemorrhage or hematoma following surgery, per 1,000 surgical discharges for patients ages 18 years and older. Excludes discharges with a principal diagnosis of postoperative hemorrhage or hematoma, or with a secondary diagnosis of postoperative hemorrhage or hematoma present on admission; discharges in which the only operating room procedure is for treatment of postoperative hemorrhage or hematoma, or discharges in which the treatment of postoperative hemorrhage or hematoma occurs before the first operating room procedure; discharges with a diagnosis of coagulation disorder; discharges with medication-related coagulopathy present on admission; discharges in which thrombolytic medication is administered before or on the same day as the first procedure for treatment of hemorrhage or hematoma; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '767 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_09_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '759 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_09_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.15 MB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_09_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.2 MB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_09_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.2 MB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_09_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.2 MB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_09_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_09_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.1 MB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_09_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_09_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '345 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_09_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '4.1 MB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 09 Perioperative Hemorrhage or Hematoma Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '333 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_09_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          v45a: {
            name: 'PSI 09 Perioperative Hemorrhage or Hematoma Rate V45a',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '117 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_09_Perioperative_Hemorrhage_or_Hematoma_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '426 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 09 Perioperative Hemorrhage or Hematoma Rate.pdf',
          },
          v44: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '180 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 09 Postoperative Hemorrhage or Hematoma Rate.pdf',
          },
          v43a: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '138 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 09 Postoperative Hemorrhage or Hematoma Rate.pdf',
          },
          v43: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '76 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 09 Postoperative Hemorrhage or Hematoma Rate.pdf',
          },
          v42: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '47 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 09 Postoperative Hemorrhage or Hematoma.pdf',
          },
          v41: {
            name: 'PSI 09 Postoperative Hemorrhage or Hematoma',
            aria: 'View PSI 09 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '85 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 09 Postoperative Hemorrhage or Hematoma.pdf',
          },
        },
      },
      {
        indicator: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
        tag: 'PSI-10',
        type: 'Hospital Level',
        desc: 'Hospital discharges with postoperative acute kidney failure (secondary diagnosis) requiring dialysis per 1,000 elective surgical discharges for patients ages 18 years and older. Excludes discharges with principal diagnosis of acute kidney failure; with secondary diagnosis of acute kidney failure present on admission; with a dialysis procedure before or on the same day as the first operating room procedure; with a dialysis access procedure before or on the same day as the first operating room procedure; with cardiac arrest, severe cardiac dysrhythmia, shock, chronic kidney disease stage 5 or end stage renal disease; with a principal diagnosis of urinary tract obstruction; with partial or total nephrectomy procedure on a solitary kidney; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '230 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis.pdf',
          },
          icd10_v2023: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '223 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis.pdf',
          },
          icd10_v2022: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '401 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis.pdf',
          },
          icd10_v2021: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '410 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '183 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '401 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '759 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '358 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '415 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate',
            aria: 'View PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate PDF',
            size: '333 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_10_Postoperative_Acute_Kidney_Injury_Requiring_Dialysis_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 10 Postoperative Physiologic and Metabolic Derangement Rate',
            aria: 'View PSI 10 Postoperative Physiologic and Metabolic Derangement Rate PDF',
            size: '473 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 10 Postoperative Physiologic and Metabolic Derangement Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 10 Postoperative Physiologic and Metabolic Derangement Rate',
            aria: 'View PSI 10 Postoperative Physiologic and Metabolic Derangement Rate PDF',
            size: '242 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_10_Postoperative_Physiologic_and_Metabolic_Derangement_Rate.pdf',
          },
          v45a: {
            name: 'PSI 10 Postoperative Physiologic and Metabolic Derangement Rate V45a',
            aria: 'View PSI 10 Postoperative Physiologic and Metabolic Derangement Rate PDF',
            size: '241 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_10_Postoperative_Physiologic_and_Metabolic_Derangement_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 10 Postoperative Physiologic and Metabolic Derangement Rate',
            aria: 'View PSI 10 Postoperative Physiologic and Metabolic Derangement Rate PDF',
            size: '382 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 10 Postoperative Physiologic and Metabolic Derangement Rate.pdf',
          },
          v44: {
            name: 'PSI 10 Postoperative Physiologic and Metabolic Derangement Rate',
            aria: 'View PSI 10 Postoperative Physiologic and Metabolic Derangement Rate PDF',
            size: '289 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 10 Postoperative Physiologic and Metabolic Derangement Rate.pdf',
          },
          v43a: {
            name: 'PSI 10 Postoperative Physiologic and Metabolic Derangement Rate',
            aria: 'View PSI 10 Postoperative Physiologic and Metabolic Derangement Rate PDF',
            size: '204 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 10 Postoperative Physiologic and Metabolic Derangement Rate.pdf',
          },
          v43: {
            name: 'PSI 10 Postoperative Physiologic and Metabolic Derangement Rate',
            aria: 'View PSI 10 Postoperative Physiologic and Metabolic Derangement Rate PDF',
            size: '76 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 10 Postoperative Physiologic and Metabolic Derangement Rate.pdf',
          },
          v42: {
            name: 'PSI 10 Postoperative Physiologic and Metabolic Derangement',
            aria: 'View PSI 10 Postoperative Physiologic and Metabolic Derangement Rate PDF',
            size: '95 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 10 Postoperative Physiologic and Metabolic Derangement.pdf',
          },
          v41: {
            name: 'PSI 10 Postoperative Physiologic and Metabolic Derangement',
            aria: 'View PSI 10 Postoperative Physiologic and Metabolic Derangement Rate PDF',
            size: '154 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 10 Postoperative Physiologic and Metabolic Derangement.pdf',
          },
        },
      },
      {
        indicator: 'PSI 11 Postoperative Respiratory Failure Rate',
        tag: 'PSI-11',
        type: 'Hospital Level',
        desc: 'Hospital discharges with postoperative respiratory failure (secondary diagnosis), prolonged mechanical ventilation, or intubation cases per 1,000 elective surgical discharges for patients ages 18 years and older. Excludes discharges with a principal diagnosis of acute respiratory failure, a secondary diagnosis of acute respiratory failure present on admission, or any diagnosis of tracheostomy present on admission; discharges in which tracheostomy is the only operating room procedure, or in which tracheostomy occurs before the first operating room procedure; discharges with malignant hyperthermia, a neuromuscular disorder present on admission, or a degenerative neurological disorder present on admission; discharges with laryngeal, pharyngeal, nose, mouth or facial surgery involving significant risk of airway compromise; discharges with esophageal surgery, a lung cancer procedure, or lung or heart transplant; discharges for treatment of respiratory diseases; and all obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '503 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '478 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '754 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '771 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '759 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '770 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '896 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '824 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '1.1 MB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '465 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 11 Postoperative Respiratory Failure Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '264 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          v45a: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate V45a',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '117 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_11_Postoperative_Respiratory_Failure_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '341 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 11 Postoperative Respiratory Failure Rate.pdf',
          },
          v44: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '269 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 11 Postoperative Respiratory Failure Rate.pdf',
          },
          v43a: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '200 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 11 Postoperative Respiratory Failure Rate.pdf',
          },
          v43: {
            name: 'PSI 11 Postoperative Respiratory Failure Rate',
            aria: 'View PSI 11 Postoperative Respiratory Failure Rate PDF',
            size: '59 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 11 Postoperative Respiratory Failure Rate.pdf',
          },
          v42: {
            name: 'PSI 11 Postoperative Respiratory Failure',
            aria: 'View PSI 11 Postoperative Respiratory Failure PDF',
            size: '58 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 11 Postoperative Respiratory Failure.pdf',
          },
          v41: {
            name: 'PSI 11 Postoperative Respiratory Failure',
            aria: 'View PSI 11 Postoperative Respiratory Failure PDF',
            size: '36 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 11 Postoperative Respiratory Failure.pdf',
          },
        },
      },
      {
        indicator: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
        tag: 'PSI-12',
        type: 'Hospital Level',
        desc: 'Hospital discharges with perioperative pulmonary embolism or proximal deep vein thrombosis (secondary diagnosis) per 1,000 surgical discharges for patients ages 18 years and older. Excludes discharges with a principal diagnosis of pulmonary embolism or proximal deep vein thrombosis, or with a secondary diagnosis of pulmonary embolism or proximal deep vein thrombosis present on admission; with heparin-induced thrombocytopenia;  discharges in which interruption of the vena cava or a pulmonary arterial or dialysis access thrombectomy occurs before or on the same day as the first operating room procedure, or is the only operating room procedure; discharges with extracorporeal membrane oxygenation; discharges with acute brain or spinal injury present on admission; discharges where the first operating room procedure occurs after or on the 10th day following admission; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '323 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '318 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '480 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '442 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '442 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '527 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '858 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '827 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '434 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '427 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '584 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '82 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate.pdf',
          },
          v45a: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate V45a',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '49 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_12_Perioperative_Pulmonary_Embolism_or_Deep_Vein_Thrombosis_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '232 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate.pdf',
          },
          v44: {
            name: 'PSI 12 Postoperative PE or DVT Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '199 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 12 Postoperative PE or DVT Rate.pdf',
          },
          v43a: {
            name: 'PSI 12 Postoperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '138 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 12 Postoperative Pulmonary Embolism or Deep Vein Thrombosis Rate.pdf',
          },
          v43: {
            name: 'PSI 12 Postoperative Pulmonary Embolism or Deep Vein Thrombosis Rate',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '113 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 12 Postoperative Pulmonary Embolism or Deep Vein Thrombosis Rate.pdf',
          },
          v42: {
            name: 'PSI 12 Postoperative Pulmonary Embolism or Deep Vein Thrombosis',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '44 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 12 Postoperative Pulmonary Embolism or Deep Vein Thrombosis.pdf',
          },
          v41: {
            name: 'PSI 12 Postoperative Pulmonary Embolism or Deep Vein Thrombosis',
            aria: 'View PSI 12 Perioperative Pulmonary Embolism or Deep Vein Thrombosis Rate PDF',
            size: '66 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 12 Postoperative Pulmonary Embolism or Deep Vein Thrombosis.pdf',
          },
        },
      },
      {
        indicator: 'PSI 13 Postoperative Sepsis Rate',
        tag: 'PSI-13',
        type: 'Hospital Level',
        desc: 'Hospital discharges with postoperative sepsis (secondary diagnosis) per 1,000 elective surgical discharges for patients ages 18 years and older. Excludes discharges with a principal diagnosis of sepsis, or with a secondary diagnosis of sepsis present on admission; discharges with a principal diagnosis of infection, or with a secondary diagnosis of infection present on admission; discharges where the first operating room procedure occurs after or on the 10th day following admission; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '201 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '200 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '332 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '337 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '337 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '335 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '633 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '600 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '334 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '305 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '391 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 13 Postoperative Sepsis Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '81 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_13_Postoperative_Sepsis_Rate.pdf',
          },
          v45a: {
            name: 'PSI 13 Postoperative Sepsis Rate V45a',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '188 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_13_Postoperative_Sepsis_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '258 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 13 Postoperative Sepsis Rate.pdf',
          },
          v44: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '213 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 13 Postoperative Sepsis Rate.pdf',
          },
          v43a: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '142 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 13 Postoperative Sepsis Rate.pdf',
          },
          v43: {
            name: 'PSI 13 Postoperative Sepsis Rate',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '79 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 13 Postoperative Sepsis Rate.pdf',
          },
          v42: {
            name: 'PSI 13 Postoperative Sepsis',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '44 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 13 Postoperative Sepsis.pdf',
          },
          v41: {
            name: 'PSI 13 Postoperative Sepsis',
            aria: 'View PSI 13 Postoperative Sepsis Rate PDF',
            size: '71 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 13 Postoperative Sepsis.pdf',
          },
        },
      },
      {
        indicator: 'PSI 14 Postoperative Wound Dehiscence Rate',
        tag: 'PSI-14',
        type: 'Hospital Level',
        desc: 'Hospital discharges with postoperative reclosure procedures involving the abdominal wall with a diagnosis of disruption of internal operation (surgical) wound per 1,000 abdominopelvic surgery discharges for patients ages 18 years and older. Exclude discharges in which the last abdominal wall reclosure procedure occurs on or before the date of the first abdominopelvic surgery; discharges with a principal diagnosis of disruption of internal operation (surgical) wound, with a secondary diagnosis of disruption of internal operation (surgical) wound present on admission, or with hospital length of stay less than two (2) days; and obstetric discharges. Measure can be stratified to report events by whether patient underwent open or non-open procedure.',
        versions: {
          icd10_v2024: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '1.97 MB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '1.99 MB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '2.54 MB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '3.1 MB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '3 MB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '3.1 MB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '8.2 MB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '3.1 MB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '3.3 MB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '394 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '2.7 MB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 14 Postoperative Wound Dehiscence Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '272 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate.pdf',
          },
          v45a: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate V45a',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '151 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_14_Postoperative_Wound_Dehiscence_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '534 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 14 Postoperative Wound Dehiscence Rate.pdf',
          },
          v44: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '325 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 14 Postoperative Wound Dehiscence Rate.pdf',
          },
          v43a: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '201 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 14 Postoperative Wound Dehiscence Rate.pdf',
          },
          v43: {
            name: 'PSI 14 Postoperative Wound Dehiscence Rate',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '174 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 14 Postoperative Wound Dehiscence Rate.pdf',
          },
          v42: {
            name: 'PSI 14 Postoperative Wound Dehiscence',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '118 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 14 Postoperative Wound Dehiscence.pdf',
          },
          v41: {
            name: 'PSI 14 Postoperative Wound Dehiscence',
            aria: 'View PSI 14 Postoperative Wound Dehiscence Rate PDF',
            size: '294 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 14 Postoperative Wound Dehiscence.pdf',
          },
        },
      },
      {
        indicator: 'PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate',
        tag: 'PSI-15',
        type: 'Hospital Level',
        desc: 'Hospital discharges with accidental punctures or lacerations (secondary diagnosis) per 1,000 discharges for patients ages 18 years and older who have undergone an abdominopelvic procedure, followed by a potentially related procedure for evaluation or treatment of the accidental puncture or laceration, one to 30 days after the index abdominopelvic procedure. Excludes discharges with accidental puncture or laceration as principal diagnosis, discharges with accidental puncture or laceration as a secondary diagnosis present on admission; discharges in which the index and/or all subsequent abdominopelvic procedure date(s) is/are missing; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '4.51 MB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_15_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '4.71 MB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_15_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '4.1 MB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_15_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '4.3 MB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_15_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '4.2 MB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_15_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '4.3 MB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_15_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '3.2 MB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_15_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v70: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '4.1 MB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_15_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v60: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '2.2 MB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_15_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd9_v60: {
            name: 'PSI 15  Unrecognized Abdominopelvic Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '394 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_15_ Unrecognized_Abdominopelvic_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v50: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '384 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 15 Accidental Puncture or Laceration Rate.pdf',
          },
          icd9_v50: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '68 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_15_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          v45a: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate V45a',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '99 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_15_Accidental_Puncture_or_Laceration_Rate_V45a.pdf',
          },
          v45: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '221 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 15 Accidental Puncture or Laceration Rate.pdf',
          },
          v44: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '164 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 15 Accidental Puncture or Laceration Rate.pdf',
          },
          v43a: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '160 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 15 Accidental Puncture or Laceration Rate.pdf',
          },
          v43: {
            name: 'PSI 15 Accidental Puncture or Laceration Rate',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '90 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 15 Accidental Puncture or Laceration Rate.pdf',
          },
          v42: {
            name: 'PSI 15 Accidental Puncture or Laceration',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '51 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 15 Accidental Puncture or Laceration.pdf',
          },
          v41: {
            name: 'PSI 15 Accidental Puncture or Laceration',
            aria: 'View PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate PDF',
            size: '109 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 15 Accidental Puncture or Laceration.pdf',
          },
        },
      },
      {
        indicator: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
        tag: 'PSI-17',
        type: 'Hospital Level',
        desc: 'Hospital discharges with birth trauma injuries per 1,000 newborns. Excludes discharges of preterm infants with a birth weight less than 2,000 grams and discharges with osteogenesis imperfecta.',
        versions: {
          icd10_v2024: {
            name: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '175 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
          },
          icd10_v2023: {
            name: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '177 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
          },
          icd10_v2022: {
            name: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '429 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
          },
          icd10_v2021: {
            name: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '440 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
          },
          icd10_v2020: {
            name: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '439 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
          },
          icd10_v2019: {
            name: 'PSI 17 Birth Trauma Rate Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '438 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
          },
          icd10_v2018: {
            name: 'PSI 17 Birth Trauma Rate Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '647 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
          },
          icd10_v70: {
            name: 'PSI 17 Birth Trauma Rate Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '613 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
          },
          icd10_v60: {
            name: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '343 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_17_Birth_Trauma_Rate-Injury_to_Neonate.pdf',
          },
          icd9_v60: {
            name: 'PSI 17 Birth Trauma-Rate Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '390 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_17_Birth_Trauma-Rate_Injury_to_Neonate.pdf',
          },
          icd10_v50: {
            name: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '484 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 17 Birth Trauma Rate-Injury to Neonate.pdf',
          },
          icd9_v50: {
            name: 'PSI 17 Birth Trauma RateInjury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '63 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_17_Birth Trauma RateInjury to Neonate.pdf',
          },
          v45a: {
            name: 'PSI 17 Birth Trauma Rate Injury Neonate v4.5a',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '158 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_17_Birth_Trauma_Rate_Injury_Neonate_v4.5a.pdf',
          },
          v45: {
            name: 'PSI 17 Birth Trauma Rate-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '230 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 17 Birth Trauma Rate-Injury to Neonate.pdf',
          },
          v44: {
            name: 'PSI 17 Birth Trauma Rate Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '184 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 17 Birth Trauma Rate Injury to Neonate.pdf',
          },
          v43a: {
            name: 'PSI 17 Birth Trauma-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '147 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 17 Birth Trauma-Injury to Neonate.pdf',
          },
          v43: {
            name: 'PSI 17 Birth Trauma-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '26 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 17 Birth Trauma-Injury to Neonate.pdf',
          },
          v42: {
            name: 'PSI 17 Birth Trauma-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '59 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 17 Birth Trauma-Injury to Neonate.pdf',
          },
          v41: {
            name: 'PSI 17 Birth Trauma-Injury to Neonate',
            aria: 'View PSI 17 Birth Trauma Rate-Injury to Neonate PDF',
            size: '109 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 17 Birth Trauma-Injury to Neonate.pdf',
          },
        },
      },
      {
        indicator: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
        tag: 'PSI-18',
        type: 'Hospital Level',
        desc: 'Hospital discharges with third or fourth degree obstetric injuries per 1,000 instrument-assisted vaginal deliveries.',
        versions: {
          icd10_v2024: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '204 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd10_v2023: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '205 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd10_v2022: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '429 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd10_v2021: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '439 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd10_v2020: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '439 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd10_v2019: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '438 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd10_v2018: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '435 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd10_v70: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '608 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd10_v60: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '521 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd9_v60: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '403 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_18_Obstetric_Trauma_Rate-Vaginal_Delivery_With_Instrument.pdf',
          },
          icd10_v50: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '473 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument.pdf',
          },
          icd9_v50: {
            name: 'PSI 18 Obstetric Trauma Rate Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '203 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_18_Obstetric_Trauma_Rate_Vaginal_Delivery_With_Instrument.pdf',
          },
          v45a: {
            name: 'PSI 18 Obstetric Trauma Rate Vaginal Delivery with Instrument v4.5a',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '164 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_18_Obstetric_Trauma_Rate_Vaginal_Delivery_with_Instrument_v4.5a.pdf',
          },
          v45: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '252 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument.pdf',
          },
          v44: {
            name: 'PSI 18 Obstetric Trauma Rate Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '164 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 18 Obstetric Trauma Rate Vaginal Delivery With Instrument.pdf',
          },
          v43a: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '128 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument.pdf',
          },
          v43: {
            name: 'PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '72 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument.pdf',
          },
          v42: {
            name: 'PSI 18 Obstetric Trauma-Vaginal Delivery with Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '57 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 18 Obstetric Trauma-Vaginal Delivery with Instrument.pdf',
          },
          v41: {
            name: 'PSI 18 Obstetric Trauma-Vaginal Delivery with Instrument',
            aria: 'View PSI 18 Obstetric Trauma Rate-Vaginal Delivery With Instrument PDF',
            size: '91 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 18 Obstetric Trauma-Vaginal Delivery with Instrument.pdf',
          },
        },
      },
      {
        indicator: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
        tag: 'PSI-19',
        type: 'Hospital Level',
        desc: 'Hospital discharges with third or fourth degree obstetric injuries per 1,000 vaginal deliveries. Exclude discharges with instrument-assisted delivery.',
        versions: {
          icd10_v2024: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '175 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd10_v2023: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '174 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd10_v2022: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '420 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd10_v2021: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '438 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd10_v2020: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '437 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd10_v2019: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '435 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd10_v2018: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '641 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd10_v70: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '609 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd10_v60: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '340 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd9_v60: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '400 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_Without_Instrument.pdf',
          },
          icd10_v50: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '473 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument.pdf',
          },
          icd9_v50: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery wo Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '76 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_wo_Instrument.pdf',
          },
          v45a: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery wo Instrument V45a',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '49 KB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_19_Obstetric_Trauma_Rate-Vaginal_Delivery_wo_Instrument_V45a.pdf',
          },
          v45: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery wo Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '220 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 19 Obstetric Trauma Rate-Vaginal Delivery wo Instrument.pdf',
          },
          v44: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery wo Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '159 KB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 19 Obstetric Trauma Rate-Vaginal Delivery wo Instrument.pdf',
          },
          v43a: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '128 KB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument.pdf',
          },
          v43: {
            name: 'PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '72 KB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument.pdf',
          },
          v42: {
            name: 'PSI 19 Obstetric Trauma-Vaginal Delivery without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '62 KB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 19 Obstetric Trauma-Vaginal Delivery without Instrument.pdf',
          },
          v41: {
            name: 'PSI 19 Obstetric Trauma-Vaginal Delivery without Instrument',
            aria: 'View PSI 19 Obstetric Trauma Rate-Vaginal Delivery Without Instrument PDF',
            size: '98 KB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 19 Obstetric Trauma-Vaginal Delivery without Instrument.pdf',
          },
        },
      },
      {
        indicator: 'PSI 90 Patient Safety and Adverse Events Composite',
        isMostPopular: true,
        tag: 'PSI-90',
        type: 'Hospital Level',
        desc: 'The weighted average of the observed-to-expected ratios for the following component indicators: PSI 03 Pressure Ulcer Rate, PSI 06 Iatrogenic Pneumothorax Rate, PSI 08 In-Hospital Fall-Associated Fracture Rate, PSI 09 Postoperative Hemorrhage or Hematoma Rate, PSI 10 Postoperative Acute Kidney Injury Requiring Dialysis Rate, PSI 11 Postoperative Respiratory Failure Rate, PSI 12 Perioperative Pulmonary Embolism (PE) or Deep Vein Thrombosis (DVT) Rate, PSI 13 Postoperative Sepsis Rate, PSI 14 Postoperative Wound Dehiscence Rate, and PSI 15 Abdominopelvic Accidental Puncture or Laceration Rate.',
        versions: {
          icd10_v2024: {
            name: 'PSI 90 Patient Safety and Adverse Events Composite',
            aria: 'View PSI 90 Patient Safety and Adverse Events Composite PDF',
            size: '162 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI 90 Patient Safety and Adverse Events Composite.pdf',
          },
          icd10_v2023: {
            name: 'PSI 90 Patient Safety and Adverse Events Composite',
            aria: 'View PSI 90 Patient Safety and Adverse Events Composite PDF',
            size: '176 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI 90 Patient Safety and Adverse Events Composite.pdf',
          },
          icd10_v2022: {
            name: 'PSI 90 Patient Safety and Adverse Events Composite',
            aria: 'View PSI 90 Patient Safety and Adverse Events Composite PDF',
            size: '538 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI 90 Patient Safety and Adverse Events Composite.pdf',
          },
          icd10_v2021: {
            name: 'PSI 90 Patient Safety and Adverse Events Composite',
            aria: 'View PSI 90 Patient Safety and Adverse Events Composite PDF',
            size: '565 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI 90 Patient Safety and Adverse Events Composite.pdf',
          },
          icd10_v2020: {
            name: 'PSI 90 Patient Safety and Adverse Events Composite',
            aria: 'View PSI 90 Patient Safety and Adverse Events Composite PDF',
            size: '659 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI 90 Patient Safety and Adverse Events Composite.pdf',
          },
          icd10_v2019: {
            name: 'PSI 90 Patient Safety and Adverse Events Composite',
            aria: 'View PSI 90 Patient Safety and Adverse Events Composite PDF',
            size: '650 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI 90 Patient Safety and Adverse Events Composite.pdf',
          },
          icd9_v60: {
            name: 'PSI 90 Patient Safety and Adverse Events Composite',
            aria: 'View PSI 90 Patient Safety and Adverse Events Composite PDF',
            size: '362 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_90_Patient_Safety_and_Adverse_Events_Composite.pdf',
          },
          icd10_v50: {
            name: 'PSI 90 Patient Safety and Adverse Events Composite',
            aria: 'View PSI 90 Patient Safety and Adverse Events Composite PDF',
            size: '44 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 90 Patient Safety for Selected Indicators.pdf',
          },
          icd9_v50: {
            name: 'PSI 90 Patient Safety for Selected Indicators',
            aria: 'View PSI 90 Patient Safety for selected indicators PDF',
            size: '61 KB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_90_Patient_Safety_for_Selected_Indicators.pdf',
          },
          v45: {
            name: 'PSI 90 Patient Safety for Selected Indicators',
            aria: 'View PSI 90 Patient Safety for selected indicators PDF',
            size: '161 KB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 90 Patient Safety for Selected Indicators.pdf',
          },
        },
      },
    ],
    appendices: {
      indicator: 'Appendices',
      tag: 'PQI-Appendices',
      versions: {
        icd10_v2024: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '9.86 MB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '204 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '214 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '397 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
           size: '1.39 MB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '309 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '214 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix J - Admission Codes for Incoming Transfer',
            aria: 'View PSI Appendix J - Admission Codes for Incoming Transfer',
            size: '141 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
           size: '154 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_M.pdf',
          },
          {
            name: 'PSI Appendix N - COVID-19 Diagnosis Codes',
            aria: 'View Appendix N - COVID-19 Diagnosis Codes PDF',
            size: '130 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_N.pdf',
          },
          {
            name: 'PSI Appendix O - MDC 14 and MDC 15 Principal Diagnosis Codes',
            aria: 'View Appendix O - MDC 14 and MDC 15 Principal Diagnosis Codes PDF',
            size: '589 KB',
            url: '/Downloads/Modules/PSI/V2024/TechSpecs/PSI_Appendix_O.pdf',
          },
        ],
        icd10_v2023: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '9.73 MB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '157 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '168 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '496 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
            size: '1.49 MB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '327 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '153 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix J - Admission Codes for Incoming Transfer',
            aria: 'View PSI Appendix J - Admission Codes for Incoming Transfer',
            size: '57 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '119 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_M.pdf',
          },
          {
            name: 'PSI Appendix N - COVID-19 Diagnosis Codes',
            aria: 'View Appendix N - COVID-19 Diagnosis Codes PDF',
            size: '56 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_N.pdf',
          },
          {
            name: 'PSI Appendix O - MDC 14 and MDC 15 Principal Diagnosis Codes',
            aria: 'View Appendix O - MDC 14 and MDC 15 Principal Diagnosis Codes PDF',
            size: '618 KB',
            url: '/Downloads/Modules/PSI/V2023/TechSpecs/PSI_Appendix_O.pdf',
          },
        ],
        icd10_v2022: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '14.3 MB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '304 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '315 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '615 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
            size: '1.97 MB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '458 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '406 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix J - Admission Codes for Incoming Transfer',
            aria: 'View PSI Appendix J - Admission Codes for Incoming Transfer',
            size: '209 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '318 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_M.pdf',
          },
          {
            name: 'PSI Appendix N - COVID-19 Diagnosis Codes',
            aria: 'View Appendix N - COVID-19 Diagnosis Codes PDF',
            size: '208 KB',
            url: '/Downloads/Modules/PSI/V2022/TechSpecs/PSI_Appendix_N.pdf',
          },
        ],
        icd10_v2021: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '14.7 MB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '312 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '327 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '629 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
            size: '2.1 MB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '469 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '414 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix J - Admission Codes for Incoming Transfer after PSI Appendix I',
            aria: 'View PSI Appendix J - Admission Codes for Incoming Transfer after PSI Appendix I PDF',
            size: '214 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '325 KB',
            url: '/Downloads/Modules/PSI/V2021/TechSpecs/PSI_Appendix_M.pdf',
          },
        ],
        icd10_v2020: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '14.1 MB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '311 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '319 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '591 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
            size: '2 MB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '490 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '303 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '326 KB',
            url: '/Downloads/Modules/PSI/V2020/TechSpecs/PSI_Appendix_M.pdf',
          },
        ],
        icd10_v2019: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '14 MB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '309 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '316 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '625 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
            size: '2.1 MB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '487 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '300 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix J - Admission Codes for Transfer',
            aria: 'View PSI Appendix J - Admission Codes for Transfer',
            size: '212 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix K - Self-Inflicted Injury Diagnosis Codes',
            aria: 'View PSI Appendix K - Self-Inflicted Injury Diagnosis Codes PDF',
            size: '315 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_K.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '325 KB',
            url: '/Downloads/Modules/PSI/V2019/TechSpecs/PSI_Appendix_M.pdf',
          },
        ],
        icd10_v2018: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '36.9 MB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '704 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '718 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '1.5 MB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
            size: '5.1 MB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '1.2 MB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '685 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix J - Admission Codes for Transfer',
            aria: 'View PSI Appendix J - Admission Codes for Transfer PDF',
            size: '483 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix K - Self-Inflicted Injury Diagnosis Codes',
            aria: 'View PSI Appendix K - Self-Inflicted Injury Diagnosis Codes PDF',
            size: '717 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_K.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '325 KB',
            url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_Appendix_M.pdf',
          },
        ],
        icd10_v70: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '34.7 MB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '673 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '687 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '1.4 MB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
            size: '5.1 MB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '1.1 MB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '652 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix J - Admission Codes for Transfer',
            aria: 'View PSI Appendix J - Admission Codes for Transfer',
            size: '450 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix K - Self-Inflicted Injury Diagnosis Codes',
            aria: 'View PSI Appendix K - Self-Inflicted Injury Diagnosis Codes PDF',
            size: '686 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_K.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '287 KB',
            url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_Appendix_M.pdf',
          },
        ],
        icd10_v60: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '13.5 MB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '211 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '220 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '514 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
            size: '2.1 MB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '401 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '195 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix J - Admission Codes for Transfer',
            aria: 'View PSI Appendix J - Admission Codes for Transfer',
            size: '118 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix K - Self-Inflicted Injury Diagnosis Codes',
            aria: 'View PSI Appendix K - Self-Inflicted Injury Diagnosis Codes PDF',
            size: '224 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_K.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '225 KB',
            url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_Appendix_M.pdf',
          },
        ],
        icd9_v60: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes PDF',
            size: '718 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs PDF',
            size: '271 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs PDF',
            size: '276 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes PDF',
            size: '336 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes PDF',
            size: '544 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes PDF',
            size: '344 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '204 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI Appendix J - Admission Codes for Transfer',
            aria: 'View PSI Appendix J - Admission Codes for Transfer',
            size: '180 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix K - Self-Inflicted Injury Diagnosis Codes',
            aria: 'View PSI Appendix K - Self-Inflicted Injury Diagnosis Codes PDF',
            size: '187 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_K.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '297 KB',
            url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_Appendix_M.pdf',
          },
        ],
        icd10_v50: [
          {
            name: 'PSI Appendix A - Operating Room Procedure Codes: (ORPROC)',
            aria: 'View PSI Appendix A - Operating Room Procedure Codes: (ORPROC) PDF',
            size: '14.2 mB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_A.pdf',
          },
          {
            name: 'PSI Appendix B - Medical Discharge DRGs (MEDICDR)',
            aria: 'View PSI Appendix B - Medical Discharge DRGs (MEDICDR) PDF',
            size: '321 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_B.pdf',
          },
          {
            name: 'PSI Appendix C - Medical Discharge MS-DRGs (MEDIC2R)',
            aria: 'View PSI Appendix C - Medical Discharge MS-DRGs (MEDIC2R) PDF',
            size: '339 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_C.pdf',
          },
          {
            name: 'PSI Appendix D - Surgical Discharge DRGs (SURGIDR)',
            aria: 'View PSI Appendix D - Surgical Discharge DRGs (SURGIDR) PDF',
            size: '202 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_D.pdf',
          },
          {
            name: 'PSI Appendix E - Surgical Discharge MS-DRGs (SURGI2R)',
            aria: 'View PSI Appendix E - Surgical Discharge MS-DRGs (SURGI2R) PDF',
            size: '225 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_E.pdf',
          },
          {
            name: 'PSI Appendix F - Infection Diagnosis Codes (INFECID)',
            aria: 'View PSI Appendix F - Infection Diagnosis Codes (INFECID) PDF',
            size: '772 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_F.pdf',
          },
          {
            name: 'PSI Appendix G - Trauma Diagnosis Codes (TRAUMID)',
            aria: 'View PSI Appendix G - Trauma Diagnosis Codes (TRAUMID) PDF',
            size: '2.7 MB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_G.pdf',
          },
          {
            name: 'PSI Appendix H - Cancer Diagnosis Codes (CANCEID)',
            aria: 'View PSI Appendix H - Cancer Diagnosis Codes (CANCEID) PDF',
            size: '703 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_H.pdf',
          },
          {
            name: 'PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes (IMMUNID)',
            aria: 'View PSI Appendix I - Immunocompromised State Diagnosis and Procedure Codes (IMMUNID) PDF',
            size: '356 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_I.pdf',
          },
          {
            name: 'PSI_Appendix J - Admission Codes for Transfers (SID ASOURCE)&(POINTOFORIGINUB04)',
            aria: 'View PSI Appendix J - Admission Codes for Transfers (SID ASOURCE)&(POINTOFORIGINUB04) PDF',
            size: '74 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_J.pdf',
          },
          {
            name: 'PSI Appendix K - Self-Inflicted Injury Diagnosis Codes (SELFIID)',
            aria: 'View PSI Appendix K - Self-Inflicted Injury Diagnosis Codes (SELFIID) PDF',
            size: '362 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_K.pdf',
          },
          {
            name: 'PSI Appendix L - Spine Surgery Procedure Codes (SPINEP)',
            aria: 'View PSI Appendix L - Spine Surgery Procedure Codes (SPINEP) PDF',
            size: '497 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_L.pdf',
          },
          {
            name: 'PSI Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View Appendix M - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '111 KB',
            url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI_Appendix_M.pdf',
          },
        ],
        icd9_v50: [
          {
            name: 'PSI Appendices',
            aria: 'View PSI Appendices PDF',
            size: '1.6 MB',
            url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_Appendices.pdf',
          },
        ],
        v45a: [
          {
            name: 'PSI Appendices',
            aria: 'View PSI Appendices PDF',
            size: '5.4 MB',
            url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_Appendices_V45a.pdf',
          },
        ],
        v45: [
          {
            name: 'PSI Appendices',
            aria: 'View PSI Appendices PDF',
            size: '5.7 MB',
            url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI Appendices.pdf',
          },
        ],
        v44: [
          {
            name: 'PSI Appendices',
            aria: 'View PSI Appendices PDF',
            size: '2.2 MB',
            url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI Appendices.pdf',
          },
        ],
        v43a: [
          {
            name: 'PSI Appendices',
            aria: 'View PSI Appendices PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI Appendices.pdf',
          },
        ],
        v43: [
          {
            name: 'PSI Appendices',
            aria: 'View PSI Appendices PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI Appendices.pdf',
          },
        ],
        v42: [
          {
            name: 'PSI Appendices',
            aria: 'View PSI Appendices PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI Appendices.pdf',
          },
        ],
        v41: [
          {
            name: 'PSI Appendices',
            aria: 'View PSI Appendices PDF',
            size: '4.2 MB',
            url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI Appendices.pdf',
          },
        ],
      },
    },
    retired: {
      indicator: 'Retired PSIs',
      tag: 'PSI-Retired',
      versions: {
        'PSI 16': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PSI/V2018/TechSpecs/PSI_16_Transfusion_Reaction_Count.pdf',
              aria: 'View PSI 16 Transfusion Reaction Count PDF',
              size: '521 KB',
              name: 'PSI 16 Transfusion Reaction Count',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PSI/V70/TechSpecs/PSI_16_Transfusion_Reaction_Count.pdf',
              aria: 'View PSI 16 Transfusion Reaction Count PDF',
              size: '487 KB',
              name: 'PSI 16 Transfusion Reaction Count',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_16_Transfusion_Reaction_Count.pdf',
              aria: 'View PSI 16 Transfusion Reaction Count PDF',
              size: '321 KB',
              name: 'PSI 16 Transfusion Reaction Count',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_16_Transfusion_Reaction_Count.pdf',
              aria: 'View PSI 16 Transfusion Reaction Count PDF',
              size: '294 KB',
              name: 'PSI 16 Transfusion Reaction Count',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 16 Transfusion Reaction Count.pdf',
              aria: 'View PSI 16 Transfusion Reaction Count PDF',
              size: '367 KB',
              name: 'PSI 16 Transfusion Reaction Count',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_16_Transfusion_Reaction_Count.pdf',
              aria: 'View PSI 16 Transfusion Reaction Count PDF',
              size: '69 KB',
              name: 'PSI 16 Transfusion Reaction Count',
            },
            {
              version: 'v45a',
              url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_16_Transfusion_Reaction_Count_V45a.pdf',
              size: '68 KB',
              name: 'PSI 16 Transfusion Reaction Count V45a',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 16 Transfusion Reaction Count.pdf',
              aria: 'View PSI 16 Transfusion Reaction Count PDF',
              size: '216 KB',
              name: 'PSI 16 Transfusion Reaction Count',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 16 Transfusion Reaction Volume.pdf',
              aria: 'View PSI 16 Transfusion Reaction PDF',
              size: '172 KB',
              name: 'PSI 16 Transfusion Reaction Volume',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 16 Transfusion Reaction Rate.pdf',
              aria: 'View PSI 16 Transfusion Reaction PDF',
              size: '137 KB',
              name: 'PSI 16 Transfusion Reaction Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 16 Transfusion Reaction Rate.pdf',
              aria: 'View PSI 16 Transfusion Reaction PDF',
              size: '23 KB',
              name: 'PSI 16 Transfusion Reaction Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 16 Transfusion Reaction.pdf',
              aria: 'View PSI 16 Transfusion Reaction PDF',
              size: '38 KB',
              name: 'PSI 16 Transfusion Reaction',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 16 Transfusion Reaction.pdf',
              aria: 'View PSI 16 Transfusion Reaction PDF',
              size: '49 KB',
              name: 'PSI 16 Transfusion Reaction',
            },
          ],
        },
        'PSI 21': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_21_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Rate.pdf',
              aria: 'View PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate PDF',
              size: '426 KB',
              name: 'PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_21_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Rate.pdf',
              aria: 'View PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate PDF',
              size: '380 KB',
              name: 'PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate.pdf',
              aria: 'View PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate PDF',
              size: '122 KB',
              name: 'PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_21_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Rate.pdf',
              aria: 'View PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate PDF',
              size: '70 KB',
              name: 'PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate',
            },
            {
              version: 'v45a',
              url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_21_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Rate_V45a.pdf',
              aria: 'View PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate PDF',
              size: '60 KB',
              name: 'PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate V45a',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate.pdf',
              aria: 'View PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate PDF',
              size: '224 KB',
              name: 'PSI 21 Retained Surgical Item or Unretrieved Device Fragment Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 21 Rate of Foreign Body Left During Procedure.pdf',
              aria: 'View PSI 21 Foreign Body Left During Procedure PDF',
              size: '172 KB',
              name: 'PSI 21 Rate of Foreign Body Left During Procedure',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 21 Foreign Body Left During Procedure.pdf',
              aria: 'View PSI 21 Foreign Body Left During Procedure PDF',
              size: '141 KB',
              name: 'PSI 21 Foreign Body Left During Procedure',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 21 Foreign Body Left During Procedure.pdf',
              aria: 'View PSI 21 Foreign Body Left During Procedure PDF',
              size: '89 KB',
              name: 'PSI 21 Foreign Body Left During Procedure',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 21 Foreign Body Left during Procedure, Area Level.pdf',
              aria: 'View PSI 21 Foreign Body Left During Procedure PDF',
              size: '41 KB',
              name: 'PSI 21 Foreign Body Left during Procedure, Area Level',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 21 Foreign Body Left during Procedure, Area Level.pdf',
              aria: 'View PSI 21 Foreign Body Left During Procedure PDF',
              size: '57 KB',
              name: 'PSI 21 Foreign Body Left during Procedure, Area Level',
            },
          ],
        },
        'PSI 22': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_22_Iatrogenic_Pneumothorax_Rate.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '1.7 MB',
              name: 'PSI 22 Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_22_Iatrogenic_Pneumothorax_Rate.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '506 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 22 Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '466 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_22_Iatrogenic_Pneumothorax_Rate.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '189 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v45a',
              url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_22_Iatrogenic_Pneumothorax_Rate_V45a.pdf',
              size: '516 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax Rate V45a',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 22 Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '551 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 22 Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '352 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 22 Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '227 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 22 Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '200 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 22 Iatrogenic Pneumothorax, Area Level.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '121 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax, Area Level',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 22 Iatrogenic Pneumothorax, Area Level.pdf',
              aria: 'View PSI 22 Iatrogenic Pneumothorax Rate PDF',
              size: '279 KB',
              name: 'PSI 22 Iatrogenic Pneumothorax, Area Level',
            },
          ],
        },
        'PSI 23': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_23_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '411 KB',
              name: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_23_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '388 KB',
              name: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '138 KB',
              name: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_23_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '82 KB',
              name: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate',
            },
            {
              version: 'v45a',
              url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_23_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate_V45a.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '68 KB',
              name: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate V45a',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '230 KB',
              name: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '202 KB',
              name: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '139 KB',
              name: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '83 KB',
              name: 'PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 23 Central Venous Catheter-related Bloodstream Infections, Area Level.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '42 KB',
              name: 'PSI 23 Central Venous Catheter-related Bloodstream Infections, Area Level',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 23 Central Venous Catheter-related Bloodstream Infections, Area Level.pdf',
              aria: 'View PSI 23 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
              size: '55 KB',
              name: 'PSI 23 Central Venous Catheter-related Bloodstream Infections, Area Level',
            },
          ],
        },
        'PSI 24': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_24_Postoperative_Wound_Dehiscence_Rate.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '572 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_24_Postoperative_Wound_Dehiscence_Rate.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '382 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 24 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '106 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_24_Postoperative_Wound_Dehiscence_Rate.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '69 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v45a',
              url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_24_Postoperative_Wound_Dehiscence_Rate_V45a.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '67 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence Rate V45a',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 24 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '214 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 24 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '169 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 24 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '128 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 24 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '84 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 24 Postoperative Wound Dehiscence, Area Level.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '38 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence, Area Level',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 24 Postoperative Wound Dehiscence, Area Level.pdf',
              aria: 'View PSI 24 Postoperative Wound Dehiscence Rate PDF',
              size: '49 KB',
              name: 'PSI 24 Postoperative Wound Dehiscence, Area Level',
            },
          ],
        },
        'PSI 25': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_25_Accidental_Puncture_or_Laceration_Rate.pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '2.1 MB',
              name: 'PSI 25 Accidental Puncture or Laceration Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_25_Accidental_Puncture_or_Laceration_Rate.pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '506 KB',
              name: 'PSI 25 Accidental Puncture or Laceration Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 25 Accidental Puncture or Laceration Rate.pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '119 KB',
              name: 'PSI 25 Accidental Puncture or Laceration Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_25_Accidental_Puncture_or_Laceration_Rate.pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '69 KB',
              name: 'PSI 25 Accidental Puncture or Laceration Rate',
            },
            {
              version: 'v45a',
              url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_25_Accidental_Puncture_or_Laceration_Rate_V45a.pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '88 KB',
              name: 'PSI 25 Accidental Puncture or Laceration Rate V45a',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 25 Accidental Puncture or Laceration Rate.pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '217 KB',
              name: 'PSI 25 Accidental Puncture or Laceration Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 25 Accidental Puncture or Laceration Rate.pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '165 KB',
              name: 'PSI 25 Accidental Puncture or Laceration Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 25 Accidental Puncture or Laceration Rate.pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '163 KB',
              name: 'PSI 25 Accidental Puncture or Laceration Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 25 Accidental Puncture or Laceration Rate.pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '104 KB',
              name: 'PSI 25 Accidental Puncture or Laceration Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 25 Accidental Puncture or Laceration, Area Level .pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '52 KB',
              name: 'PSI 25 Accidental Puncture or Laceration, Area Level ',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 25 Accidental Puncture or Laceration, Area Level .pdf',
              aria: 'View PSI 25 Accidental Puncture or Laceration Rate PDF',
              size: '35 KB',
              name: 'PSI 25 Accidental Puncture or Laceration, Area Level ',
            },
          ],
        },
        'PSI 26': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_26_Transfusion_Reaction_Rate.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '328 KB',
              name: 'PSI 26 Transfusion Reaction Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_26_Transfusion_Reaction_Rate.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '413 KB',
              name: 'PSI 26 Transfusion Reaction Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 26 Transfusion Reaction Rate.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '112 KB',
              name: 'PSI 26 Transfusion Reaction Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_26_Transfusion_Reaction_Rate.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '71 KB',
              name: 'PSI 26 Transfusion Reaction Rate',
            },
            {
              version: 'v45a',
              url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_26_Transfusion_Reaction_Rate_V45a.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '87 KB',
              name: 'PSI 26 Transfusion Reaction Rate V45a',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 26 Transfusion Reaction Rate.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '226 KB',
              name: 'PSI 26 Transfusion Reaction Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 26 Transfusion Reaction Rate.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '191 KB',
              name: 'PSI 26 Transfusion Reaction Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 26 Transfusion Reaction Rate.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '132 KB',
              name: 'PSI 26 Transfusion Reaction Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 26 Transfusion Reaction Rate.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '86 KB',
              name: 'PSI 26 Transfusion Reaction Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 26 Transfusion Reaction, Area Level.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '38 KB',
              name: 'PSI 26 Transfusion Reaction, Area Level',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 26 Transfusion Reaction, Area Level.pdf',
              aria: 'View PSI 26 Transfusion Reaction Rate PDF',
              size: '49 KB',
              name: 'PSI 26 Transfusion Reaction, Area Level',
            },
          ],
        },
        'PSI 27': {
          previous: [
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PSI/V60-ICD10/TechSpecs/PSI_27_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '2.5 MB',
              name: 'PSI 27 Perioperative Hemorrhage or Hematoma Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PSI/V60-ICD09/TechSpecs/PSI_27_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '345 KB',
              name: 'PSI 27 Perioperative Hemorrhage or Hematoma Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PSI/V50-ICD10/TechSpecs/PSI 27 Perioperative Hemorrhage or Hematoma Rate.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '1 MB',
              name: 'PSI 27 Perioperative Hemorrhage or Hematoma Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PSI/V50/TechSpecs/PSI_27_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '135 KB',
              name: 'PSI 27 Perioperative Hemorrhage or Hematoma Rate',
            },
            {
              version: 'v45a',
              url: '/Downloads/Modules/PSI/V45a/TechSpecs/PSI_27_Perioperative_Hemorrhage_or_Hematoma_Rate_V45a.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '375 KB',
              name: 'PSI 27 Perioperative Hemorrhage or Hematoma Rate V45a',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/PSI 27 Perioperative Hemorrhage or Hematoma Rate.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '467 KB',
              name: 'PSI 27 Perioperative Hemorrhage or Hematoma Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/PSI 27 Postoperative Hemorrhage or Hematoma Rate.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '160 KB',
              name: 'PSI 27 Postoperative Hemorrhage or Hematoma Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/PSI 27 Postoperative Hemorrhage or Hematoma Rate.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '127 KB',
              name: 'PSI 27 Postoperative Hemorrhage or Hematoma Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/PSI 27 Postoperative Hemorrhage or Hematoma Rate.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '93 KB',
              name: 'PSI 27 Postoperative Hemorrhage or Hematoma Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PSI/V42/TechSpecs/PSI 27 Postoperative Hemorrhage or Hematoma, Area Level.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '47 KB',
              name: 'PSI 27 Postoperative Hemorrhage or Hematoma, Area Level',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PSI/V41/TechSpecs/PSI 27 Postoperative Hemorrhage or Hematoma, Area Level.pdf',
              aria: 'View PSI 27 Perioperative Hemorrhage or Hematoma Rate PDF',
              size: '81 KB',
              name: 'PSI 27 Postoperative Hemorrhage or Hematoma, Area Level',
            },
          ],
        },
        'EXP 01': {
          previous: [
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/EXP 01 Rate of Complications of Anesthesia.pdf',
              aria: 'EXP 01 Rate of Complications of Anesthesia PDF',
              size: '257 KB',
              name: 'EXP 01 Rate of Complications of Anesthesia',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/EXP 01 Rate of Complications of Anesthesia.pdf',
              aria: 'EXP 01 Rate of Complications of Anesthesia PDF',
              size: '192 KB',
              name: 'EXP 01 Rate of Complications of Anesthesia',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/EXP 01 Rate of Complications of Anesthesia.pdf',
              aria: 'EXP 01 Rate of Complications of Anesthesia PDF',
              size: '172 KB',
              name: 'EXP 01 Rate of Complications of Anesthesia',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/EXP 01 Rate of Complications of Anesthesia.pdf',
              aria: 'EXP 01 Rate of Complications of Anesthesia PDF',
              size: '101 KB',
              name: 'EXP 01 Rate of Complications of Anesthesia',
            },
          ],
        },
        'EXP 02': {
          previous: [
            {
              version: 'v45',
              url: '/Downloads/Modules/PSI/V45/TechSpecs/EXP 02 Obstetric Trauma Rate - Cesarean Delivery.pdf',
              aria: 'EXP 02 Obstetric Trauma Rate - Cesarean Delivery PDF',
              size: '205 KB',
              name: 'EXP 02 Obstetric Trauma Rate - Cesarean Delivery',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PSI/V44/TechSpecs/EXP 02 Obstetric Trauma Rate - Cesarean Delivery.pdf',
              aria: 'EXP 02 Obstetric Trauma Rate - Cesarean Delivery PDF',
              size: '132 KB',
              name: 'EXP 02 Obstetric Trauma Rate - Cesarean Delivery',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PSI/V43a/TechSpecs/EXP 02 Obstetric Trauma Rate - Cesarean Delivery.pdf',
              aria: 'EXP 02 Obstetric Trauma Rate - Cesarean Delivery PDF',
              size: '111 KB',
              name: 'EXP 02 Obstetric Trauma Rate - Cesarean Delivery',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PSI/V43/TechSpecs/EXP 02 Obstetric Trauma Rate - Cesarean Delivery.pdf',
              aria: 'EXP 02 Obstetric Trauma Rate - Cesarean Delivery PDF',
              size: '64 KB',
              name: 'EXP 02 Obstetric Trauma Rate - Cesarean Delivery',
            },
          ],
        },
      },
    },
  },
  pdi: {
    topic: 'PDI - Pediatric Quality Indicators',
    tag: 'PDI-Indicators',
    indicators: [
      {
        indicator: 'NQI 03 Neonatal Blood Stream Infection Rate',
        tag: 'NQI-03',
        type: 'Hospital Level',
        desc: 'Hospital discharges with healthcare-associated bloodstream infection per 1,000 discharges for newborns and outborns with birth weight of 500 grams or more but less than 1,500 grams; with gestational age between 24 and 30 weeks; or with birth weight of 1,500 grams or more associated with an operating room procedure, mechanical ventilation, transfer from another hospital within two days of birth, or death. Excludes discharges with a length of stay less than 3 days and discharges with a principal diagnosis (or secondary diagnosis present on admission) of sepsis, bacteremia, staphylococcal infection, or gram-negative bacterial infection.',
        versions: {
          icd10_v2024: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '255 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2023: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '220 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2022: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '456 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2021: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '465 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2020: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '463 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2019: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '222 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2018: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '671 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v70: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '452 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v60: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '1.7 MB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd9_v60: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '415 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v50: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '174 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/NQI 03 Neonatal Blood Stream Infection Rate.pdf',
          },
          icd9_v50: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '90 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/NQI_03_Neonatal_Blood_Stream_Infection_Rate.pdf',
          },
          v45: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '286 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/NQI 03 Neonatal Blood Stream Infection Rate.pdf',
          },
          v44: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '228 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/NQI 03 Neonatal Blood Stream Infection Rate.pdf',
          },
          v43a: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '165 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/NQI 03 Neonatal Blood Stream Infection Rate.pdf',
          },
          v43: {
            name: 'NQI 03 Neonatal Blood Stream Infection Rate',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '33 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/NQI 03 Neonatal Blood Stream Infection Rate.pdf',
          },
          v42: {
            name: 'NQI 03 Neonatal Blood Stream Infection',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '51 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/NQI 03 Neonatal Blood Stream Infection.pdf',
          },
          v41: {
            name: 'NQI 03 Neonatal Blood Stream Infection',
            aria: 'NQI 03 Neonatal Blood Stream Infection Rate PDF',
            size: '163 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/NQI 03 Neonatal Blood Stream Infection.pdf',
          },
        },
      },
      {
        indicator: 'PDI 01 Accidental Puncture or Laceration Rate',
        tag: 'PDI-01',
        type: 'Hospital Level',
        desc: 'Accidental punctures or lacerations (secondary diagnosis) during a procedure per 1,000 hospital discharges for patients ages 17 years and younger. Excludes discharges with accidental puncture or laceration as principal diagnosis, with accidental puncture or laceration as a secondary diagnosis that is present on admission, or with spinal surgery; discharges for normal newborns; discharges for neonates with birth weight less than 500 grams; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '401 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '452 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '660 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '669 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '667 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '448 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '750 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '567 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '422 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '1.1 MB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 01 Accidental Puncture or Laceration Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '248 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_01_Accidental_Puncture_or_Laceration_Rate.pdf',
          },
          v45: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '318 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 01 Accidental Puncture or Laceration Rate.pdf',
          },
          v44: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '215 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 01 Accidental Puncture or Laceration Rate.pdf',
          },
          v43a: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '161 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 01 Accidental Puncture or Laceration Rate.pdf',
          },
          v43: {
            name: 'PDI 01 Accidental Puncture or Laceration Rate',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '87 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 01 Accidental Puncture or Laceration Rate.pdf',
          },
          v42: {
            name: 'PDI 01 Accidental Puncture or Laceration',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '54 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 01 Accidental Puncture or Laceration.pdf',
          },
          v41: {
            name: 'PDI 01 Accidental Puncture or Laceration',
            aria: 'View PDI 01 Accidental Puncture or Laceration Rate PDF',
            size: '154 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 01 Accidental Puncture or Laceration.pdf',
          },
        },
      },
      {
        indicator: 'PDI 05 Iatrogenic Pneumothorax Rate',
        tag: 'PDI-05',
        type: 'Hospital Level',
        desc: 'Hospital discharges with iatrogenic pneumothorax (secondary diagnosis) per 1,000 surgical or medical discharges for patients ages 17 years and younger. Excludes discharges with a principal diagnosis of non-traumatic pneumothorax, or a secondary diagnosis of non-traumatic pneumothorax present on admission; discharges with chest trauma; pleural effusion; thoracic surgery (including lung or pleural biopsy and diaphragmatic repair); potentially trans-pleural cardiac procedures; discharges of neonates with birth weight less than 2500 grams; discharges of normal newborns; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '1.32 MB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '1.87 MB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '1.87 MB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '1.9 MB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '1.7 MB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '1.6 MB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '4.1 MB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '1.8 MB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '3.4 MB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '393 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '1.8 MB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 05 Iatrogenic Pneumothorax Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '292 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_05_Iatrogenic_Pneumothorax_Rate.pdf',
          },
          v45: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '412 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 05 Iatrogenic Pneumothorax Rate.pdf',
          },
          v44: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '342 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 05 Iatrogenic Pneumothorax Rate.pdf',
          },
          v43a: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '221 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 05 Iatrogenic Pneumothorax Rate.pdf',
          },
          v43: {
            name: 'PDI 05 Iatrogenic Pneumothorax Rate',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '180 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 05 Iatrogenic Pneumothorax Rate.pdf',
          },
          v42: {
            name: 'PDI 05 Iatrogenic Pneumothorax',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '120 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 05 Iatrogenic Pneumothorax.pdf',
          },
          v41: {
            name: 'PDI 05 Iatrogenic Pneumothorax',
            aria: 'View PDI 05 Iatrogenic Pneumothorax Rate PDF',
            size: '326 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 05 Iatrogenic Pneumothorax.pdf',
          },
        },
      },
      {
        indicator: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
        tag: 'PDI-08',
        type: 'Hospital Level',
        desc: 'Discharges with postoperative hemorrhage or hematoma (secondary diagnosis) associated with a procedure to treat the hemorrhage or hematoma, following surgery per 1,000 elective surgical discharges for patients ages 17 years and younger. Excludes discharges with a principal diagnosis of postoperative hemorrhage or hematoma, or a secondary diagnosis of postoperative hemorrhage or hematoma present on admission; discharges in which the only operating room procedure is for treatment of postoperative hemorrhage or hematoma, or discharges in which the treatment of postoperative hemorrhage or hematoma occurs before the first operating room procedure; discharges for neonates with birth weight less than 500 grams; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '816 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_08_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '758 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_08_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.15 MB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_08_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_08_Postoperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_08_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.1 MB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_08_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '2.8 MB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_08_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_08_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_08_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '361 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_08_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '1.4 MB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 08 Perioperative Hemorrhage or Hematoma Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '353 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_08_Perioperative_Hemorrhage_or_Hematoma_Rate.pdf',
          },
          v45: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '532 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 08 Perioperative Hemorrhage or Hematoma Rate.pdf',
          },
          v44: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '224 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 08 Postoperative Hemorrhage or Hematoma Rate.pdf',
          },
          v43a: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '157 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 08 Postoperative Hemorrhage or Hematoma Rate.pdf',
          },
          v43: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma Rate',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '85 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 08 Postoperative Hemorrhage or Hematoma Rate.pdf',
          },
          v42: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '72 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 08 Postoperative Hemorrhage or Hematoma.pdf',
          },
          v41: {
            name: 'PDI 08 Postoperative Hemorrhage or Hematoma',
            aria: 'View PDI 08 Postoperative Hemorrhage or Hematoma Rate PDF',
            size: '157 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 08 Postoperative Hemorrhage or Hematoma.pdf',
          },
        },
      },
      {
        indicator: 'PDI 09 Postoperative Respiratory Failure Rate',
        tag: 'PDI-09',
        type: 'Hospital Level',
        desc: 'Hospital discharges with postoperative respiratory failure (secondary diagnosis), prolonged mechanical ventilation, or intubation cases per 1,000 elective surgical discharges for patients ages 17 years and younger. Excludes discharges with principal diagnosis of acute respiratory failure, a secondary diagnosis of acute respiratory failure present on admission, or any diagnosis of tracheostomy present on admission; discharges in which tracheostomy is the only operating room procedure, or in which tracheostomy occurs before the first operating room procedure; discharges with malignant hyperthermia, a neuromuscular disorder present on admission, a degenerative neurological disorder present on admission, or craniofacial anomalies; discharges with laryngeal, pharyngeal, nose, mouth, or facial surgery involving significant risk of airway compromise; discharges with esophageal surgery, a lung cancer procedure, or a lung or heart transplant; discharges for treatment of respiratory diseases; discharges of neonates with birth weight less than 500 grams; and all obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '538 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '647 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '748 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '774 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '763 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '596 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '1.7 MB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '865 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '1.1 MB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '367 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '419 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 09 Postoperative Respiratory Failure Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '263 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_09_Postoperative_Respiratory_Failure_Rate.pdf',
          },
          v45: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '373 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 09 Postoperative Respiratory Failure Rate.pdf',
          },
          v44: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '308 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 09 Postoperative Respiratory Failure Rate.pdf',
          },
          v43a: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '198 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 09 Postoperative Respiratory Failure Rate.pdf',
          },
          v43: {
            name: 'PDI 09 Postoperative Respiratory Failure Rate',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '154 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 09 Postoperative Respiratory Failure Rate.pdf',
          },
          v42: {
            name: 'PDI 09 Postoperative Respiratory Failure',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '59 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 09 Postoperative Respiratory Failure.pdf',
          },
          v41: {
            name: 'PDI 09 Postoperative Respiratory Failure',
            aria: 'View PDI 09 Postoperative Respiratory Failure Rate PDF',
            size: '123 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 09 Postoperative Respiratory Failure.pdf',
          },
        },
      },
      {
        indicator: 'PDI 10 Postoperative Sepsis Rate',
        tag: 'PDI-10',
        type: 'Hospital Level',
        desc: 'Hospital discharges with postoperative sepsis (secondary diagnosis) per 1,000 surgical discharges for patients ages 17 years and younger. Excludes discharges with a principal diagnosis of sepsis, or with a secondary diagnosis of sepsis present on admission; discharges with a principal diagnosis of infection; discharges in which the procedure belongs to surgical class 4; neonates; discharges where the first operating room procedure occurs after or on the 10th day following admission; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '232 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '192 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '448 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '460 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '457 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '263 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '876 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '529 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '611 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '411 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '349 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 10 Postoperative Sepsis Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '370 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_10_Postoperative_Sepsis_Rate.pdf',
          },
          v45: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '1.1 MB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 10 Postoperative Sepsis Rate.pdf',
          },
          v44: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '391 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 10 Postoperative Sepsis Rate.pdf',
          },
          v43a: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '261 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 10 Postoperative Sepsis Rate.pdf',
          },
          v43: {
            name: 'PDI 10 Postoperative Sepsis Rate',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '248 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 10 Postoperative Sepsis Rate.pdf',
          },
          v42: {
            name: 'PDI 10 Postoperative Sepsis',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '199 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 10 Postoperative Sepsis.pdf',
          },
          v41: {
            name: 'PDI 10 Postoperative Sepsis',
            aria: 'View PDI 10 Postoperative Sepsis Rate PDF',
            size: '72 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 10 Postoperative Sepsis.pdf',
          },
        },
      },
      {
        indicator: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
        tag: 'PDI-12',
        type: 'Hospital Level',
        desc: 'Hospital discharges with central venous catheter-related bloodstream infections (secondary diagnosis) per 1,000 medical and surgical discharges for patients ages 17 years and younger. Excludes discharges with a principal diagnosis of a central venous catheter-related bloodstream infection, or a secondary diagnosis of a central venous catheter-related bloodstream infection present on admission; discharges of normal newborns; discharges of neonates with a birth weight of less than 500 grams, discharges with length of stay less than two (2) days; and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '228 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '184 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '340 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '349 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '347 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '168 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '670 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '482 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '268 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '319 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '173 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '118 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_12_Central_Venous_Catheter-Related_Blood_Stream_Infection_Rate.pdf',
          },
          v45: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '764 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
          },
          v44: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '402 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
          },
          v43a: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '234 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
          },
          v43: {
            name: 'PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '272 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate.pdf',
          },
          v42: {
            name: 'PDI 12 Central Venous Catheter-Related Bloodstream Infection',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '133 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 12 Central Venous Catheter-Related Bloodstream Infection.pdf',
          },
          v41: {
            name: 'PDI 12 Central Venous Catheter-Related Bloodstream Infections',
            aria: 'View PDI 12 Central Venous Catheter-Related Blood Stream Infection Rate PDF',
            size: '58 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 12 Central Venous Catheter-Related Bloodstream Infections.pdf',
          },
        },
      },
      {
        indicator: 'PDI 14 Asthma Admission Rate',
        tag: 'PDI-14',
        type: 'Area Level',
        desc: 'Hospitalizations with a principal diagnosis of asthma per 100,000 population, ages 2 through 17 years. Excludes discharges with a diagnosis code for cystic fibrosis and anomalies of the respiratory system, transfers from other institutions, and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '230 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '183 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '420 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '430 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '431 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '192 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '419 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '426 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '307 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '392 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '990 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 14 Asthma Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '81 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_14_Asthma_Admission_Rate.pdf',
          },
          v45: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '204 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 14 Asthma Admission Rate.pdf',
          },
          v44: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '194 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 14 Asthma Admission Rate.pdf',
          },
          v43a: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '141 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 14 Asthma Admission Rate.pdf',
          },
          v43: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '94 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 14 Asthma Admission Rate.pdf',
          },
          v42: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '45 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 14 Asthma Admission Rate.pdf',
          },
          v41: {
            name: 'PDI 14 Asthma Admission Rate',
            aria: 'View PDI 14 Asthma Admission Rate PDF',
            size: '52 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 14 Asthma Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PDI 15 Diabetes Short-term Complications Admission Rate',
        tag: 'PDI-15',
        type: 'Area Level',
        desc: 'Hospitalizations with a principal diagnosis of diabetes with short-term complications (ketoacidosis, hyperosmolarity, or coma) per 100,000 population, ages 6 through 17 years. Excludes transfers from other institutions and obstetric discharges.',
        versions: {
          icd10_v2024: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '220 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '171 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '404 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '413 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '412 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '173 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '599 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '408 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '293 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '386 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '952 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 15 Diabetes Short-term Complications Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '70 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_15_Diabetes_Short-term_Complications_Admission_Rate.pdf',
          },
          v45: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '185 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 15 Diabetes Short-term Complications Admission Rate.pdf',
          },
          v44: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '166 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 15 Diabetes Short-term Complications Admission Rate.pdf',
          },
          v43a: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '135 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 15 Diabetes Short-term Complications Admission Rate.pdf',
          },
          v43: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '88 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 15 Diabetes Short-term Complications Admission Rate.pdf',
          },
          v42: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '40 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 15 Diabetes Short-term Complications Admission Rate.pdf',
          },
          v41: {
            name: 'PDI 15 Diabetes Short-term Complications Admission Rate',
            aria: 'View PDI 15 Diabetes Short-term Complications Admission Rate PDF',
            size: '50 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 15 Diabetes Short-term Complications Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PDI 16 Gastroenteritis Admission Rate',
        tag: 'PDI-16',
        type: 'Area Level',
        desc: 'Hospitalizations with a principal diagnosis of gastroenteritis, or with a principal diagnosis of dehydration plus a secondary diagnosis of gastroenteritis, per 100,000 population, ages 3 months to 17 years. Excludes hospitalizations transferred from another facility, hospitalizations with gastrointestinal abnormalities, hospitalizations with bacterial gastroenteritis, and obstetric hospitalizations.',
        versions: {
          icd10_v2024: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '251 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '223 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '372 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '380 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '382 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '187 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '702 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '460 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '333 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '412 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '247 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 16 Gastroenteritis Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '89 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_16_Gastroenteritis_Admission_Rate.pdf',
          },
          v45: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '273 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 16 Gastroenteritis Admission Rate.pdf',
          },
          v44: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '228 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 16 Gastroenteritis Admission Rate.pdf',
          },
          v43a: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '151 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 16 Gastroenteritis Admission Rate.pdf',
          },
          v43: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '106 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 16 Gastroenteritis Admission Rate.pdf',
          },
          v42: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '59 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 16 Gastroenteritis Admission Rate.pdf',
          },
          v41: {
            name: 'PDI 16 Gastroenteritis Admission Rate',
            aria: 'View PDI 16 Gastroenteritis Admission Rate PDF',
            size: '91 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 16 Gastroenteritis Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PDI 18 Urinary Tract Infection Admission Rate',
        tag: 'PDI-18',
        type: 'Area Level',
        desc: 'Hospitalizations with a principal diagnosis of urinary tract infection per 100,000 population, ages 3 months to 17 years. Excludes hospitalizations with a kidney or urinary tract disorder; hospitalizations with a high- or intermediate-risk immunocompromised state (including hepatic failure, cirrhosis, and transplants); transfers from other institutions; and obstetric hospitalizations.',
        versions: {
          icd10_v2024: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '242 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2023: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '215 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2022: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '356 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2021: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '363 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2020: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '362 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2019: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '174 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v2018: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '678 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v70: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '516 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v60: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '446 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd9_v60: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '411 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          icd10_v50: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '194 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 18 Urinary Tract Infection Admission Rate.pdf',
          },
          icd9_v50: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '91 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_18_Urinary_Tract_Infection_Admission_Rate.pdf',
          },
          v45: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '282 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 18 Urinary Tract Infection Admission Rate.pdf',
          },
          v44: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '221 KB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 18 Urinary Tract Infection Admission Rate.pdf',
          },
          v43a: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '149 KB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 18 Urinary Tract Infection Admission Rate.pdf',
          },
          v43: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '147 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 18 Urinary Tract Infection Admission Rate.pdf',
          },
          v42: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '53 KB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 18 Urinary Tract Infection Admission Rate.pdf',
          },
          v41: {
            name: 'PDI 18 Urinary Tract Infection Admission Rate',
            aria: 'View PDI 18 Urinary Tract Infection Admission Rate PDF',
            size: '87 KB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 18 Urinary Tract Infection Admission Rate.pdf',
          },
        },
      },
      {
        indicator: 'PDI 90 Pediatric Quality Overall Composite',
        tag: 'PDI-90',
        type: 'Area Level',
        desc: 'Pediatric Quality Indicators (PDI) overall composite per 100,000 population, ages 6 to 17 years. Includes hospitalizations for one of the following conditions: asthma, diabetes with short-term complications, gastroenteritis, or urinary tract infection.',
        versions: {
          icd10_v2024: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '155 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI 90 Pediatric Quality Overall Composite.pdf',
          },
          icd10_v2023: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '112 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI 90 Pediatric Quality Overall Composite.pdf',
          },
          icd10_v2022: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '316 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI 90 Pediatric Quality Overall Composite.pdf',
          },
          icd10_v2021: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '319 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI 90 Pediatric Quality Overall Composite.pdf',
          },
          icd10_v2020: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '319 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI 90 Pediatric Quality Overall Composite.pdf',
          },
          icd10_v2019: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '158 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI 90 Pediatric Quality Overall Composite.pdf',
          },
          icd10_v2018: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '497 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI 90 Pediatric Quality Overall Composite.pdf',
          },
          icd10_v70: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '465 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_90_Pediatric_Quality_Overall_Composite.pdf',
          },
          icd10_v60: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '376 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_90_Pediatric_Quality_Overall_Composite.pdf',
          },
          icd9_v60: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '385 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_90_Pediatric_Quality_Overall_Composite.pdf',
          },
          icd10_v50: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '40 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 90 Pediatric Quality Overall Composite.pdf',
          },
          icd9_v50: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '54 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_90_Pediatric_Quality_Overall_Composite.pdf',
          },
          v45: {
            name: 'PDI 90 Pediatric Quality Overall Composite',
            aria: 'View PDI 90 Pediatric Quality Overall Composite PDF',
            size: '165 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 90 Pediatric Quality Overall Composite.pdf',
          },
        },
      },
      {
        indicator: 'PDI 91 Pediatric Quality Acute Composite',
        tag: 'PDI-91',
        type: 'Area Level',
        desc: 'Pediatric Quality Indicators (PDI) composite of acute conditions per 100,000 population, ages 6 to 17 years. Includes hospitalizations for gastroenteritis or urinary tract infection.',
        versions: {
          icd10_v2024: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '174 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI 91 Pediatric Quality Acute Composite.pdf',
          },
          icd10_v2023: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '115 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI 91 Pediatric Quality Acute Composite.pdf',
          },
          icd10_v2022: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '316 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI 91 Pediatric Quality Acute Composite.pdf',
          },
          icd10_v2021: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '322 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI 91 Pediatric Quality Acute Composite.pdf',
          },
          icd10_v2020: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '323 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI 91 Pediatric Quality Acute Composite.pdf',
          },
          icd10_v2019: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '161 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI 91 Pediatric Quality Acute Composite.pdf',
          },
          icd10_v2018: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '498 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI 91 Pediatric Quality Acute Composite.pdf',
          },
          icd10_v70: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '468 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_91_Pediatric_Quality_Acute_Composite.pdf',
          },
          icd10_v60: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '381 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_91_Pediatric_Quality_Acute_Composite.pdf',
          },
          icd9_v60: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '388 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_91_Pediatric_Quality_Acute_Composite.pdf',
          },
          icd10_v50: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '43 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 91 Pediatric Quality Acute Composite.pdf',
          },
          icd9_v50: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '56 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_91_Pediatric_Quality_Acute_Composite.pdf',
          },
          v45: {
            name: 'PDI 91 Pediatric Quality Acute Composite',
            aria: 'View PDI 91 Pediatric Quality Acute Composite PDF',
            size: '165 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 91 Pediatric Quality Acute Composite.pdf',
          },
        },
      },
      {
        indicator: 'PDI 92 Pediatric Quality Chronic Composite',
        tag: 'PDI-92',
        type: 'Area Level',
        desc: 'Pediatric Quality Indicators (PDI) composite of chronic conditions per 100,000 population, ages 6 to 17 years. Includes hospitalizations for asthma or diabetes with short-term complications.',
        versions: {
          icd10_v2024: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '173 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI 92 Pediatric Quality Chronic Composite.pdf',
          },
          icd10_v2023: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '115 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI 92 Pediatric Quality Chronic Composite.pdf',
          },
          icd10_v2022: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '316 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI 92 Pediatric Quality Chronic Composite.pdf',
          },
          icd10_v2021: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '322 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI 92 Pediatric Quality Chronic Composite.pdf',
          },
          icd10_v2020: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '322 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI 92 Pediatric Quality Chronic Composite.pdf',
          },
          icd10_v2019: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '160 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI 92 Pediatric Quality Chronic Composite.pdf',
          },
          icd10_v2018: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '498 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI 92 Pediatric Quality Chronic Composite.pdf',
          },
          icd10_v70: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '457 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_92_Pediatric_Quality_Chronic_Composite.pdf',
          },
          icd10_v60: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '544 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_92_Pediatric_Quality_Chronic_Composite.pdf',
          },
          icd9_v60: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '388 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_92_Pediatric_Quality_Chronic_Composite.pdf',
          },
          icd10_v50: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '43 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 92 Pediatric Quality Chronic Composite.pdf',
          },
          icd9_v50: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '57 KB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_92_Pediatric_Quality_Chronic_Composite.pdf',
          },
          v45: {
            name: 'PDI 92 Pediatric Quality Chronic Composite',
            aria: 'View PDI 92 Pediatric Quality Chronic Composite PDF',
            size: '163 KB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 92 Pediatric Quality Chronic Composite.pdf',
          },
        },
      },
    ],
    appendices: {
      indicator: 'Appendices',
      tag: 'PQI-Appendices',
      versions: {
        icd10_v2024: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '10.5 MB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical discharge MS-DRGs',
            aria: 'View PDI Appendix C - Surgical discharge MS-DRGs PDF',
            size: '249 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical Discharge MS-DRGs',
            aria: 'View PDI Appendix E - Medical Discharge MS-DRGs PDF',
            size: '203 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '252 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '192 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '396 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '148 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Incoming Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Incoming Transfers PDF',
            size: '164 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '142 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '162 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer Diagnosis Codes',
            aria: 'View PDI Appendix M - Cancer Diagnosis Codes PDF',
            size: '280 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_M.pdf',
          },
          {
            name: 'PDI Appendix N - MDC 14 and MDC 15 Principal Diagnosis Codes',
            aria: 'View PDI Appendix N - MDC 14 and MDC 15 Principal Diagnosis Codes PDF',
            size: '623 KB',
            url: '/Downloads/Modules/PDI/V2024/TechSpecs/PDI_Appendix_N.pdf',
          },
        ],
        icd10_v2023: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '9.44 MB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical discharge MS-DRGs',
            aria: 'View PDI Appendix C - Surgical discharge MS-DRGs PDF',
            size: '169 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical Discharge MS-DRGs',
            aria: 'View PDI Appendix E - Medical Discharge MS-DRGs PDF',
            size: '158 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '179 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '117 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '496 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '118 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Incoming Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Incoming Transfers PDF',
            size: '100 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '61 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '64 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer Diagnosis Codes',
            aria: 'View PDI Appendix M - Cancer Diagnosis Codes PDF',
            size: '279 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_M.pdf',
          },
          {
            name: 'PDI Appendix N - MDC 14 and MDC 15 Principal Diagnosis Codes',
            aria: 'View PDI Appendix N - MDC 14 and MDC 15 Principal Diagnosis Codes PDF',
            size: '617 KB',
            url: '/Downloads/Modules/PDI/V2023/TechSpecs/PDI_Appendix_N.pdf',
          },
        ],
        icd10_v2022: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '14.4 MB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical discharge MS-DRGs',
            aria: 'View PDI Appendix C - Surgical discharge MS-DRGs PDF',
            size: '434 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical Discharge MS-DRGs',
            aria: 'View PDI Appendix E - Medical Discharge MS-DRGs PDF',
            size: '305 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '327 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '265 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '614 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '318 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Incoming Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Incoming Transfers PDF',
            size: '293 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '212 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '215 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer Diagnosis Codes',
            aria: 'View PDI Appendix M - Cancer Diagnosis Codes PDF',
            size: '415 KB',
            url: '/Downloads/Modules/PDI/V2022/TechSpecs/PDI_Appendix_M.pdf',
          },
        ],
        icd10_v2021: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '15 MB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical discharge MS-DRGs',
            aria: 'View PDI Appendix C - Surgical discharge MS-DRGs PDF',
            size: '445 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical Discharge MS-DRGs',
            aria: 'View PDI Appendix E - Medical Discharge MS-DRGs PDF',
            size: '313 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '336 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '270 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '628 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '326 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Transfers PDF',
            size: '301 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '217 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '220 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer',
            aria: 'View PDI Appendix M - Cancer PDF',
            size: '422 KB',
            url: '/Downloads/Modules/PDI/V2021/TechSpecs/PDI_Appendix_M.pdf',
          },
        ],
        icd10_v2020: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '14.1 MB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical discharge MS-DRGs',
            aria: 'View PDI Appendix C - Surgical discharge MS-DRGs PDF',
            size: '319 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical Discharge MS-DRGs',
            aria: 'View PDI Appendix E - Medical Discharge MS-DRGs PDF',
            size: '312 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '328 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '266 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '591 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '326 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Transfers PDF',
            size: '302 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '218 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '220 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer',
            aria: 'View PDI Appendix M - Cancer PDF',
            size: '415 KB',
            url: '/Downloads/Modules/PDI/V2020/TechSpecs/PDI_Appendix_M.pdf',
          },
        ],
        icd10_v2019: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '15 MB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical discharge MS-DRGs',
            aria: 'View PDI Appendix C - Surgical discharge MS-DRGs PDF',
            size: '173 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical Discharge MS-DRGs',
            aria: 'View PDI Appendix E - Medical Discharge MS-DRGs PDF',
            size: '166 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '183 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '117 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '506 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '125 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Transfers PDF',
            size: '61 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '67 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '68 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer',
            aria: 'View PDI Appendix M - Cancer PDF',
            size: '277 KB',
            url: '/Downloads/Modules/PDI/V2019/TechSpecs/PDI_Appendix_M.pdf',
          },
        ],
        icd10_v2018: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '36.9 MB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical discharge MS-DRGs',
            aria: 'View PDI Appendix C - Surgical discharge MS-DRGs PDF',
            size: '703 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical Discharge MS-DRGs',
            aria: 'View PDI Appendix E - Medical Discharge MS-DRGs PDF',
            size: '707 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '764 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '617 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '1.5 MB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '325 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Transfers PDF',
            size: '399 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '86 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '497 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer',
            aria: 'View PDI Appendix M - Cancer PDF',
            size: '979 KB',
            url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_Appendix_M.pdf',
          },
        ],
        icd10_v70: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '34.8 MB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical discharge MS-DRGs',
            aria: 'View PDI Appendix C - Surgical discharge MS-DRGs PDF',
            size: '691 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical Discharge MS-DRGs',
            aria: 'View PDI Appendix E - Medical Discharge MS-DRGs PDF',
            size: '676 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '733 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '581 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '1.4 MB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '288 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Transfers PDF',
            size: '365 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '86 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '465 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer',
            aria: 'View PDI Appendix M - Cancer PDF',
            size: '942 KB',
            url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_Appendix_M.pdf',
          },
        ],
        icd10_v60: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '15.7 MB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical MS-DRGs',
            aria: 'View PDI Appendix C - Surgical MS-DRGs PDF',
            size: '226 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical MS-DRGs',
            aria: 'View PDI Appendix E - Medical MS-DRGs PDF',
            size: '214 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '236 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '175 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '1.3 MB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '289 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Transfers PDF',
            size: '119 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '86 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '405 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI_Appendix M - Cancer',
            aria: 'View PDI Appendix M - Cancer PDF',
            size: '845 KB',
            url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_Appendix_M.pdf',
          },
        ],
        icd9_v60: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '1.9 MB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical discharge MS-DRG codes:',
            aria: 'View PDI Appendix C - Surgical discharge MS-DRG codes PDF',
            size: '693 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix E - Medical discharge MS-DRG codes',
            aria: 'View PDI Appendix E - Medical discharge MS-DRG codes PDF',
            size: '677 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '605 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-risk Immunocompromised State Diagnosis Codes PDF',
            size: '508 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '875 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '492 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Transfers PDF',
            size: '448 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '86 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '491 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer',
            aria: 'View PDI Appendix M - Cancer PDF',
            size: '730 KB',
            url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_Appendix_M.pdf',
          },
        ],
        icd10_v50: [
          {
            name: 'PDI Appendix A - Operating Room Procedure Codes',
            aria: 'View PDI Appendix A - Operating Room Procedure Codes PDF',
            size: '2.6 MB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_A.pdf',
          },
          {
            name: 'PDI Appendix B - Surgical DRGs',
            aria: 'View PDI Appendix B - Surgical DRGs PDF',
            size: '128 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_B.pdf',
          },
          {
            name: 'PDI Appendix C - Surgical MS-DRGs',
            aria: 'View PDI Appendix C - Surgical MS-DRGs PDF',
            size: '130 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_C.pdf',
          },
          {
            name: 'PDI Appendix D - Medical DRGs',
            aria: 'View PDI Appendix D - Medical DRGs PDF',
            size: '126 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_D.pdf',
          },
          {
            name: 'PDI Appendix E - Medical MS-DRGs',
            aria: 'View PDI Appendix E - Medical MS-DRGs PDF',
            size: '125 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_E.pdf',
          },
          {
            name: 'PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes',
            aria: 'View PDI Appendix F - High-Risk Immunocompromised State Diagnosis and Procedure Codes PDF',
            size: '110 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_F.pdf',
          },
          {
            name: 'PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes',
            aria: 'View PDI Appendix G - Intermediate-Risk Immunocompromised State Diagnosis Codes PDF',
            size: '94 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_G.pdf',
          },
          {
            name: 'PDI Appendix H - Infection Diagnosis Codes',
            aria: 'View PDI Appendix H - Infection Diagnosis Codes PDF',
            size: '170 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_H.pdf',
          },
          {
            name: 'PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn',
            aria: 'View PDI Appendix I - Definitions of Neonate, Newborn, Normal Newborn, and Outborn PDF',
            size: '112 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_I.pdf',
          },
          {
            name: 'PDI Appendix J - Admission Codes for Transfers',
            aria: 'View PDI Appendix J - Admission Codes for Transfers PDF',
            size: '107 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_J.pdf',
          },
          {
            name: 'PDI Appendix K - Stratification',
            aria: 'View PDI Appendix K - Stratification PDF',
            size: '73 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_K.pdf',
          },
          {
            name: 'PDI Appendix L - Low Birth Weight Categories',
            aria: 'View PDI Appendix L - Low Birth Weight Categories PDF',
            size: '535 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_L.pdf',
          },
          {
            name: 'PDI Appendix M - Cancer',
            aria: 'View PDI Appendix M - Cancer PDF',
            size: '134 KB',
            url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI_Appendix_M.pdf',
          },
        ],
        icd9_v50: [
          {
            name: 'PDI Appendices',
            aria: 'View PDI Appendices PDF',
            size: '1.2 MB',
            url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_Appendices.pdf',
          },
        ],
        v45: [
          {
            name: 'PDI Appendices',
            aria: 'View PDI Appendices PDF',
            size: '1.5 MB',
            url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI Appendices.pdf',
          },
        ],
        v44: [
          {
            name: 'PDI Appendices',
            aria: 'View PDI Appendices PDF',
            size: '1.6 MB',
            url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI Appendices.pdf',
          },
        ],
        v43a: [
          {
            name: 'PDI Appendices',
            aria: 'View PDI Appendices PDF',
            size: '1.1 MB',
            url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI Appendices.pdf',
          },
        ],
        v43: [
          {
            name: 'PDI Appendices',
            aria: 'View PDI Appendices PDF',
            size: '929 KB',
            url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI Appendices.pdf',
          },
        ],
        v42: [
          {
            name: 'PDI Appendices',
            aria: 'View PDI Appendices PDF',
            size: '1 MB',
            url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI Appendices.pdf',
          },
        ],
        v41: [
          {
            name: 'PDI Appendices',
            aria: 'View PDI Appendices PDF',
            size: '3 MB',
            url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI Appendices.pdf',
          },
        ],
      },
    },
    retired: {
      indicator: 'Retired PDIs',
      tag: 'PDI-Retired',
      versions: {
        'NQI 01': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PDI/V2018/TechSpecs/NQI_01_Neonatal_Iatrogenic_Pneumothorax_Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '1.8 MB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PDI/V70/TechSpecs/NQI_01_Neonatal_Iatrogenic_Pneumothorax_Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '3.9 MB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/NQI_01_Neonatal_Iatrogenic_Pneumothorax_Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '1.6 MB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/NQI_01_Neonatal_Iatrogenic_Pneumothorax_Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '422 KB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/NQI 01 Neonatal Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '1.4 MB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/NQI_01_Neonatal_Iatrogenic_Pneumothorax_Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '350 KB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/NQI 01 Neonatal Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '619 KB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PDI/V44/TechSpecs/NQI 01 Neonatal Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '47 KB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PDI/V43a/TechSpecs/NQI 01 Neonatal Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '220 KB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PDI/V43/TechSpecs/NQI 01 Neonatal Iatrogenic Pneumothorax Rate.pdf',
              aria: 'View NQI 01 Neonatal Iatrogenic Pneumothorax Rates PDF',
              size: '178 KB',
              name: 'NQI 01 Neonatal Iatrogenic Pneumothorax Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PDI/V42/TechSpecs/NQI 01 Iatrogenic Pneumothorax in Neonates.pdf',
              aria: 'View NQI 01 Iatrogenic Pneumothorax in Neonates PDF',
              size: '121 KB',
              name: 'NQI 01 Iatrogenic Pneumothorax in Neonates',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PDI/V41/TechSpecs/NQI 01 Iatrogenic Pneumothorax in Neonates.pdf',
              aria: 'View NQI 01 Iatrogenic Pneumothorax in Neonates PDF',
              size: '159 KB',
              name: 'NQI 01 Iatrogenic Pneumothorax in Neonates',
            },
          ],
        },
        'PDI 02': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_02_Pressure_Ulcer_Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '532 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_02_Pressure_Ulcer_Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '497 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_02_Pressure_Ulcer_Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '923 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_02_Pressure_Ulcer_Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '436 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 02 Pressure Ulcer Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '316 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_02_Pressure_Ulcer_Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '249 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 02 Pressure Ulcer Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '378 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 02 Pressure Ulcer Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '270 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 02 Pressure Ulcer Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '175 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 02 Pressure Ulcer Rate.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '125 KB',
              name: 'PDI 02 Pressure Ulcer Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 02 Pressure Ulcer.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '62 KB',
              name: 'PDI 02 Pressure Ulcer',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 02 Pressure Ulcer.pdf',
              aria: 'View PDI 02 Pressure Ulcer Rate PDF',
              size: '65 KB',
              name: 'PDI 02 Pressure Ulcer',
            },
          ],
        },
        'PDI 03': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_03_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
              aria: 'View PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
              size: '561 KB',
              name: 'PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_03_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
              aria: 'View PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
              size: '437 KB',
              name: 'PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_03_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
              aria: 'View PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
              size: '259 KB',
              name: 'PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_03_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
              aria: 'View PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
              size: '296 KB',
              name: 'PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count.pdf',
              aria: 'View PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
              size: '523 KB',
              name: 'PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_03_Retained_Surgical_Item_or_Unretrieved_Device_Fragment_Count.pdf',
              aria: 'View PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
              size: '63 KB',
              name: 'PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count.pdf',
              aria: 'View PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count PDF',
              size: '169 KB',
              name: 'PDI 03 Retained Surgical Item or Unretrieved Device Fragment Count',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 03 Foreign Body Left During Procedure.pdf',
              aria: 'View PDI 03 Foreign Body Left During Procedure PDF',
              size: '149 KB',
              name: 'PDI 03 Foreign Body Left During Procedure',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 03 Foreign Body Left During Procedure REV.pdf',
              aria: 'View PDI 03 Foreign Body Left During Procedure PDF',
              size: '143 KB',
              name: 'PDI 03 Foreign Body Left During Procedure REV',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 03 Foreign Body Left During Procedure.pdf',
              aria: 'View PDI 03 Foreign Body Left During Procedure PDF',
              size: '21 KB',
              name: 'PDI 03 Foreign Body Left During Procedure',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 03 Foreign Body Left During Procedure.pdf',
              aria: 'View PDI 03 Foreign Body Left During Procedure PDF',
              size: '40 KB',
              name: 'PDI 03 Foreign Body Left During Procedure',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 03 Foreign Body Left During Procedure.pdf',
              aria: 'View PDI 03 Foreign Body Left During Procedure PDF',
              size: '101 KB',
              name: 'PDI 03 Foreign Body Left During Procedure',
            },
          ],
        },
        'PDI 11': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_11_Postoperative_Wound_Dehiscence_Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '8.6 MB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_11_Postoperative_Wound_Dehiscence_Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '3.2 MB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_11_Postoperative_Wound_Dehiscence_Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '5.2 MB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_11_Postoperative_Wound_Dehiscence_Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '596 KB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 11 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '850 KB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_11_Postoperative_Wound_Dehiscence_Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '532 KB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 11 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '1.8 MB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 11 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '632 KB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 11 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '332 KB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 11 Postoperative Wound Dehiscence Rate.pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence Rate PDF',
              size: '399 KB',
              name: 'PDI 11 Postoperative Wound Dehiscence Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 11 Postoperative Wound Dehiscence .pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence PDF',
              size: '281 KB',
              name: 'PDI 11 Postoperative Wound Dehiscence ',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 11 Postoperative Wound Dehiscence .pdf',
              aria: 'View PDI 11 Postoperative Wound Dehiscence PDF',
              size: '353 KB',
              name: 'PDI 11 Postoperative Wound Dehiscence ',
            },
          ],
        },
        'PDI 13': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_13_Transfusion_Reaction_Count.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '519 KB',
              name: 'PDI 13 Transfusion Reaction Count',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_13_Transfusion_Reaction_Count.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '411 KB',
              name: 'PDI 13 Transfusion Reaction Count',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_13_Transfusion_Reaction_Count.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '238 KB',
              name: 'PDI 13 Transfusion Reaction Count',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_13_Transfusion_Reaction_Count.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '297 KB',
              name: 'PDI 13 Transfusion Reaction Count',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 13 Transfusion Reaction Count.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '116 KB',
              name: 'PDI 13 Transfusion Reaction Count',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_13_Transfusion_Reaction_Count.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '82 KB',
              name: 'PDI 13 Transfusion Reaction Count',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 13 Transfusion Reaction Count.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '192 KB',
              name: 'PDI 13 Transfusion Reaction Count',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 13 Transfusion Reaction Volume.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '164 KB',
              name: 'PDI 13 Transfusion Reaction Volume',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 13 Transfusion Reaction Volume.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '135 KB',
              name: 'PDI 13 Transfusion Reaction Volume',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 13 Transfusion Reaction Volume.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '21 KB',
              name: 'PDI 13 Transfusion Reaction Volume',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 13 Transfusion Reaction.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '37 KB',
              name: 'PDI 13 Transfusion Reaction',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 13 Transfusion Reaction.pdf',
              aria: 'View PDI 13 Transfusion Reaction Count PDF',
              size: '49 KB',
              name: 'PDI 13 Transfusion Reaction',
            },
          ],
        },
        'PDI 17': {
          previous: [
            {
              version: 'icd10_v2018',
              url: '/Downloads/Modules/PDI/V2018/TechSpecs/PDI_17_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '602 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PDI/V70/TechSpecs/PDI_17_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '412 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/PDI_17_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '408 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_17_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '378 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/PDI 17 Perforated Appendix Admission Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '941 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_17_Perforated_Appendix_Admission_Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '70 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 17 Perforated Appendix Admission Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '184 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 17 Perforated Appendix Admission Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '159 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 17 Perforated Appendix Admission Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '130 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 17 Perforated Appendix Admission Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '80 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 17 Perforated Appendix Admission Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '40 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 17 Perforated Appendix Admission Rate.pdf',
              aria: 'View PDI 17 Perforated Appendix Admission Rate PDF',
              size: '50 KB',
              name: 'PDI 17 Perforated Appendix Admission Rate',
            },
          ],
        },
        'NQI 02': {
          previous: [
            {
              version: 'icd10_v70',
              url: '/Downloads/Modules/PDI/V70/TechSpecs/NQI_02_Neonatal_Mortality_Rate.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '419 KB',
              name: 'NQI 02 Neonatal Mortality Rate',
            },
            {
              version: 'icd10_v60',
              url: '/Downloads/Modules/PDI/V60-ICD10/TechSpecs/NQI_02_Neonatal_Mortality_Rate.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '512 KB',
              name: 'NQI 02 Neonatal Mortality Rate',
            },
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/NQI_02_Neonatal_Mortality_Rate.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '387 KB',
              name: 'NQI 02 Neonatal Mortality Rate',
            },
            {
              version: 'icd10_v50',
              url: '/Downloads/Modules/PDI/V50-ICD10/TechSpecs/NQI 02 Neonatal Mortality Rate.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '548 KB',
              name: 'NQI 02 Neonatal Mortality Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/NQI_02_Neonatal_Mortality_Rate.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '114 KB',
              name: 'NQI 02 Neonatal Mortality Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/NQI 02 Neonatal Mortality Rate.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '187 KB',
              name: 'NQI 02 Neonatal Mortality Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PDI/V44/TechSpecs/NQI 02 Neonatal Mortality Rate.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '28 KB',
              name: 'NQI 02 Neonatal Mortality Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PDI/V43a/TechSpecs/NQI 02 Neonatal Mortality Rate.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '127 KB',
              name: 'NQI 02 Neonatal Mortality Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PDI/V43/TechSpecs/NQI 02 Neonatal Mortality Rate.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '66 KB',
              name: 'NQI 02 Neonatal Mortality Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PDI/V42/TechSpecs/NQI 02 Neonatal Mortality.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '38 KB',
              name: 'NQI 02 Neonatal Mortality',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PDI/V41/TechSpecs/NQI 02 Neonatal Mortality.pdf',
              aria: 'View NQI 02 Neonatal Mortality Rate PDF',
              size: '99 KB',
              name: 'NQI 02 Neonatal Mortality',
            },
          ],
        },
        'PDI 06': {
          previous: [
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_06_Pediatric_Heart_Surgery_Mortality_Rate.pdf',
              aria: 'View PDI 06 Pediatric Heart Surgery Mortality Rate PDF',
              size: '353 KB',
              name: 'PDI 06 Pediatric Heart Surgery Mortality Rate',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_06_RACHS-1_Pediatric_Heart_Surgery_Mortality_Rate.pdf',
              aria: 'View PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate PDF',
              size: '107 KB',
              name: 'PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate.pdf',
              aria: 'View PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate PDF',
              size: '372 KB',
              name: 'PDI 06 RACHS-1 Pediatric Heart Surgery Mortality Rate',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 06 Pediatric Heart Surgery Mortality Rate.pdf',
              aria: 'View PDI 06 Pediatric Heart Surgery Mortality Rate PDF',
              size: '281 KB',
              name: 'PDI 06 Pediatric Heart Surgery Mortality Rate',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 06 Pediatric Heart Surgery Mortality Rate.pdf',
              aria: 'View PDI 06 Pediatric Heart Surgery Mortality Rate PDF',
              size: '178 KB',
              name: 'PDI 06 Pediatric Heart Surgery Mortality Rate',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 06 Pediatric Heart Surgery Mortality Rate.pdf',
              aria: 'View PDI 06 Pediatric Heart Surgery Mortality Rate PDF',
              size: '163 KB',
              name: 'PDI 06 Pediatric Heart Surgery Mortality Rate',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 06 Pediatric Heart Surgery Mortality.pdf',
              aria: 'View PDI 06 Pediatric Heart Surgery Mortality Rate PDF',
              size: '76 KB',
              name: 'PDI 06 Pediatric Heart Surgery Mortality',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 06 Pediatric Heart Surgery Mortality.pdf',
              aria: 'View PDI 06 Pediatric Heart Surgery Mortality Rate PDF',
              size: '183 KB',
              name: 'PDI 06 Pediatric Heart Surgery Mortality',
            },
          ],
        },
        'PDI 07': {
          previous: [
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_07_Pediatric_Heart_Surgery_Volume.pdf',
              aria: 'View PDI 07 Pediatric Heart Surgery Volume PDF',
              size: '341 KB',
              name: 'PDI 07 Pediatric Heart Surgery Volume',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_07_RACHS-1_Pediatric_Heart_Surgery_Volume.pdf',
              aria: 'View PDI 07 Pediatric Heart Surgery Volume PDF',
              size: '92 KB',
              name: 'PDI 07 RACHS-1 Pediatric Heart Surgery Volume',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 07 RACHS-1 Pediatric Heart Surgery Volume.pdf',
              aria: 'View PDI 07 Pediatric Heart Surgery Volume PDF',
              size: '248 KB',
              name: 'PDI 07 RACHS-1 Pediatric Heart Surgery Volume',
            },
            {
              version: 'v44',
              url: '/Downloads/Modules/PDI/V44/TechSpecs/PDI 07 Pediatric Heart Surgery Volume.pdf',
              aria: 'View PDI 07 Pediatric Heart Surgery Volume PDF',
              size: '243 KB',
              name: 'PDI 07 Pediatric Heart Surgery Volume',
            },
            {
              version: 'v43a',
              url: '/Downloads/Modules/PDI/V43a/TechSpecs/PDI 07 Pediatric Heart Surgery Volume.pdf',
              aria: 'View PDI 07 Pediatric Heart Surgery Volume PDF',
              size: '158 KB',
              name: 'PDI 07 Pediatric Heart Surgery Volume',
            },
            {
              version: 'v43',
              url: '/Downloads/Modules/PDI/V43/TechSpecs/PDI 07 Pediatric Heart Surgery Volume.pdf',
              aria: 'View PDI 07 Pediatric Heart Surgery Volume PDF',
              size: '111 KB',
              name: 'PDI 07 Pediatric Heart Surgery Volume',
            },
            {
              version: 'v42',
              url: '/Downloads/Modules/PDI/V42/TechSpecs/PDI 07 Pediatric Heart Surgery Volume.pdf',
              aria: 'View PDI 07 Pediatric Heart Surgery Volume PDF',
              size: '69 KB',
              name: 'PDI 07 Pediatric Heart Surgery Volume',
            },
            {
              version: 'v41',
              url: '/Downloads/Modules/PDI/V41/TechSpecs/PDI 07 Pediatric Heart Surgery Volume.pdf',
              aria: 'View PDI 07 Pediatric Heart Surgery Volume PDF',
              size: '41 KB',
              name: 'PDI 07 Pediatric Heart Surgery Volume',
            },
          ],
        },
        'PDI 19': {
          previous: [
            {
              version: 'icd9_v60',
              url: '/Downloads/Modules/PDI/V60/TechSpecs/PDI_19_Pediatric_Safety_for_Selected_Indicators.pdf',
              aria: 'View PDI 19 Pediatric Safety for Selected Indicators PDF',
              size: '373 KB',
              name: 'PDI 19 Pediatric Safety for Selected Indicators',
            },
            {
              version: 'icd9_v50',
              url: '/Downloads/Modules/PDI/V50/TechSpecs/PDI_19_Pediatric_Safety_for_Selected_Indicators.pdf',
              aria: 'View PDI 19 Pediatric Safety for Selected Indicators PDF',
              size: '53 KB',
              name: 'PDI 19 Pediatric Safety for Selected Indicators',
            },
            {
              version: 'v45',
              url: '/Downloads/Modules/PDI/V45/TechSpecs/PDI 19 Pediatric Safety for Selected Indicators.pdf',
              aria: 'View PDI 19 Pediatric Safety for Selected Indicators PDF',
              size: '153 KB',
              name: 'PDI 19 Pediatric Safety for Selected Indicators',
            },
          ],
        },
      },
    },
  },
  pqe: {
    topic: 'PQE - Prevention Quality Indicator in Emergency Department Settings',
    tag: 'ED-PQI-Indicators',
    indicators: [
      {
        indicator: 'PQE 01 Visits for Non-Traumatic Dental Conditions in ED',
        tag: 'ED-PQI-01',
        type: 'Area Level',
        desc: 'Emergency Department (ED) visits for non-traumatic dental conditions per 100,000 population, for individuals ages 5 years and older.',
        versions: {
          icd10_v2024: {
            name: 'PQE 01 Visits for Non-Traumatic Dental Conditions in ED',
            aria: 'View PQE 01 ED Visits for Non-Traumatic Dental Conditions in ED PDF',
            size: '271 KB',
            url: '/Downloads/Modules/PQE/V2024/TechSpecs/PQE_01_Visits_for_Dental_Conditions.pdf',
          },
          icd10_v2023: {
            name: 'PQE 01 ED Visits for Non-Traumatic Dental Conditions',
            aria: 'View PQE 01 ED Visits for Non-Traumatic Dental Conditions PDF',
            size: '243 KB',
            url: '/Downloads/Modules/ED_PQI/V2023/TechSpecs/PQE_01_Visits_for_Dental_Conditions.pdf',
          },
        },
      },
      {
        indicator: 'PQE 02 Visits for Chronic Ambulatory Care Sensitive Conditions in ED',
        tag: 'ED-PQE-02',
        type: 'Area Level',
        desc: 'Emergency Department (ED) visits for chronic ambulatory care sensitive conditions, for individuals ages 40 years and older, per 100,000 population. Includes asthma, chronic obstructive pulmonary disease (COPD), heart failure, acute diabetic hyper- and hypoglycemic complications, and chronic kidney disease. A first-listed diagnosis of lower respiratory infection with a secondary diagnosis of COPD or asthma is also included.',
        versions: {
          icd10_v2024: {
            name: 'PQE 02 Visits for Chronic Ambulatory Care Sensitive Conditions in ED',
            aria: 'View PQE 02 Visits for Chronic Ambulatory Care Sensitive Conditions in ED PDF',
            size: '200 KB',
            url: '/Downloads/Modules/PQE/V2024/TechSpecs/PQE_02_Visits_for_Chronic_Conditions.pdf',
          },
          icd10_v2023: {
            name: 'PQE 02 ED Visits for Chronic Ambulatory Care Sensitive Conditions',
            aria: 'View PQE 02 ED Visits for Chronic Ambulatory Care Sensitive Conditions PDF',
            size: '155 KB',
            url: '/Downloads/Modules/ED_PQI/V2023/TechSpecs/PQE_02_Visits_for_Chronic_Conditions.pdf',
          },
        },
      },
      {
        indicator: 'PQE 03 Visits for Acute Ambulatory Care Sensitive Conditions in ED',
        tag: 'ED-PQI-03',
        type: 'Area Level',
        desc: 'Emergency Department (ED) visits for selected acute ambulatory care sensitive conditions (ACSC) without inpatient admission, for individuals ages 3 months through 64 years, per 100,000 population.',
        versions: {
          icd10_v2024: {
            name: 'PQE 03 Visits for Acute Ambulatory Care Sensitive Conditions in ED',
            aria: 'View PQE 03 Visits for Acute Ambulatory Care Sensitive Conditions in ED PDF',
            size: '333 KB',
            url: '/Downloads/Modules/PQE/V2024/TechSpecs/PQE_03_Visits_for_Acute_Conditions.pdf',
          },
          icd10_v2023: {
            name: 'PQE 03 ED Visits for Acute Ambulatory Care Sensitive Conditions',
            aria: 'View PQE 03 ED Visits for Acute Ambulatory Care Sensitive Conditions PDF',
            size: '338 KB',
            url: '/Downloads/Modules/ED_PQI/V2023/TechSpecs/PQE_03_Visits_for_Acute_Conditions.pdf',
          },
        },
      },
      {
        indicator: 'PQE 04 Visits for Asthma in ED',
        tag: 'ED-PQI-04',
        type: 'Area Level',
        desc: 'Emergency Department (ED) visits for asthma, for individuals ages 5 to 39 years, per 100,000 population.',
        versions: {
          icd10_v2024: {
            name: 'PQE 04 Visits for Asthma in ED',
            aria: 'View PQE 04 Visits for Asthma in ED',
            size: '186 KB',
            url: '/Downloads/Modules/PQE/V2024/TechSpecs/PQE_04_Visits_for_Asthma.pdf',
          },
          icd10_v2023: {
            name: 'PQE 04 ED Visits for Asthma',
            aria: 'View PQE 04 ED Visits for Asthma PDF',
            size: '113 KB',
            url: '/Downloads/Modules/ED_PQI/V2023/TechSpecs/PQE_04_Visits_for_Asthma.pdf',
          },
        },
      },
      {
        indicator: 'PQE 05 Visits for Back Pain in ED',
        tag: 'ED-PQI-05',
        type: 'Area Level',
        desc: 'Patients with two or more Emergency Department (ED) visits for back pain in one calendar year, for individuals ages 18 and older, per 100,000 population.',
        versions: {
          icd10_v2024: {
            name: 'PQE 05 Visits for Back Pain in ED',
            aria: 'View PQE 05 Visits for Back Pain in ED',
            size: '264 KB',
            url: '/Downloads/Modules/PQE/V2024/TechSpecs/PQE_05_Visits_for_BackPain.pdf',
          },
          icd10_v2023: {
            name: 'PQE 05 ED Visits for Back Pain',
            aria: 'View PQE 05 ED Visits for Back Pain PDF',
            size: '246 KB',
            url: '/Downloads/Modules/ED_PQI/V2023/TechSpecs/PQE_05_Visits_for_BackPain.pdf',
          },
        },
      },
    ],
    appendices: {
      indicator: 'Appendices',
      tag: 'ED-PQI-Appendices',
      versions: {
        icd10_v2024: [
          {
            name: 'PQE Appendix A - Cancer Diagnosis Codes',
            aria: 'View Appendix A - Cancer Diagnosis Codes PDF',
            size: '278 KB',
            url: '/Downloads/Modules/PQE/V2024/TechSpecs/PQE_Appendix_A.pdf',
          },
          {
            name: 'PQE Appendix B - Trauma Diagnosis Codes',
            aria: 'View Appendix B - Trauma Diagnosis Codes PDF',
            size: '1.73 MB',
            url: '/Downloads/Modules/PQE/V2024/TechSpecs/PQE_Appendix_B.pdf',
          },
        ],
        icd10_v2023: [
          {
            name: 'PQE Appendix A - Cancer Diagnosis Codes',
            aria: 'View Appendix A - Cancer Diagnosis Codes PDF',
            size: '218 KB',
            url: '/Downloads/Modules/ED_PQI/V2023/TechSpecs/ED_PQI_Appendix_A.pdf',
          },
          {
            name: 'PQE Appendix B - Trauma Diagnosis Codes',
            aria: 'View Appendix B - Trauma Diagnosis Codes PDF',
            size: '1.86 MB',
            url: '/Downloads/Modules/ED_PQI/V2023/TechSpecs/ED_PQI_Appendix_B.pdf',
          },
        ],
      },
    },
    // retired: {
    //   indicator: 'Retired ED-PQIs',
    //   tag: 'ED-PQI-Retired',
    //   versions: {},
    // },
  },
};
