import React, { useEffect } from 'react'
import AnnouncementMain from '../../components/Announcements/AnnouncementMain';

const News = () => {
    useEffect(() => {
        document.title = `AHRQ - Quality Indicators Announcements`;
    });
    return (<AnnouncementMain />)
}

export default News;