import React from 'react';
import { Tile, TileGroup } from '../../../ui';

export const QITileGroup = ({ isTechSpecs, includePQE, showPsiCloudQi }) => {
  return (
    <TileGroup type='measures' columns={{ xs: 2, md: 4, lg: 6, xl: 6, xxl: 6 }} gap='sm' py={3}>
      <Tile
        title='PQI'
        subtitle='Prevention Quality Indicators in Inpatient Settings'
        description='The Prevention Quality Indicators in Inpatient Settings (PQIs) identify issues of access to outpatient care, including appropriate follow-up care after hospital discharge.'
        to={isTechSpecs ? '/measures/PQI_TechSpec' : '/measures/pqi_resources'}
        toLabel={isTechSpecs ? 'View PQI Technical Specifications' : 'View PQI Measures'}
      />
      <Tile
        title='IQI'
        subtitle='Inpatient Quality Indicators'
        description='The Inpatient Quality Indicators (IQIs) provide a perspective on quality of care inside hospitals.'
        to={isTechSpecs ? '/measures/IQI_TechSpec' : '/measures/iqi_resources'}
        toLabel={isTechSpecs ? 'View IQI Technical Specifications' : 'View IQI Measures'}
      />
      <Tile
        title='PSI'
        subtitle='Patient Safety Indicators'
        description='The Patient Safety Indicators (PSIs) provide information on potentially avoidable safety events that represent opportunities for improvement in the delivery of care.'
        to={isTechSpecs ? '/measures/PSI_TechSpec' : '/measures/psi_resources'}
        toLabel={isTechSpecs ? 'View PSI Technical Specifications' : 'View PSI Measures'}
        showPsiCloudQi={showPsiCloudQi}
      />
      <Tile
        title='PDI'
        subtitle='Pediatric Quality Indicators'
        description='The Pediatric Quality Indicators (PDIs) focus on potentially preventable complications and iatrogenic events for pediatric patients treated in hospitals and on preventable hospitalizations among pediatric patients.'
        to={isTechSpecs ? '/measures/PDI_TechSpec' : '/measures/pdi_resources'}
        toLabel={isTechSpecs ? 'View PDI Technical Specifications' : 'View PDI Measures'}
      />
      {
        includePQE && 
        <Tile
          title='PQE'
          subtitle='Prevention Quality Indicators in Emergency Department Settings'
          description='The Prevention Quality Indicators in Emergency Department Settings (PQE) identify issues of access to outpatient care, including appropriate follow-up care after hospital discharge.'
          to={isTechSpecs ? '/measures/pqe_TechSpec' : '/measures/pqe_resources'}
          toLabel={isTechSpecs ? 'View PQE Technical Specifications' : 'View PQE Measures'}
          mdOffset={1}
          lgOffset={0}
        />
      }
    </TileGroup>
  );
};
