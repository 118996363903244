import React,{useRef, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {QITileCloudQI,  VideoModal} from '../';
import CloudQIScreenshot from '../../img/CloudQIScreenshot.png';
import {Button, Flex, LinkBox, LinkItem, PageDisclaimer, PageRow, Rule} from '../../ui';
import {getReleaseItem} from '../../data/releases';
import { cloudqiPQEVideos as PQEVedios } from '../../data/videos';
import PeopleSayCloudQI from './PeopleSayCloudQI';

export const CloudQI = () => {
  const cloudQiRelease = getReleaseItem('CloudQi', 'CLOUDQI');
  const infoSheet = getReleaseItem('PsiInfoSheet', 'CLOUDQI');
  const releaseFaq = getReleaseItem('Icd10Faq');
  const releaseNotes = getReleaseItem('CloudQIReleaseNotes', 'CLOUDQI');
  const softwareInstructions = getReleaseItem('CloudQiSoftwareInstructions', 'CLOUDQI');
  const scrollToVideosRef = useRef(null);
  const [pqeVideoModalInfo, setPQEVideoModalInfo] = useState({ isOpen: false, url: '' });

  useEffect(() => {
    if (window.location.hash === '#PQE-videos') {
      setTimeout(() => {
        scrollToVideosRef.current.scrollIntoView();
      });
    }
  }, []);

  return (
    <>
      <PageRow className='cloud-qi-intro-row'>
        <Flex cols='lg' gap={{xs: 4, lg: 10}}>
          <Flex alignItems='flex-start' gap={3} marginBottom={{xs: 4, lg: 0}}>
            <div>
              <h1>
                CloudQI
              </h1>
              <span className='sub-tag subtitleBold'>
                For use on your local desktop or a server hosted on your private/public cloud
              </span>
              <p className='text-xl'>
                Multi-version, multi-user support, remote access, and ease-of-use for users preferring to run PSI and PQE using GUI
              </p>
              <p>
                AHRQ's latest technology platform, CloudQI, is positioned as the future platform of AHRQ QI Windows software tools. For v2024, CloudQI PSI Beta and ED PQI Beta are now integrated as a unified product called v2024 CloudQI. This integrated software, includes both the PSI module and what is now referred to as the Prevention Quality Indicators in Emergency Department Settings (PQE) module. Additional AHRQ QI modules, such as IQI, PQI, and PDI will be integrated in phases.
              </p>
              <Button href={cloudQiRelease.url} aria-label={cloudQiRelease.aria} arrow>
                {cloudQiRelease.cta}
              </Button>
            </div>

            <Rule spacing={1} />

              <img className='mx-auto' src={CloudQIScreenshot} alt='CloudQI Screenshot' style={{ width: '100%' }} />
          </Flex>
          <Box bgcolor='grey.50' p={2.5} width={{lg: 320}} margin={{xs: '-1.25rem', lg: 0}}>
            <div>
              <h6>Software v2024 Improvements</h6>
              <ul>
                <li>Integrates both the PSI and PQE modules</li>
                <li>Allows single user mode on a local desktop or multi-user mode on private/public cloud</li>
                <li>Provides remote access to the application via a browser</li>
                <li>Capability to run multiple versions of the QIs</li>
                <li>Access to previously uploaded files and reports</li>
                <li>Collects telemetry information for software improvement purposes, with an option to opt-out</li>
                <li>Allows users to submit feedback directly within the application</li>
              </ul>
            </div>
            <div>
              <h6>Release Notes</h6>
              <ul>
                <li>
                  v2024, July 2024 - CloudQI PSI
                </li>
              </ul>
              <LinkBox>
                <LinkItem href={releaseNotes.url} meta={releaseNotes.info} leftBorder light noicon noclip>
                  {releaseNotes.version} Release Notes
                </LinkItem>
                <LinkItem href={infoSheet.url} meta={infoSheet.info} leftBorder light noicon noclip>
                  {infoSheet.version} Information Sheet
                </LinkItem>
                <LinkItem
                  href={releaseFaq.url}
                  meta={releaseFaq.info}
                  leftBorder
                  light
                  noicon
                  noclip>
                  {releaseFaq.version} Software Release FAQ
                </LinkItem>
              </LinkBox>
            </div>
          </Box>
        </Flex>

        <Rule spacing={5} sx={{ display: { xs: 'none', lg: 'block' } }} />
        
        <h2>Installation & Getting Started</h2>
        <Flex gap={3}>
          <div>
            <p>
              To learn more about CloudQI PSI installation and
              get started, download the software instructions below (See Appendix F).
            </p>
            <LinkItem
              href={softwareInstructions.url}
              meta={`${softwareInstructions.revision} ${softwareInstructions.info}`}
              light>
              {softwareInstructions.title}
            </LinkItem>
          </div>
        </Flex>
      </PageRow>

      <PageRow className='getting-started-row' lightGrey>
        <h2>Videos about PQE</h2>
        <Flex gap={3}>
          <div>
            <p>
              ED PQI is now PQE. The videos below reference version 2023, but remain relevant for version 2024.
            </p>
          </div>
          <Flex
            gap={3}
            pb={3}
            flexDirection={'row'}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {Object.keys(PQEVedios).map((pqeVideoKey) => {
              const video = PQEVedios[pqeVideoKey];
              return (
                <Flex
                  key={video.id}
                  flex='unset'
                  alignItems='center'
                  alignSelf='flex-start'
                  gap={3}
                  width={{ xs: '45%', md: '30%' }}
                >
                  <button
                    onClick={() => setPQEVideoModalInfo({ isOpen: true, url: video.url })}
                    className='video-thumbnail'
                  >
                    {video.thumb !== undefined && (
                      <img alt='Video Thumbnail' src={video.thumb} width='100%' />
                    )}
                  </button>
                  <p className='text-center'>
                    <a aria-label={video.label} target='_blank' rel='noreferrer' href={video.url}>
                      {video.text}
                    </a>
                    <a href='/ExternalDisclaimer'>
                      <img
                        alt='external web link policy'
                        className='ml-1'
                        src='/images/exit_disclaimer.png'
                      />
                    </a>
                  </p>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </PageRow>

      <PageRow babyBlue>
        <PeopleSayCloudQI />
      </PageRow>

      <PageRow className='tech-specs-row'>
        <Box px={{lg: 16}} textAlign='center'>
          <h2>Technical Specifications</h2>
          <p> 
            Technical Specifications break down calculations used to formulate each indicator, including a brief description of the measure, numerator and denominator information, and details on cases that should be excluded from calculations. CloudQI includes PSI and PQE modules.
          </p>
        </Box>
        <QITileCloudQI isTechSpecs />
        <div className='align-center'>
          <Button
            aria-label='View all measures'
            label='View All Measures'
            href='/measures/all_measures'
            variant='outlined'
            arrow
          />
        </div>
      </PageRow>

      <PageRow className='disclaimer-row' lightGrey>
        <PageDisclaimer
          resource={`AHRQ CloudQI v2024 Software Download for AHRQ Quality Indicators`}
          software
        />
      </PageRow>
      <VideoModal
        isOpen={pqeVideoModalInfo?.isOpen ?? false}
        onClose={() => setPQEVideoModalInfo(null)}
        url={pqeVideoModalInfo?.url}
      />
    </>
  );
};
