import React from 'react';
import { Container } from 'react-bootstrap';
import { TileGroup, Tile } from '../../../ui';
import styles from '../home.module.scss';

export const GetToKnowAHRQ = () => {
  return (
    <div className={styles.GetToKnowAHRQ}>
      <Container className={styles.GetToKnowAHRQContainer}>
        <TileGroup type='hero' columns={{ xs: 2, md: 4, lg: 6, xl: 10 }} gap='xs'>
          <Tile
            title='PQI'
            description='Prevention Quality Indicators in Inpatient Settings'
            to='/measures/pqi_resources'
            tag={['Prevention']}
          />
          <Tile
            title='IQI'
            description='Inpatient Quality Indicators'
            to='/measures/iqi_resources'
            tag={['Mortality', 'Utilization']}
          />
          <Tile
            title='PSI'
            description='Patient Safety Indicators'
            to='/measures/psi_resources'
            tag={['Complications', ' Adverse events']}
          />
          <Tile
            title='PDI'
            description='Pediatric Quality Indicators'
            to='/measures/pdi_resources'
            tag={['Complications', 'Prevention', ' Adverse events']}
            lgOffset={1}
            xlOffset={0}
          />
          <Tile
            title='PQE'
            description='Prevention Quality Indicators in Emergency Department Settings'
            subDescription='(ED PQI is now PQE)'
            to='/measures/pqe_resources'
            tag={['Prevention']}
            mdOffset={1}
            lgOffset={0}
            xlOffset={0}
          />
        </TileGroup>
      </Container>
    </div>
  );
};
