import React from 'react';
import { modulesData } from '../../../data/modules';
import { LinkItem, LinkTable } from '../../../ui';

const TechSpecTable = (props) => {
  const { module, version } = props;

  const getData = () => {
    const indicatorsToUse = modulesData[module]['indicators']
      .map((indicator) => indicator['versions'][version])
      .filter((indicator) => !!indicator);
    const retiredIndicatorsToUse = modulesData[module]['retired']
      ? Object.keys(modulesData[module]['retired']['versions'])
          .map((retiredKey) => {
            const previouses = modulesData[module]['retired']['versions'][retiredKey]['previous'];
            for (const previous of previouses) {
              if (previous['version'] === version) {
                return previous;
              }
            }
            return null;
          })
          .filter((indicator) => !!indicator)
      : [];
    const itemsToUse = [...indicatorsToUse, ...retiredIndicatorsToUse].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    return itemsToUse;
  };
  const dataToUse = getData();

  return (
    <LinkTable className='tech-spec-table' key={`table-${module}-${version}`}>
      <tbody>
        {dataToUse.map((indicator) => (
          <tr key={indicator.name}>
            <td>
              <LinkItem
                href={indicator.url}
                meta={`PDF File, ${indicator.size}`}
                aria-label={indicator.aria}>
                {indicator.name}
              </LinkItem>
            </td>
          </tr>
        ))}
        {modulesData[module]['appendices'] &&
          modulesData[module]['appendices']['versions'][version]?.map((appendix, index) => {
            return (
              <tr key={`appendix-${index}`}>
                <td>
                  <LinkItem
                    href={appendix.url}
                    meta={`PDF File, ${appendix.size}`}
                    aria-label={appendix.aria}>
                    {appendix.name}
                  </LinkItem>
                </td>
              </tr>
            );
          })}
      </tbody>
    </LinkTable>
  );
};

export default TechSpecTable;
