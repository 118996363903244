const PQE_ITEMS = [
  {
    name: 'TechSpecs',
    title: 'Prevention Quality Indicators in Emergency Department Settings (PQE) Technical Specifications',
    filename: 'PQE_2024_ICD10_techspecs_pdf.zip',
    path: '/Downloads/Modules/PQE/V2024/TechSpecs/',
    size: '2.92 MB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    revision: 'July 2024',
    dateModified: '2024-07-26',
  },
  {
    name: 'TechSpecsExcel',
    title:
      'Prevention Quality Indicators in Emergency Department Settings (PQE) Technical Specifications (Excel Format)',
    filename: 'PQE_2024_ICD10_techspecs_excel.zip',
    path: '/Downloads/Modules/PQE/V2024/TechSpecs/',
    size: '495 KB',
    format: 'ZIP',
    ctaLabel: 'Download All',
    revision: 'July 2024',
    dateModified: '2024-07-26',
  },
  {
    name: 'ParameterEstimates',
    title: 'Parameter Estimates for v2024',
    desc: 'Tables of Prevention Quality Indicators in Emergency Department Settings (PQE) covariates and coefficients for risk adjustment logistic regression models. ',
    filename: 'Parameter_Estimates_PQE_v2024.pdf',
    path: '/Downloads/Modules/PQE/V2024/',
    size: '506 KB',
    format: 'PDF',
    revision: 'July 2024',
    dateModified: '2024-07-26',
  },
  {
    name: 'BenchmarkDataTables',
    title: 'Benchmark Data Tables for v2024 ICD-10-CM/PCS',
    desc: 'Tables of nationwide comparative rates for each Prevention Quality Indicators in Emergency Department Settings (PQE) including observed rate, numerator, and denominator data for each indicator overall and stratified by sex and age group.',
    filename: 'Version_2024_Benchmark_Tables_PQE.pdf',
    path: '/Downloads/Modules/PQE/V2024/',
    size: '473 KB',
    format: 'PDF',
    revision: 'July 2024',
    dateModified: '2024-07-26',
  },
  {
    name: 'LogOfUpdatesRevisions',
    title: 'Log of Updates and Revisions for v2024',
    desc: 'This document contains tables summarizing revisions made to the PQE software, software documentation and technical specifications since the original release of these documents in September 2023.',
    filename: 'ChangeLog_PQE_v2024.pdf',
    path: '/Downloads/Modules/PQE/V2024/',
    size: '265 KB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'CodingRevisionsExcel',
    title: 'All ICD-10-CM/PCS coding revisions in MS Excel format',
    desc: 'This file is intended to help users identify which code sets are present in the current version of the QI software. The file also shows which code sets have been removed from the current version, which have remained but have either dropped or added new codes, and which individual codes have changed mapping in the current version.',
    filename: 'AHRQ_PQE_v2024_Code_Set_Changes.xlsx',
    path: '/Downloads/Modules/PDI/V2024/',
    size: '966 KB',
    format: 'EXCEL',
    dateModified: '2024-07-26',
  },
  // LINKS
  {
    name: 'IndividualMeasureTechnicalSpecifications',
    title: 'Individual Measure Technical Specifications (v2024 coding)',
    desc: 'Breaks down calculations used to formulate each Prevention Quality Indicators in Emergency Department Settings (PQE), including a brief description of the measures, numerator and denominator information, and details on cases that should be excluded from calculations.',
    url: '/measures/PQE_TechSpec',
    aria: 'View PQE Technical Specifications',
    cta: 'Learn More',
  },
  {
    name: 'LogOfCodingUpdates',
    title: 'Log of Coding Updates and Revisions for v2024',
    desc: 'Documents summarizing revisions made to the PQE software, software documentation and technical specifications since the original release of these documents in September 2023.',
    url: '/measures/pqe_log_coding_updates/v2024',
    aria: 'View Log Coding Updates PQE v2024',
    cta: 'Learn More',
  },
];

export default PQE_ITEMS;
