import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Box, Chip, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import styles from '../Measures.module.scss';
import QIRetiredImg from '../../../img/qi-retired.jpg';
import QIEmpiricalImg from '../../../img/qi-empirical.jpg';
import QINQFImg from '../../../img/qi-nqf.jpg';
import { Button, PageDisclaimer, PageHeader, PageRow } from '../../../ui';
import { QITileGroup } from '../../Common';
import { getReleaseItem, releaseVersion } from '../../../data/releases';

const QIResources = () => {
  const empiricalFile = getReleaseItem('EmpiricalMethods');

  return (
    <div className={`${styles.MeasuresHome}`}>
      <PageHeader.Row purple bgSrc='/images/hex-purple.png'>
        <PageHeader.Title>Measures</PageHeader.Title>
        <PageHeader.Content>
          Quality Indicators (QIs) are standardized, evidence-based measures of health care quality
          that can be used with readily available hospital inpatient administrative data to measure
          and track clinical performance and outcomes.
        </PageHeader.Content>
      </PageHeader.Row>

      <PageRow className='explore-qi-row'>
        <h1 className='align-center mb-5'>Explore the Quality Indicators</h1>
        <QITileGroup includePQE />
      </PageRow>

      <TechnicalSpecificationsRow className='technical-specifications-row' lightGrey>
        <h1>Technical Specifications</h1>
        <Box className='text-xl' mt={2}>
          View the Technical Specifications ({releaseVersion} coding)
        </Box>
        <Box mt={1} width={{ xs: '100%', lg: '50%' }}>
          Technical Specifications break down calculations used to formulate each indicator,
          including a brief description of the measure, numerator and denominator information, and
          details on cases that should be excluded from calculations.
        </Box>
        <ul className='arrows mt-4'>
          <li>
            <Link to='/measures/PQI_TechSpec'>PQI Technical Specifications </Link>
          </li>
          <li>
            <Link to='/measures/IQI_TechSpec'>IQI Technical Specifications</Link>
          </li>
          <li>
            <Link to='/measures/PSI_TechSpec'>PSI Technical Specifications</Link>
          </li>
          <li>
            <Link to='/measures/PDI_TechSpec'>PDI Technical Specifications</Link>
          </li>
          <li>
            <Link role='button' to='/measures/PQE_TechSpec' aria-label='PQE Specification'>
              PQE Technical Specifications
            </Link>
          </li>
        </ul>
      </TechnicalSpecificationsRow>

      <PageRow className='qi-resources-row'>
        <h2 className='align-center'>Quality Indicator Resources</h2>
        <div className={styles.QIResourcesContainer}>
          <Card className={styles.CaseCard}>
            <Card.Img alt='Indicator Empirical' variant='top' src={QIEmpiricalImg} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>{empiricalFile.title}</div>
              </Card.Title>
              <p>{empiricalFile.desc}</p>
              <p>Last Updated: July 2024</p>
              <div className={styles.ResourcesCardButtonContainer}>
                <Button
                  aria-label={empiricalFile.aria}
                  label={empiricalFile.cta}
                  href={empiricalFile.url}
                />
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.CaseCard}>
            <Card.Img alt='National Quality Forum' variant='top' src={QINQFImg} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>National Quality Forum</div>
              </Card.Title>
              <p>
                The National Quality Forum (NQF) is an independent, voluntary, consensus-based
                member organization that endorses standardized quality measures. See which AHRQ QIs
                were previously endorsed by NQF.
              </p>
              <div className={styles.ResourcesCardButtonContainer}>
                <Button
                  aria-label='View list_ahrq_qi'
                  label='Learn More'
                  href='/measures/all_measures?tag=NQF'
                />
              </div>
            </Card.Body>
          </Card>
          <Card className={styles.CaseCard}>
            <Card.Img alt='Retired Measures' variant='top' src={QIRetiredImg} />
            <Card.Body className={styles.Body}>
              <Card.Title className={`bold ${styles.CaseTitle}`}>
                <div>Retired Measures</div>
              </Card.Title>
              <p>
                AHRQ announces the retirement of indicators in v2019. View the 21 indicators that
                were retired by downloading the document below.
              </p>
              <div className={styles.ResourcesCardButtonContainer}>
                <Button
                  aria-label='View all measures'
                  label='Learn More'
                  href='/measures/all_measures?tag=RM'
                />
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className='align-center'>
          <Button
            aria-label='View all measure resources'
            label='See All Measure Resources'
            href='/measures/all_measures'
            variant='outlined'
            arrow
          />
        </div>
      </PageRow>

      {/* AHRQ LIFE CYCLE */}
      <PageRow className='qi-life-cycle-row' lightBlue>
        <div>
          <h1 className='align-center mb-5'>The AHRQ QI Life Cycle</h1>
          <div className='align-center text-large'>
            The AHRQ QI measure development process involves four phases.
          </div>
        </div>

        <LifeCycleGroup
          columnSpacing={{ xs: 0, md: 0, xl: 8 }}
          rowSpacing={{ xs: 4, md: 0, xl: 0 }}
        >
          <LifeCyclePhase>
            <Chip label='1' color='secondary' />
            <Box className='title'>Candidate Indicator Development</Box>
            <Box className='steps' component='ul'>
              <li>Identify candidate indicators within areas of interest.</li>
              <li>Review literature.</li>
              <li>Finalize specifications.</li>
              <li>Summarize evidence.</li>
            </Box>
          </LifeCyclePhase>
          <LifeCyclePhase>
            <Chip label='2' color='secondary' />
            <Box className='title'>Implement New Indicator</Box>
            <Box className='steps' component='ul'>
              <li>Code the new indicator into the software.</li>
              <li>Test the new indicator.</li>
              <li>Develop user documentation.</li>
            </Box>
          </LifeCyclePhase>
          <LifeCyclePhase>
            <Chip label='3' color='secondary' />
            <Box className='title'>Maintain Existing Indicators</Box>
            <Box className='steps' component='ul'>
              <li>Review evidence on the use of the indicator.</li>
              <li>Update technical specifications.</li>
              <li>Periodic review by clinical panels.</li>
              <li>Consider new data and methodological advances.</li>
            </Box>
          </LifeCyclePhase>
          <LifeCyclePhase>
            <Chip label='4' color='secondary' />
            <Box className='title'>Retire Indicators</Box>
            <Box className='steps' component='ul'>
              <li>
                Review evidence on the use of the indicator and determine that retirement is needed.
              </li>
              <li>Remove coding from software and user documentation.</li>
            </Box>
          </LifeCyclePhase>
        </LifeCycleGroup>
      </PageRow>

      <PageRow className='page-row disclaimer-row' lightBlue py={0} pb={6}>
        <PageDisclaimer
          resource={`AHRQ QI Technical Documentation, Version ${releaseVersion}`}
          technical
        />
      </PageRow>
    </div>
  );
};

export default QIResources;

const TechnicalSpecificationsRow = styled(PageRow)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    backgroundImage: `url(/images/qi-technical-specifications.png)`,
    backgroundPosition: 'center right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
}));

const LifeCycleGroup = styled(Grid)(({ theme }) => ({
  paddingTop: '3rem',
  [theme.breakpoints.up('md')]: {
    paddingTop: `3rem`,
  },
}));
LifeCycleGroup.defaultProps = {
  container: true,
};

const LifeCyclePhase = styled(Grid)(({ theme }) => ({
  '.MuiChip-root': {
    aspectRatio: '1',
    borderRadius: '50%',
    fontFamily: '"Public Sans", sans-serif',
    fontSize: 33,
    fontWeight: 700,
    height: '2em',
    width: '2em',
  },
  '.title': {
    fontFamily: '"Public Sans", sans-serif',
    fontSize: 19,
    fontWeight: 700,
    lineHeight: 1.25,
    margin: '1.25rem 0',
  },
  '.steps': {
    '&, li': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    li: {
      borderTop: `2px solid ${theme.palette.divider}`,
      padding: '10px 0',

      '&:first-of-type': {
        borderTopWidth: 0,
      },
    },
  },

  [theme.breakpoints.down('md')]: {
    borderTop: `1px dashed rgba(27, 27, 27, 0.35)`,

    '&:first-of-type': {
      borderTopWidth: 0,
    },
  },
  [theme.breakpoints.between('md', 'xl')]: {
    '&:nth-of-type(1)': {
      borderBottom: `1px dashed rgba(27, 27, 27, 0.35)`,
      padding: `0 2rem 2rem 0`,
    },
    '&:nth-of-type(2)': {
      borderBottom: `1px dashed rgba(27, 27, 27, 0.35)`,
      borderLeft: `1px dashed rgba(27, 27, 27, 0.35)`,
      padding: `0 0 2rem 2rem`,
    },
    '&:nth-of-type(3)': {
      padding: `2rem 2rem 0 0`,
    },
    '&:nth-of-type(4)': {
      borderLeft: `1px dashed rgba(27, 27, 27, 0.35)`,
      padding: `2rem 0 0 2rem`,
    },
  },
  [theme.breakpoints.up('xl')]: {
    borderLeft: `1px dashed rgba(27, 27, 27, 0.35)`,
    '&:first-of-type': {
      borderLeftWidth: 0,
    },
  },
}));
LifeCyclePhase.defaultProps = {
  xs: 12,
  md: 6,
  xl: 3,
};
