const SAS_QI_ITEMS = [
  // DOWNLOADS/SOFTWARE/SAS
  {
    name: 'SasQi',
    title: 'SAS QI v2024',
    filename: 'SAS_V2024_ICD-10-CM-PCS_QI_Software.zip',
    path: '/Downloads/Software/SAS/V2024/',
    size: '62.1 MB',
    format: 'ZIP',
    ctaLabel: 'Download SAS QI v2024',
    dateModified: '2024-07-26',
  },
  {
    name: 'ReleaseNotes',
    title: 'v2024 Release Notes',
    filename: 'AHRQ_SASQI_v2024_Software_Rel_Notes.pdf',
    path: '/Downloads/Software/SAS/V2024/',
    size: '290 KB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'SasQiSoftwareInstructions',
    title: 'SAS QI Software Instructions',
    filename: 'Software_Inst_SASQI_v2024_July_2024.pdf',
    path: '/Downloads/Software/SAS/V2024/',
    size: '2.62 MB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'AutomateSasQiSoftware',
    title: 'Automate SAS QI Software Runs in Windows',
    filename: 'Automate_SAS_QI_Software_Runs_in_Windows.pdf',
    path: '/Downloads/Software/SAS/V2024/',
    size: '602 KB',
    format: 'PDF',
    dateModified: '2024-07-26',
  },
  {
    name: 'SasPopulationFiles',
    title: 'v2024 Population File',
    desc: 'Population estimates through 2023 for use with SAS QI v2024 (July 2024)',
    filename: '2000-2023_Population_Files_v2024.zip',
    path: '/Downloads/Software/SAS/V2024/',
    size: '17.4 MB',
    format: 'ZIP',
    dateModified: '2024-07-26',
  },
  {
    name: 'AhrqQiPopulationFileDocumentation',
    title: 'AHRQ QI 2000-2024 Population File Documentation',
    desc: 'This document describes how the population data estimates are derived from public use Census data for use with the QI SAS® v2024',
    filename: 'AHRQ_QI_v2024_ICD10_Population_File.pdf',
    path: '/Downloads/Software/SAS/V2024/',
    size: '218 KB',
    format: 'PDF',
    revision: 'July 2024',
    dateModified: '2024-07-26',
  },
  {
    name: 'InpatientQualityIndicators',
    title: 'Inpatient Quality Indicators',
    filename: 'IQI_SAS_V2024_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2024/',
    size: '2.16 MB',
    format: 'ZIP',
    dateModified: '2024-07-26',
  },
  {
    name: 'PatientSafetyIndicators',
    title: 'Patient Safety Indicators',
    filename: 'PSI_SAS_V2024_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2024/',
    size: '2.98 MB',
    format: 'ZIP',
    dateModified: '2024-07-26',
  },
  {
    name: 'PediatricQualityIndicator',
    title: 'Pediatric Quality Indicator',
    filename: 'PDI_SAS_V2024_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2024/',
    size: '20.0 MB',
    format: 'ZIP',
    dateModified: '2024-07-26',
  },
  {
    name: 'PreventionQualityIndicator',
    title: 'Prevention Quality Indicators in Inpatient Settings',
    filename: 'PQI_SAS_V2024_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2024/',
    size: '17.5 MB',
    format: 'ZIP',
    dateModified: '2024-07-26',
  },
  {
    name: 'Psi17',
    title: 'PSI 17',
    filename: 'PSI17_SAS_V2024_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2024/',
    size: '1.79 MB',
    format: 'ZIP',
    dateModified: '2024-07-26',
  },

  // PQE
  {
    name: 'SasPQE',
    title: 'Prevention Quality Indicators in Emergency Department Settings',
    filename: 'PQE_SAS_V2024_QI_SOFTWARE.zip',
    path: '/Downloads/Software/SAS/V2024/',
    size: '17.5 MB',
    format: 'ZIP',
    ctaLabel: 'Prevention Quality Indicators in Emergency Department Settings',
    dateModified: '2024-07-26',
  }
];

export default SAS_QI_ITEMS;
