import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TileGroup, Tile } from '../../../ui';

export const LatestNews = () => {
  return (
    <Container>
      <Typography variant='h3' align='center' mt={8}>
        Read the Latest News
      </Typography>
      <TileGroup type='news' py={3}>
        <Tile
          title='Release of AHRQ QI SAS QI, WinQI, and CloudQI v2024 software.'
          description='The software allows organizations to apply the AHRQ Quality Indicators (QIs) to their own data to assist quality improvement efforts in acute hospital settings.'
          label='Software'
          date='July 26, 2024'
          to='/news#software-release-2024-07-26'
          promote
        />
        <Tile
          title='Release of ED PQI Beta v2023.0.1 and CloudQI PSI Beta v2023.0.1.'
          description='This is a minor release of Windows ED PQI Beta and CloudQI PSI Beta applications. Details are in the Software Release Notes for Windows ED PQI Beta and CloudQI PSI Beta.'
          label='Software'
          date='December 21, 2023'
          to='/news#software-release-2023-12-21'
        />
        <Tile
          title='Beta release of a new module: The Emergency Department Prevention Quality Indicators (ED PQI) v2023 software.'
          description="The beta software allows organizations to apply the Emergency Department's (ED) AHRQ Quality Indicators (QIs) to their own treat-and-release cases from the ED and inpatient discharges admitted through the ED."
          label='Software'
          date='September 22, 2023'
          to='/news#software-release-2023-09-22'
        />
      </TileGroup>
      <Box mb={8} textAlign='center'>
        <Typography variant='h5' color='primary'>
          <Link to='/news'>
            See all news <ChevronRightIcon />
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};
