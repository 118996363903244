import React from 'react';
import { Box, styled, Typography } from '@mui/material';

export const HeroContent = ({ title, description, subDescription, to, promote, tag }) => {
  return (
    <CardContent className='HomeCardContent'>
      <div className='promote'>{promote ? 'Beta' : ''}</div>
      <Typography variant='h3' className='title'>
        <a href={to}>{title}</a>
      </Typography>
      <div className='definition'>{description}</div>
      {subDescription && (<div className='sub-definition'>{subDescription}</div>)}
      {tag && 
        <div className='HomeHeroIndicatorsTag' >
            {tag.map((item) => (<div>{item}</div>))}
        </div>
     }
    </CardContent>
  );
};

const CardContent = styled(Box)(({ theme }) => ({
  marginTop: -12,
  '.title': {
    fontSize: '2rem',
    fontWeight: 900,

    '&:after': {
      content: `""`,
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,

      display: 'block',
      backgroundColor: theme.palette.grey[300],
      height: 2,
    },
  },
  '.definition': {
    fontSize: '24px',
    lineHeight: '30px',
    marginTop: theme.spacing(1),
    zIndex: 1,
    'display': 'flex',
    'flex-grow': 1,
  },
  '.promote': {
    color: theme.palette.secondary.light,
    fontSize: '.875rem',
    fontWeight: 'bold',
    lineHeight: 1,
    height: 12,
    textTransform: 'uppercase',
  },
  '.HomeCardContent': {
    display: 'flex',
    'flex-direction': 'column',
    height: '100%',
  }
}));
