import React, { useRef } from 'react';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';
import { pqiMeasuresData } from '../../../data/measures/pqi';
import QICard from '../../Common/QICard/QICard';
import {
  Button,
  PageHeader,
  PageRow,
  ImagePageRow,
  LinkItem,
  PageDisclaimer,
  TileGroup,
  Tile,
} from '../../../ui';
import { Box } from '@mui/material';
import { releaseVersion } from '../../../data/releases';

const PQIResources = () => {
  const scrollToResourcesRef = useRef(null);
  const scrollToSoftwareRef = useRef(null);

  return (
    <>
      <PageHeader.Row bgSrc='/images/measures-hero-bg.jpg' split sx={{ paddingBottom: '6rem' }}>
        <PageHeader.Title>
          Prevention Quality Indicators in Inpatient Settings Measures
        </PageHeader.Title>
        <PageHeader.Content>
          <div>
            The Prevention Quality Indicators in Inpatient Settings (<strong>PQI</strong>s) identify
            issues of access to outpatient care, including appropriate follow-up care after hospital
            discharge. More specifically, the PQIs use data from hospital discharges to identify
            admissions that might have been avoided through access to high-quality outpatient care.
            The PQIs are population based indicators that capture all cases of the potentially
            preventable complications that occur in a given population (in a community or region)
            either during a hospitalization or in a subsequent hospitalization. The PQIs are a key
            tool for community health needs assessments.
          </div>
          <Box display='flex' flexDirection={{ xs: 'column', lg: 'row' }} gap={2}>
            <Button
              label='Go to the PQI Resources'
              onClick={() => scrollToResourcesRef.current.scrollIntoView()}
              arrowDown
            />
            <Button
              label={`View Software Below`}
              onClick={() => scrollToSoftwareRef.current.scrollIntoView()}
              variant='text'
              sx={{ fontWeight: 400 }}
              arrowDown
            />
          </Box>
        </PageHeader.Content>
      </PageHeader.Row>

      <ImagePageRow
        bgSrc='/images/hex-purple.png'
        imageSrc='/images/computer-presentation.png'
        purple
      >
        <h2>How are Prevention Quality Indicators in Inpatient Settings used?</h2>
        <p>
          The PQIs provide a good starting point for assessing quality of health services in the
          community. The PQIs use administrative data found in a typical hospital discharge abstract
          to:
        </p>
        <ul>
          <li>Flag potential health care quality problem areas that need further investigation;</li>
          <li>
            Provide a quick check on primary care access or outpatient services in a community;
          </li>
          <li>Help organizations identify unmet needs in their communities.</li>
        </ul>
        <p>Download information about AHRQ's Quality Indicators below:</p>
        <div>
          <LinkItem
            href='/Downloads/Modules/V2022/AHRQ_QI_Full_Brochure.pdf'
            meta='PDF File, 1.19 MB'
            light
            mb
            noborder
          >
            AHRQ Quality Indicators Brochure
          </LinkItem>
          <LinkItem
            href='/Downloads/Modules/V2024/AHRQ_QI_Indicators_List.pdf'
            meta='PDF File, 345 KB'
            mb
            noborder
          >
            List of all Indicators
          </LinkItem>
        </div>
      </ImagePageRow>

      <PageRow
        sx={{
          paddingTop: '7rem',
          borderBottom: '1px solid',
          borderColor: (t) => t.palette.divider,
        }}
      >
        <h2 ref={scrollToSoftwareRef} className='align-center'>
          AHRQ QI Software
        </h2>
        <p>
          AHRQ offers free software to help users using the AHRQ QIs generate results that are both
          accurate and actionable. Use of this free software ensures a standard, trusted approach to
          quality measurement and means more resources are available for supporting improvements to
          patient care.
        </p>
        <TileGroup type='measures' columns={{ xs: 2, md: 4, lg: 4, xl: 4 }} gap='sm' py={3}>
          <Tile
            title='SAS QI v2024'
            description='Ideal for advanced users to run in a SAS/STAT software package.'
            to='/software/sas_qi'
            toLabel='Now available, SAS QI v2024'
          />
          <Tile
            title='WinQI v2024'
            description={`WinQI's robust user interface is ideal for non-programmers.`}
            to='/software/win_qi'
            toLabel='Now available, WinQI v2024'
          />
        </TileGroup>
      </PageRow>

      <PageRow bgSrc='/images/bg-tech-specs-list.jpg' bgSrcNone='sm' sx={{ paddingTop: '7rem' }}>
        <Box
          pb={6}
          textAlign={{ xs: 'center', sm: 'left' }}
          width={{ xs: '100%', sm: '60%', lg: '50%' }}
        >
          <h2>{pqiMeasuresData.technical[0].title}</h2>
          <p>{pqiMeasuresData.technical[0].desc}</p>
          <Button
            label={pqiMeasuresData.technical[0].cta}
            aria-label={pqiMeasuresData.technical[0].aria}
            href={pqiMeasuresData.technical[0].url}
            arrow
          />
        </Box>
      </PageRow>

      <PageRow lightBlue>
        <h2 ref={scrollToResourcesRef} className='align-center'>
          PQI Resources
        </h2>
        <Tabs defaultActiveKey='technical' className='qiTabs'>
          <Tab id='techspecs' eventKey='technical' title='Technical Specifications'>
            <Container>
              <Row>
                {pqiMeasuresData.technical.map((info, index) => (
                  <QICard key={index} info={info} />
                ))}
              </Row>
            </Container>
          </Tab>
          <Tab eventKey='additional' title='Additional PQI Resources'>
            <Container>
              <Row>
                {pqiMeasuresData.additional.map((info, index) => (
                  <QICard key={index} info={info} />
                ))}
              </Row>
            </Container>
          </Tab>
        </Tabs>
      </PageRow>

      <PageRow className='page-row disclaimer-row' lightBlue py={0} pb={6}>
        <PageDisclaimer
          resource={`AHRQ PQI Technical Documentation, Version ${releaseVersion}`}
          technical
        />
      </PageRow>
    </>
  );
};

export default PQIResources;
